import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { DEFAULT_LIMIT } from "@ag/utils/constants";
import { CommonPaginatedResponse, MetaArgs } from "@ag/utils/types";

import {
  CertificateBeneficiary,
  CertificateBeneficiarySchema,
} from "../entities/certificate-beneficiary";

type GetCertificateBeneficiariesParams = MetaArgs & {
  filters?: Partial<{
    name: string;
  }>;
};

export const generateCertificateBeneficiariesQueryKey = (
  params?: GetCertificateBeneficiariesParams | undefined,
) => ["certificate-beneficiaries", params].filter(Boolean);

const getCertificateBeneficiaries = async (
  params?: GetCertificateBeneficiariesParams,
) => {
  const response = await axios.get<
    CommonPaginatedResponse<CertificateBeneficiary>
  >("/api/v1/certificate-beneficiaries", {
    params: {
      ...params,
      limit: params?.limit || DEFAULT_LIMIT,
    },
    schema: z.object({
      items: z.array(CertificateBeneficiarySchema),
    }),
    apiSource: "node-markets",
  });

  return response.data.success!.data;
};

export const useCertificateBeneficiariesQuery = (
  params?: GetCertificateBeneficiariesParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateCertificateBeneficiariesQueryKey(params),
    () => getCertificateBeneficiaries(params),
    {
      keepPreviousData: true,
      ...options,
    },
  );
