import ReactJson from "react-json-view";

import { FieldActualsInputType } from "@ag/carbon/types";
import { Button, InfoBox } from "@ag/design-system/atoms";
import { box, stack } from "@ag/design-system/utils";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import CollapseBox from "~components/collapse-box";
import { useFieldHarvestYearContext } from "~features/harvest-year";
import {
  CarbonResourceClass,
  UserManagementResourceClass,
  useCarbonPermissions,
  useUserManagementPermissions,
} from "~features/permission";
import { useSignInAsMutation } from "~features/user";
import { ampli } from "~lib/ampli";
import { redirectToAppFarmerFieldDetailsPage } from "~pages/_legacy/carbon/helpers";
import { FieldStepTabId } from "~pages/_legacy/carbon/types";

import { useUnlockFieldActualsMutation } from "../api/unlock-field-actuals";
import { Field } from "../entities/field";
import {
  getStepStatusIcon,
  getStepStatusText,
  getStepStatusVariant,
} from "../helpers/field-step";
import { useIsFieldActualsUnlockable } from "../hooks/use-is-field-actuals-unlockable";
import AdditionalInformation from "./partials/additional-information";
import CropProtection from "./partials/crop-protection";
import CultivationPractice from "./partials/cultivation-practice";
import Fallow from "./partials/fallow";
import Fertiliser from "./partials/fertiliser";
import FuelConsumption from "./partials/fuel-consumption/fuel-consumption";

type Props = {
  field: Field;
};

export const FieldActualsV2022Overview = ({ field }: Props) => {
  const { selectedHarvestYear } = useFieldHarvestYearContext();

  const fieldActuals = field.carbonFieldActual?.find(
    fieldActuals => fieldActuals.harvestYear === selectedHarvestYear,
  );

  const { data: carbonPermissions } = useCarbonPermissions();
  const { data: userManagementPermissions } = useUserManagementPermissions();

  const isActualsUnlockable = useIsFieldActualsUnlockable(fieldActuals);

  const unlockFieldActualsMutation = useUnlockFieldActualsMutation();

  const signInAsMutation = useSignInAsMutation();

  const handleSignInAs = (userId: string) => {
    signInAsMutation.mutate(userId, {
      onSuccess: ({ ticket }) => {
        redirectToAppFarmerFieldDetailsPage({
          ticket,
          fieldId: field.id,
          harvestYear: selectedHarvestYear,
          activeFieldDetailsTab: FieldStepTabId.FieldActuals,
        });
      },
    });
  };

  const handleUnlockActuals = () => {
    if (!fieldActuals) return;

    unlockFieldActualsMutation.mutate(
      {
        fieldId: field.id,
        fieldActualsId: fieldActuals.id,
      },
      {
        onSuccess: () => {
          ToastNotification.success(
            I18n.t("js.admin.field_details.actuals_unlocked"),
          );

          if (selectedHarvestYear) {
            ampli.adminUnlockFieldActuals({
              fieldId: Number(field.id),
              harvestYear: selectedHarvestYear,
            });
          }
        },
      },
    );
  };
  if (
    !fieldActuals ||
    fieldActuals.carbonFieldActualInputType !== FieldActualsInputType.V2022 ||
    !selectedHarvestYear
  ) {
    return null;
  }

  /**
   * admin can only edit the "actuals" field when is in a read-only state and has already been submitted
   */
  const isReadonly = !(
    fieldActuals.readonly === true || fieldActuals.submittedAt
  );

  return (
    <div className={stack()}>
      <InfoBox
        variant={getStepStatusVariant(fieldActuals.status)}
        icon={getStepStatusIcon(fieldActuals.status)}
      >
        {getStepStatusText(fieldActuals.status, fieldActuals.submittedAt)}
      </InfoBox>

      <div className={box({ gap: 8 })}>
        {carbonPermissions?.unlock?.includes(
          CarbonResourceClass.CarbonFieldActual,
        ) ? (
          <Button disabled={!isActualsUnlockable} onClick={handleUnlockActuals}>
            {I18n.t("js.shared.unlock")}
          </Button>
        ) : null}

        {userManagementPermissions?.loginAsUser?.includes(
          UserManagementResourceClass.User,
        ) ? (
          <Button onClick={() => handleSignInAs(field.user.id)}>
            {I18n.t("js.shared.sign_in")}
          </Button>
        ) : null}
      </div>

      <Fallow
        fieldId={field.id}
        fieldActuals={fieldActuals}
        isReadonly={isReadonly}
      />

      <CultivationPractice
        fieldId={field.id}
        fieldActuals={fieldActuals}
        harvestYear={selectedHarvestYear}
        isReadonly={isReadonly || fieldActuals.fallow}
      />

      <Fertiliser
        fieldId={field.id}
        fieldActuals={fieldActuals}
        harvestYear={selectedHarvestYear}
        isReadonly={isReadonly || fieldActuals.fallow}
      />

      <CropProtection
        fieldId={field.id}
        fieldActuals={fieldActuals}
        isReadonly={isReadonly || fieldActuals.fallow}
      />

      <FuelConsumption
        fieldId={field.id}
        fieldActuals={fieldActuals}
        isReadonly={isReadonly || fieldActuals.fallow}
      />

      <AdditionalInformation
        fieldId={field.id}
        fieldActuals={fieldActuals}
        isReadonly={isReadonly}
      />

      {/* Sent to CFT */}
      <CollapseBox.Root>
        <CollapseBox.Title>
          {I18n.t("js.carbon.field_details.sent_to_cft")}
        </CollapseBox.Title>
        <CollapseBox.Content>
          {fieldActuals.latestCarbonFootprint ? (
            <ReactJson
              src={fieldActuals.latestCarbonFootprint}
              displayDataTypes={false}
              shouldCollapse={key => key.name !== "root"}
            />
          ) : (
            <InfoBox variant="danger" icon="triangle-exclamation">
              {I18n.t("js.carbon.cft_response_error")}
            </InfoBox>
          )}
        </CollapseBox.Content>
      </CollapseBox.Root>
    </div>
  );
};
