import { Field } from "react-final-form";

import { FormTextarea } from "@ag/components/form";
import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { AdminCommentAttribute } from "./types";

export type CommentData = {
  comment: string;
};

type Props = {
  isSubmitting: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

export const AdminCommentForm = ({
  isSubmitting,
  onCancel,
  onSubmit,
}: Props) => (
  <>
    <Field
      label={I18n.t("js.carbon.field.comment")}
      name={AdminCommentAttribute.Comment}
      component={FormTextarea}
      rows={8}
    />

    <Modal.Footer
      render={() => (
        <>
          <Button variant="text" onClick={onCancel}>
            {I18n.t("js.shared.cancel")}
          </Button>

          <Button isLoading={isSubmitting} onClick={onSubmit}>
            {I18n.t("js.shared.save")}
          </Button>
        </>
      )}
    />
  </>
);

export default AdminCommentForm;
