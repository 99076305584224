import { ChipVariant } from "@ag/design-system/atoms";

import { CertificaGroupStatus } from "../entities/inventory";

export function getCertificaGroupStatusChipVariant(
  status: CertificaGroupStatus,
): ChipVariant {
  const lookup: Record<CertificaGroupStatus, ChipVariant> = {
    [CertificaGroupStatus.IMPORTED]: "info",
    [CertificaGroupStatus.ALLOCATED]: "success",
    [CertificaGroupStatus.ISSUED]: "warning",
    [CertificaGroupStatus.SOLD]: "neutral",
  };

  return lookup[status];
}

export function getCertificaGroupStatusChipText(
  status: CertificaGroupStatus,
): string {
  const lookup: Record<CertificaGroupStatus, string> = {
    [CertificaGroupStatus.IMPORTED]: "Imported",
    [CertificaGroupStatus.ALLOCATED]: "Allocated",
    [CertificaGroupStatus.ISSUED]: "Issued",
    [CertificaGroupStatus.SOLD]: "Sold",
  };

  return lookup[status];
}

export const getCertificaGroupStatusChip = (
  status: CertificaGroupStatus,
): { variant: ChipVariant; label: string } => ({
  variant: getCertificaGroupStatusChipVariant(status),
  label: getCertificaGroupStatusChipText(status),
});
