import styled from "@emotion/styled";

import { SRow as SRowGlobal } from "@ag/components/styled";

export const SRow = styled(SRowGlobal)`
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
