import { stack } from "@ag/design-system/utils";

import CollapseBox from "~components/collapse-box";
import { CQAFieldDefinitionData } from "~features/bulk-upload/entities/cqa-edits";
import { UpdateFailedRow } from "~features/bulk-upload/helpers/bulk-cqa-edits";
import { Row } from "~features/bulk-upload/types/cqa-edits";

import * as styles from "./bulk-cqa-edits-modal.css";

type GetUpdateCompleteMessage = {
  harvestYear: number;
  updatedRows: Row<CQAFieldDefinitionData>[];
  nonExstingFieldsRows: Row<CQAFieldDefinitionData>[];
  qaStatusNotApprovedRows: Row<CQAFieldDefinitionData>[];
  dataUpdateFailedRows: UpdateFailedRow[];
  qaNonConformanceStatusUpdateFailedRows: UpdateFailedRow[];
  qaApprovedStatusUpdateFailedRows: UpdateFailedRow[];
};

export const getCompleteReportModalContent = ({
  harvestYear,
  updatedRows,
  dataUpdateFailedRows,
  nonExstingFieldsRows,
  qaStatusNotApprovedRows,
  qaNonConformanceStatusUpdateFailedRows,
  qaApprovedStatusUpdateFailedRows,
}: GetUpdateCompleteMessage) => {
  // Merge all error types and count unique fields
  const failedRowsAmount = [
    ...new Set(
      [
        ...nonExstingFieldsRows,
        ...qaStatusNotApprovedRows,
        ...dataUpdateFailedRows.map(({ row }) => row),
        ...qaNonConformanceStatusUpdateFailedRows.map(({ row }) => row),
        ...qaApprovedStatusUpdateFailedRows.map(({ row }) => row),
      ].map(row => row.fieldId),
    ),
  ].length;

  return (
    <div className={stack()}>
      <p className={styles.header}>
        <h4 className={styles.successLabel}>
          Updated {updatedRows.length} fields
        </h4>

        {failedRowsAmount ? (
          <h4 className={styles.errorLabel}>
            Failed {failedRowsAmount} fields
          </h4>
        ) : null}
      </p>

      {failedRowsAmount ? (
        <div className={styles.collapseBox}>
          <CollapseBox.Root isInitiallyOpen>
            <CollapseBox.Title>Errors report</CollapseBox.Title>
            <CollapseBox.Content>
              <div className={styles.collapseBoxContent}>
                {nonExstingFieldsRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Field does not exist in the {harvestYear}:
                    </h3>

                    <ul className={styles.errorList}>
                      {nonExstingFieldsRows.map(row => (
                        <li key={row.fieldId}>
                          <p>FieldId: {row.fieldId}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {qaStatusNotApprovedRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: QA status is not Approved:
                    </h3>

                    <ul className={styles.errorList}>
                      {qaStatusNotApprovedRows.map(row => (
                        <li key={row.fieldId}>
                          <p>FieldId: {row.fieldId}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {dataUpdateFailedRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Update data failed:
                    </h3>

                    <ul className={styles.errorList}>
                      {dataUpdateFailedRows.map(({ row, errorMessage }) => (
                        <>
                          <li key={row.fieldId}>
                            <p>FieldId: {row.fieldId}</p>
                          </li>

                          {errorMessage && (
                            <li key={errorMessage}>
                              <p>API response message: {errorMessage}</p>
                            </li>
                          )}
                        </>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {qaNonConformanceStatusUpdateFailedRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Update qa status to &apos;Non conformance&apos;
                      failed:
                    </h3>

                    <ul className={styles.errorList}>
                      {qaNonConformanceStatusUpdateFailedRows.map(
                        ({ row, errorMessage }) => (
                          <>
                            <li key={row.fieldId}>
                              <p>FieldId: {row.fieldId}</p>
                            </li>

                            {errorMessage && (
                              <li key={errorMessage}>
                                <p>API response message: {errorMessage}</p>
                              </li>
                            )}
                          </>
                        ),
                      )}
                    </ul>
                  </div>
                ) : null}

                {qaApprovedStatusUpdateFailedRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Update qa status to &apos;Approved&apos; failed:
                    </h3>

                    <ul className={styles.errorList}>
                      {qaApprovedStatusUpdateFailedRows.map(
                        ({ row, errorMessage }) => (
                          <>
                            <li key={row.fieldId}>
                              <p>FieldId: {row.fieldId}</p>
                            </li>

                            {errorMessage && (
                              <li key={errorMessage}>
                                <p>API response message: {errorMessage}</p>
                              </li>
                            )}
                          </>
                        ),
                      )}
                    </ul>
                  </div>
                ) : null}
              </div>
            </CollapseBox.Content>
          </CollapseBox.Root>
        </div>
      ) : null}
    </div>
  );
};
