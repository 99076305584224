import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { InputField } from "@ag/form-fields";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

const BoundaryVerification = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      farmId: "",
    },
  });
  const navigate = useNavigate();

  // TODO: Farms list table will be added in later release
  // const [pagination, updatePagination] = usePagination({
  //   defaultLimit: 10,
  // });

  // const table = useBoundaryVerificationFarmsTable(
  //   boundaryVerificationFarms.data,
  // );

  const onSubmit = (data: { farmId: string }) => {
    navigate(`/carbon/boundary-verification/map/${data.farmId}`);
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <BackButton />

        <ListLayout.TopBarTitle>Boundary verification</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header variant="slim">
          <form onSubmit={handleSubmit(onSubmit)} className="flex gap-2">
            <Controller
              name="farmId"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  placeholder="Search for a FarmId"
                  leftAddon={<Icon name="search" />}
                />
              )}
            />
            <Button type="submit">Map view</Button>
          </form>
        </ListLayout.Header>

        {/* TODO: Farms list table will be added in later release */}
        {/* <Table
          instance={table}
          meta={boundaryVerificationFarms?.meta}
          pagination={pagination}
          onPaginationChange={(...args) => {
            updatePagination(...args);
          }}
        /> */}
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default BoundaryVerification;
