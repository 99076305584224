import { zodResolver } from "@hookform/resolvers/zod";
import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { transformInitialFilterValues } from "@ag/components/Filters";
import { ButtonSelect, ComboBox, Select } from "@ag/design-system/molecules";
import { parseSorting } from "@ag/design-system/organisms";
import {
  ButtonSelectField,
  ComboBoxField,
  InputField,
  SelectField,
} from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";

import { Filters, useSearchParamForm } from "~components/filters";
import Table from "~components/table";
import { useCarbonCountries } from "~features/countries";
import {
  useInventoryCertificateGroupsQuery,
  useInventoryTable,
} from "~features/inventory";
import {
  CertificaGroupStatus,
  InventoryFilters,
  InventoryFiltersSchema,
} from "~features/inventory/entities/inventory";
import { AuthorizedSidebar } from "~features/navigation";
import {
  CarbonResourceClass,
  useCarbonPermissions,
} from "~features/permission";
import { transformEnumToLabels, transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const Inventory = () => {
  const [pagination, updatePagination, resetPagination] = usePagination();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [query, setQuery] = useQueryParams({
    importId: withDefault(StringParam, undefined),
    fieldId: withDefault(StringParam, undefined),
    status: withDefault(
      createEnumParam(Object.values(CertificaGroupStatus)),
      undefined,
    ),
    eligibleVerra: withDefault(StringParam, undefined),
    eligibleIso: withDefault(StringParam, undefined),
    vintageYear: withDefault(StringParam, undefined),
    countryCode: withDefault(StringParam, undefined),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InventoryFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(InventoryFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<InventoryFilters>(
    query,
    setQuery,
    resetPagination,
    () => setIsFiltersOpen(false),
  );

  const { data: countries } = useCarbonCountries();

  const { data, isLoading } = useInventoryCertificateGroupsQuery({
    ...pagination,
    filters: {
      ...query,
      countryCode: query.countryCode ? [query.countryCode] : undefined,
      vintageYear: query.vintageYear ? [query.vintageYear] : undefined,
      status: query.status ? [query.status] : undefined,
    },
    sort: parseSorting(sorting),
  });

  const { data: carbonPermissions } = useCarbonPermissions();

  const canViewField = carbonPermissions?.read?.includes(
    CarbonResourceClass.CarbonField,
  );

  const renderFilterBarItem = (
    key: keyof InventoryFilters,
    value: InventoryFilters[keyof InventoryFilters],
  ) => {
    const elibilityStatus = {
      true: "Yes",
      false: "No",
    };
    const label = (
      {
        importId: "Import ID",
        fieldId: "Field ID",
        status: "Certificate Group Status",
        eligibleVerra: "Eligible Verra",
        eligibleIso: "Eligible Iso",
        vintageYear: "Vintage Year",
        countryCode: "Country",
      } as unknown as Record<keyof InventoryFilters, string>
    )[key];

    if (key === "eligibleVerra" || key === "eligibleIso") {
      return `${label}: ${elibilityStatus[value as "true" | "false"]}`;
    }

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const certificatesGroupStatusLabels =
    transformEnumToLabels(CertificaGroupStatus);

  const table = useInventoryTable(
    data?.items,
    {
      sorting,
      setSorting,
    },
    Boolean(canViewField),
  );

  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Inventory</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField
            {...register("importId")}
            label="Import ID"
            error={errors.importId}
            type="number"
          />

          <InputField
            {...register("fieldId")}
            label="Field ID"
            error={errors.importId}
            type="number"
          />

          <Controller
            name="status"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                value={field.value ?? ""}
                label="Certificate Group Status"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {Object.values(CertificaGroupStatus).map(type => (
                  <Select.Option key={type} value={type}>
                    {certificatesGroupStatusLabels[type]}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            name="eligibleVerra"
            control={control}
            render={({ field, fieldState }) => (
              <ButtonSelectField
                label="Eligible Verra"
                {...field}
                {...fieldState}
              >
                <ButtonSelect.Option value="true">Yes</ButtonSelect.Option>

                <ButtonSelect.Option value="false">No</ButtonSelect.Option>
              </ButtonSelectField>
            )}
          />

          <Controller
            name="eligibleIso"
            control={control}
            render={({ field, fieldState }) => (
              <ButtonSelectField
                label="Eligible Iso"
                {...field}
                {...fieldState}
              >
                <ButtonSelect.Option value="true">Yes</ButtonSelect.Option>

                <ButtonSelect.Option value="false">No</ButtonSelect.Option>
              </ButtonSelectField>
            )}
          />

          <Controller
            name="countryCode"
            control={control}
            render={({ field, fieldState }) => (
              <ComboBoxField
                {...field}
                {...fieldState}
                value={field.value ?? ""}
                label="Country"
                loadingText="Loading..."
                emptyText="No matching results"
                optionsClassName="z-modal"
              >
                {countries?.map(country => (
                  <ComboBox.Item key={country.code}>
                    {country.name}
                  </ComboBox.Item>
                ))}
              </ComboBoxField>
            )}
          />

          <InputField
            {...register("vintageYear")}
            label="Vintage Year"
            type="number"
            error={errors.vintageYear}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={data?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default Inventory;
