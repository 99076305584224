import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";

import {
  ActionsCell,
  ActionsCellValue,
  ChipCell,
  ChipCellValue,
  DateCell,
  DateCellConfig,
  LinkCell,
  LinkCellValue,
  TableRowError,
} from "@ag/design-system/organisms";

import { useList } from "~components/list";
import { TableSortingState } from "~components/table";

import { ImportStatus, InventoryImport } from "../entities/inventory-import";
import { getStatusChip } from "../helpers/import-statuses";

type ListData = {
  id: string;
  admin?: LinkCellValue;
  filename: string | null | undefined;
  status: ChipCellValue;
  createdAt: DateCellConfig | null;
  processedAt: DateCellConfig | null;
  startedProcessingAt: DateCellConfig | null;
  actions: ActionsCellValue;

  import: InventoryImport;
};

export const useActiveInventoryImportsTable = (
  data: InventoryImport[] | undefined,
  state: TableSortingState,
  hasAdminAccess: boolean | undefined,
  handleErrorButtonClick: (params: { id: string; errors: string[] }) => void,
  handleCancelImportButtonClick: (id: string) => void,
) => {
  const columns = useMemo(() => getColumns(), []);
  const rowData = useMemo(
    () =>
      getRowData(
        data,
        hasAdminAccess,
        handleErrorButtonClick,
        handleCancelImportButtonClick,
      ),
    [
      data,
      hasAdminAccess,
      handleErrorButtonClick,
      handleCancelImportButtonClick,
    ],
  );

  const errors = data?.reduce(
    (acc, item) => {
      if (item.errors.length > 0) {
        acc[item.id] = item.errors.map(error => new TableRowError(error));
      }
      return acc;
    },
    {} as Record<string, TableRowError<ListData>[]>,
  );

  return useList<ListData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id,
    meta: {
      errors,
    },
    initialState: {
      columnVisibility: {
        admin: hasAdminAccess ?? false,
      },
    },
    state,
    onSortingChange: state.setSorting,
  });

  function getColumns() {
    const columnHelper = createColumnHelper<ListData>();

    return [
      columnHelper.accessor("id", {
        header: "Import ID",
      }),
      columnHelper.accessor("admin", {
        header: "Admin ID",
        cell: LinkCell,
      }),
      columnHelper.accessor("filename", {
        header: "File name",
        maxSize: 200,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: ChipCell,
      }),
      columnHelper.accessor("createdAt", {
        header: "Created",
        cell: DateCell,
        enableSorting: true,
      }),
      columnHelper.accessor("startedProcessingAt", {
        header: "Started processing",
        cell: DateCell,
      }),
      columnHelper.accessor("processedAt", {
        header: "Processed",
        cell: DateCell,
      }),
      columnHelper.accessor("actions", {
        header: "Actions",
        cell: ActionsCell,
      }),
    ];
  }

  function getRowData(
    data: InventoryImport[] | undefined,
    hasAdminAccess: boolean | undefined,
    handleErrorButtonClick: (params: { id: string; errors: string[] }) => void,
    handleCancelImportButtonClick: (id: string) => void,
  ): ListData[] {
    if (!data) return [];

    return data.map(importData => {
      const hasErrors =
        Array.isArray(importData.errors) && importData.errors.length > 0;

      const cancellable =
        importData.status === ImportStatus.FAILED ||
        importData.status === ImportStatus.UPLOADING;

      return {
        id: importData.id,
        filename: importData.filename,
        status: importData.status ? getStatusChip(importData.status) : null,
        admin: hasAdminAccess
          ? {
              title: importData.adminId,
              url: `/admins/${importData.adminId}`,
            }
          : undefined,
        createdAt: { value: importData.createdAt, format: "datetime" },
        processedAt: { value: importData.processedAt, format: "datetime" },
        startedProcessingAt: {
          value: importData.startedProcessingAt,
          format: "datetime",
        },
        actions: {
          title: "Actions",
          items: [
            {
              children: "Download file",
              isDisabled: !importData.fileDownloadUrl,
              href: `/file?${new URLSearchParams({
                url: importData.fileDownloadUrl || "",
                apiSource: "node-markets",
              })}`,
            },
            {
              children: "Cancel import",
              isDanger: true,
              isDisabled: !cancellable,
              onClick: () => handleCancelImportButtonClick(importData.id),
            },
            ...(hasErrors
              ? [
                  {
                    children: "View errors",
                    onClick: () =>
                      handleErrorButtonClick({
                        id: importData.id,
                        errors: importData.errors,
                      }),
                  },
                ]
              : []),
          ],
        },
        import: importData,
      };
    });
  }
};
