import { z } from "zod";

export const CertificateBeneficiarySchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type CertificateBeneficiary = z.infer<
  typeof CertificateBeneficiarySchema
>;
