import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { colors } from "@ag/design-system/tokens";

export const SGridContainer = styled.div`
  display: grid;

  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  grid-gap: 8px;

  justify-content: space-between;

  width: 100%;
`;

export const SErrorMessage = styled.div`
  fontSize: 0.75rem,
  lineHeight: 1.5,
  fontWeight: 400,

  display: flex;
  align-items: center;
  color: ${colors.messaging.error[700]};
  margin-top: 8px;
  text-align: left;
`;

export const SFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 4px;
`;
