import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { BasicStepper } from "@ag/design-system/organisms";
import { usePrompt } from "@ag/utils/hooks";

import BackButton from "~components/BackButton";
import { ProgressLostModal } from "~features/certificate";
import {
  useDeleteDistributeVcuMutation,
  useImportIsoVcuStore,
} from "~features/import-vcu";
import { AuthorizedSidebar } from "~features/navigation";
import { Page, withPageAccess } from "~features/permission";
import ListLayout from "~layouts/list-layout";

import * as pageStyles from "../styles.css";
import ImportBatch from "./steps/import-batch";
import SelectIsoDistribution from "./steps/select-distribution";
import UploadIsoDocumentation from "./steps/upload-documentation";

const ImportIsoCertificates = () => {
  const navigate = useNavigate();
  const [isShowProgressLostModal, setIsShowProgressLostModal] = useState(false);
  const deleteDistributeVcu = useDeleteDistributeVcuMutation();
  const { isFormValid, resetState, documentation, id } = useImportIsoVcuStore();

  const { blockedRoute, unblockRoute } = usePrompt(
    true,
    setIsShowProgressLostModal,
  );

  const handleNavigation = () => {
    if (blockedRoute) {
      unblockRoute();
      navigate(blockedRoute);
    } else {
      history.back();
    }
  };

  const handleExitClick = () => {
    if (id) {
      deleteDistributeVcu.mutate(id, {
        onSuccess: () => {
          handleNavigation();
          resetState();
        },
      });
    } else {
      handleNavigation();
    }
  };

  const handleCancel = () => {
    setIsShowProgressLostModal(false);
  };

  return (
    <ListLayout.Root>
      <ProgressLostModal
        isOpen={isShowProgressLostModal}
        onClose={handleCancel}
        onExitClick={handleExitClick}
      />
      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.TopBar>
        <BackButton onClick={() => navigate("/certificates-management")} />

        <ListLayout.TopBarTitle>Import and distribute</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Content>
        <div className={pageStyles.root}>
          <BasicStepper.Root>
            <BasicStepper.List>
              <BasicStepper.Trigger title="Import VCU batch" value="batch" />
              <BasicStepper.Trigger
                title="Upload documentation"
                value="documentation"
                isDisabled={!isFormValid}
              />
              <BasicStepper.Trigger
                title="Select distribution file"
                value="file"
                isDisabled={documentation.length === 0}
              />
            </BasicStepper.List>

            <BasicStepper.Content value="batch">
              {({ goNextStep }) => <ImportBatch onGoNextStep={goNextStep} />}
            </BasicStepper.Content>

            <BasicStepper.Content value="documentation">
              {({ goPrevStep, goNextStep }) => (
                <UploadIsoDocumentation
                  onGoNextStep={goNextStep}
                  onGoPrevStep={goPrevStep}
                />
              )}
            </BasicStepper.Content>

            <BasicStepper.Content value="file">
              {({ goPrevStep }) => (
                <SelectIsoDistribution
                  onGoPrevStep={goPrevStep}
                  unblockRoute={unblockRoute}
                />
              )}
            </BasicStepper.Content>
          </BasicStepper.Root>
        </div>
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default withPageAccess(Page.ImportIsoCertificates)(
  ImportIsoCertificates,
);
