import styled from "@emotion/styled";

export const SGridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, minmax(12rem, 33vw));
  grid-gap: 1rem;
  justify-content: flex-start;

  margin: 0 8px;
`;
