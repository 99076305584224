import { RecordWithLinks } from "@flatfile/api/api";

import { stack } from "@ag/design-system/utils";

import CollapseBox from "~components/collapse-box";
import { CQCEditsData } from "~features/bulk-upload/entities/cqc-edits";

import * as styles from "./bulk-cqc-edits-modal.css";

export type Row<T> = T & {
  record: RecordWithLinks;
};

export type RowItem = {
  recordKey: keyof CQCEditsData;
  recordCommentKey?:
    | "tillingRateComment"
    | "energyConsumptionAmountHaComment"
    | "coverCropsAdoptionComment"
    | "cropGrossYieldComment"
    | "cropResidueManagementComment";
  row: Row<CQCEditsData>;
};

type GetUpdateCompleteMessage = {
  harvestYear: number;
  updatedRows: Row<CQCEditsData>[];
  qcApprovedRowItems: RowItem[];
  dataUpdateFailedRowItems: RowItem[];
  nonExstingFieldsRowItems: RowItem[];
  nonExstingFielActualsRowItems: RowItem[];
  actualsNotBelongsToFieldRowItems: RowItem[];
  qcStatusUpdateFailedRowItems: RowItem[];
  fieldFallowRowItems: RowItem[];
};

export const getCompleteReportModalContent = ({
  harvestYear,
  updatedRows,
  qcApprovedRowItems,
  dataUpdateFailedRowItems,
  nonExstingFieldsRowItems,
  nonExstingFielActualsRowItems,
  qcStatusUpdateFailedRowItems,
  actualsNotBelongsToFieldRowItems,
  fieldFallowRowItems,
}: GetUpdateCompleteMessage) => {
  // Merge all error types and count unique fields
  const failedRowsAmount = [
    ...new Set(
      [
        ...qcApprovedRowItems,
        ...dataUpdateFailedRowItems,
        ...nonExstingFieldsRowItems,
        ...nonExstingFielActualsRowItems,
        ...qcStatusUpdateFailedRowItems,
        ...actualsNotBelongsToFieldRowItems,
        ...fieldFallowRowItems,
      ].map(item => item.row.fieldId),
    ),
  ].length;

  return (
    <div className={stack()}>
      <p className={styles.header}>
        <h4 className={styles.successLabel}>
          Updated {updatedRows.length} fields
        </h4>

        {failedRowsAmount ? (
          <h4 className={styles.errorLabel}>
            Failed {failedRowsAmount} fields
          </h4>
        ) : null}
      </p>

      {failedRowsAmount ? (
        <div className={styles.collapseBox}>
          <CollapseBox.Root isInitiallyOpen>
            <CollapseBox.Title>Errors report</CollapseBox.Title>
            <CollapseBox.Content>
              <div className={styles.collapseBoxContent}>
                {qcApprovedRowItems.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: QC status already Approved:
                    </h3>

                    <ul className={styles.errorList}>
                      {qcApprovedRowItems.map(({ row }) => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {nonExstingFieldsRowItems.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Field does not exist in the {harvestYear}:
                    </h3>

                    <ul className={styles.errorList}>
                      {nonExstingFieldsRowItems.map(({ row }) => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {nonExstingFielActualsRowItems.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Field does not exist in the {harvestYear}:
                    </h3>

                    <ul className={styles.errorList}>
                      {nonExstingFielActualsRowItems.map(({ row }) => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, ActualsId: {row.actualId},
                            UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {actualsNotBelongsToFieldRowItems.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: ActualsId does not belongs to the fieldId:
                    </h3>

                    <ul className={styles.errorList}>
                      {actualsNotBelongsToFieldRowItems.map(({ row }) => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, ActualsId: {row.actualId},
                            UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {fieldFallowRowItems.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Field is marked as fallow:
                    </h3>

                    <ul className={styles.errorList}>
                      {fieldFallowRowItems.map(({ row }) => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, ActualsId: {row.actualId},
                            UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {dataUpdateFailedRowItems.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Update data failed:
                    </h3>

                    <ul className={styles.errorList}>
                      {dataUpdateFailedRowItems.map(
                        ({ row, recordKey, recordCommentKey }) => (
                          <li key={row.fieldId}>
                            <p>
                              FieldId: {row.fieldId}, ActualsId: {row.actualId},
                              UserId: {row.userId}
                              <br />
                              Comment:{" "}
                              {recordCommentKey ? row[recordCommentKey] : ""}
                              <br />
                              {`${recordKey}: ${row[recordKey]}`}
                            </p>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                ) : null}

                {qcStatusUpdateFailedRowItems.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Update qc status failed:
                    </h3>

                    <ul className={styles.errorList}>
                      {qcStatusUpdateFailedRowItems.map(({ row }) => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, ActualsId: {row.actualId},
                            UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </CollapseBox.Content>
          </CollapseBox.Root>
        </div>
      ) : null}
    </div>
  );
};
