import api from "@flatfile/api";
import { RecordWithLinks } from "@flatfile/api/api";
import { FlatfileListener } from "@flatfile/listener";
import { SetStateAction, useSetAtom } from "jotai";
import { ReactNode, useRef } from "react";

import {
  isModalOpenAtom,
  modalContentAtom,
} from "../components/bulk-cqc-edits-modal/bulk-cqc-edits-modal";

export type OnStartParams = {
  context: {
    jobId: string;
    sheetId: string;
    records: RecordWithLinks[];
  };
  options: {
    harvestYear: number;
    setIsModalOpen: (value: SetStateAction<boolean>) => void;
    setModalContent: (value: SetStateAction<ReactNode>) => void;
  };
};

type Options = {
  harvestYear: number;
  onStart: (args: OnStartParams) => void;
  onError: (error: Error) => void;
  onValidationError: (options: { jobId: string }) => void;
};

/**
 * Creates a flatfile listener, that listens for update actions,
 * and update callback with the prepared data.
 */
export function useCQCEditsFlatfileListener({
  harvestYear,
  onStart,
  onValidationError,
  onError,
}: Options) {
  const listener = useRef<FlatfileListener>();

  const setIsModalOpen = useSetAtom(isModalOpenAtom);
  const setModalContent = useSetAtom(modalContentAtom);

  listener.current = FlatfileListener.create(listener => {
    listener.filter({ job: ["sheet:updateActionFg"] }, configure => {
      configure.on("job:ready", async ({ context, data }) => {
        const { jobId, sheetId } = context;

        try {
          const eventData: { records: { id: string }[] } = await data;
          const selectedIds = eventData.records.map(record => record.id);

          if (!selectedIds.length)
            throw new Error("No rows were selected or in view");

          const { data: recordsData } = await api.records.get(sheetId);
          const records = recordsData.records.filter(record =>
            selectedIds.includes(record.id),
          );

          const errors = records.flatMap(record =>
            getErrors(record, {
              // Excluding any field id errors, as we use that to show our own custom errors.
              excludeFields: ["fieldId"],
            }),
          );

          if (errors.length) {
            onValidationError({ jobId });
            return;
          }

          onStart({
            context: {
              jobId,
              sheetId,
              records,
            },
            options: {
              harvestYear,
              setIsModalOpen,
              setModalContent,
            },
          });
        } catch (err) {
          onError(err as Error);
        }
      });
    });
  });

  return {
    listener: listener.current,
  };
}

function getErrors<TData extends Record<string, unknown>>(
  record: RecordWithLinks,
  options?: {
    excludeFields: (keyof TData)[];
  },
) {
  const messages = record.messages ?? [];

  for (const [key, value] of Object.entries(record.values)) {
    if (options?.excludeFields.includes(key)) continue;

    const errors = value.messages?.filter(message => message.type === "error");

    if (!errors?.length) continue;

    messages.push(...errors);
  }

  return messages;
}
