import { RowSelectionState, SortingState } from "@tanstack/react-table";
import { useEffect, useState } from "react";

import { parseSorting } from "@ag/design-system/organisms";
import { usePagination } from "@ag/utils/hooks";

import Table from "~components/table";
import { useFieldsQuery, useFieldsTable } from "~features/field";
import { FieldOverview } from "~features/field/entities/field-overview";
import {
  CarbonResourceClass,
  useCarbonPermissions,
} from "~features/permission";

import { Contract, ContractStatus } from "../entities/contract";

type Props = {
  contract: Contract;
  fieldsToDisplay: "contract-fields" | "unlinked-fields";
  onSelectedFieldsUpdate?: (selectedFields: FieldOverview[]) => void;
};
export const ContractFields = ({
  contract,
  fieldsToDisplay,
  onSelectedFieldsUpdate,
}: Props) => {
  const [pagination, updatePagination] = usePagination();

  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);

  const { data: carbonPermissions } = useCarbonPermissions();
  const hasRemoveFieldsFromContractPermission =
    carbonPermissions?.update?.includes(CarbonResourceClass.CarbonContract) &&
    carbonPermissions?.unassign?.includes(CarbonResourceClass.CarbonContract);

  const { data: fieldsData, isLoading } = useFieldsQuery({
    ...pagination,
    filters: {
      id:
        fieldsToDisplay === "contract-fields"
          ? contract.carbonFields.map(field => field.id)
          : contract.uncontractedFields.map(field => field.carbonFieldId),
    },
    sort: parseSorting(sorting),
  });

  const [selectedFieldsState, setSelectedFieldsState] =
    useState<RowSelectionState>({});

  useEffect(() => {
    if (onSelectedFieldsUpdate) {
      const selectedFields = fieldsData?.items.filter(
        field => selectedFieldsState[field.id],
      );
      onSelectedFieldsUpdate(selectedFields ?? []);
    }
  }, [selectedFieldsState, fieldsData, onSelectedFieldsUpdate]);

  const table = useFieldsTable(fieldsData?.items, {
    sorting,
    setSorting,
    selection: selectedFieldsState,
    setSelection: setSelectedFieldsState,
    isSelectionEnabled:
      hasRemoveFieldsFromContractPermission &&
      fieldsToDisplay === "contract-fields" &&
      (contract.status === ContractStatus.Signed ||
        contract.status === ContractStatus.Sent),
    showContractAmendmentStatus: true,
  });

  return (
    <Table
      instance={table}
      meta={fieldsData?.meta}
      pagination={pagination}
      isLoading={isLoading}
      onPaginationChange={updatePagination}
    />
  );
};
