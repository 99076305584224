import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";
import { Row } from "react-table";

import {
  BooleanCell,
  ChipCell,
  ChipCellValue,
  DateCell,
  LinkCell,
  LinkCellConfig,
  TextCell,
} from "@ag/design-system/organisms";

import { TableSortingState, useTable } from "~components/table";
import { getCountryWithFlag } from "~helpers/countries";

import { Inventory } from "../entities/inventory";
import { getCertificaGroupStatusChip } from "../helpers/certificate-group-statuses";

type TableData = {
  id: string;
  importId: string;
  fieldId: LinkCellConfig;
  countryCode: string;
  cropTypeCode: string;
  vintageYear: string | null;
  status: ChipCellValue;
  idRange: string;
  eligibleIso: boolean | null;
  eligibleVerra: boolean | null;
  footprint: number | null;
  isoQuantityReductionNet: number | null;
  isoQuantityRemovalNet: number | null;
  isoQuantityReductionGross: number | null;
  isoQuantityRemovalGross: number | null;
  isoQuantityPremiumPoolReduction: number | null;
  isoQuantityPremiumPoolRemoval: number | null;
  isoQuantityBufferReduction: number | null;
  isoQuantityBufferRemoval: number | null;
  isoQuantityReductionFee: number | null;
  isoQuantityRemovalFee: number | null;
  verraQuantityReductionNet: number | null;
  verraQuantityRemovalNet: number | null;
  verraQuantityReductionGross: number | null;
  verraQuantityRemovalGross: number | null;
  verraQuantityPremiumPoolReduction: number | null;
  verraQuantityPremiumPoolRemoval: number | null;
  verraQuantityBufferReduction: number | null;
  verraQuantityBufferRemoval: number | null;
  verraQuantityReductionFee: number | null;
  verraQuantityRemovalFee: number | null;
  createdAt: string;
};

export type ActionColumnProps = {
  row: Row<TableData>;
};

function formatIdRange(
  idRange: { minId?: number; maxId?: number } | undefined,
): string {
  if (idRange?.minId && idRange?.maxId) {
    return `${idRange.minId} - ${idRange.maxId}`;
  }
  return "-";
}

function formatCropTypeCode(str: string): string {
  return str
    .toLowerCase()
    .replace(/-/g, " ")
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const useInventoryTable = (
  data: Inventory[] | undefined,
  state: TableSortingState,
  hasCarbonPermission: boolean,
) => {
  const columns = useMemo(
    () => getStickyColumns(hasCarbonPermission),
    [hasCarbonPermission],
  );
  const rowData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
    getRowId: original => String(original.id),
    state: {
      sorting: state.sorting,
      columnPinning: {
        left: ["id", "fieldId"],
      },
    },
    onSortingChange: state.setSorting,
  });
};

function getStickyColumns(hasCarbonPermission: boolean) {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("id", {
      header: "ID",
      cell: TextCell,
      enableSorting: true,
      size: 80,
    }),
    columnHelper.accessor("fieldId", {
      header: "Field ID",
      cell: hasCarbonPermission ? LinkCell : TextCell,
      enableSorting: true,
      size: 120,
    }),
    columnHelper.accessor("importId", {
      header: "Import ID",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("countryCode", {
      header: "Country",
      cell: ({ getValue }) => {
        const country = getValue();
        if (!country) return null;
        const { flag, name } = getCountryWithFlag(country);
        return `${flag} ${name}`;
      },
    }),
    columnHelper.accessor("cropTypeCode", {
      header: "Crop Type",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("vintageYear", {
      header: "Vintage Year",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("idRange", {
      header: "Range",
      cell: TextCell,
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("eligibleIso", {
      header: "ISO Eligible",
      cell: BooleanCell,
    }),
    columnHelper.accessor("eligibleVerra", {
      header: "Verra Eligible",
      cell: BooleanCell,
    }),
    columnHelper.accessor("footprint", {
      header: "Footprint",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("isoQuantityReductionNet", {
      header: "ISO Reduction Net",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("isoQuantityRemovalNet", {
      header: "ISO Removal Net",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("isoQuantityReductionGross", {
      header: "ISO Reduction Gross",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("isoQuantityRemovalGross", {
      header: "ISO Removal Gross",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("isoQuantityPremiumPoolReduction", {
      header: "ISO Premium Pool Reduction",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("isoQuantityPremiumPoolRemoval", {
      header: "ISO Premium Pool Removal",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("isoQuantityBufferReduction", {
      header: "ISO Buffer Reduction",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("isoQuantityBufferRemoval", {
      header: "ISO Buffer Removal",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("isoQuantityReductionFee", {
      header: "ISO Reduction Fee",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("isoQuantityRemovalFee", {
      header: "ISO Removal Fee",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("verraQuantityReductionNet", {
      header: "Verra Reduction Net",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("verraQuantityRemovalNet", {
      header: "Verra Removal Net",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("verraQuantityReductionGross", {
      header: "Verra Reduction Gross",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("verraQuantityRemovalGross", {
      header: "Verra Removal Gross",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("verraQuantityPremiumPoolReduction", {
      header: "Verra Premium Pool Reduction",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("verraQuantityPremiumPoolRemoval", {
      header: "Verra Premium Pool Removal",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("verraQuantityBufferReduction", {
      header: "Verra Buffer Reduction",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("verraQuantityBufferRemoval", {
      header: "Verra Buffer Removal",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("verraQuantityReductionFee", {
      header: "Verra Reduction Fee",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("verraQuantityRemovalFee", {
      header: "Verra Removal Fee",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("createdAt", {
      header: "Created At",
      cell: DateCell,
      enableSorting: true,
    }),
  ];
}

function getRowData(inventoryList: Inventory[] | undefined): TableData[] {
  if (!inventoryList) return [];

  return inventoryList.map(inventory => ({
    id: inventory.id,
    importId: inventory.importId,
    fieldId: {
      title: inventory.fieldId,
      url: `/carbon/fields/${inventory.fieldId}`,
    },
    countryCode: inventory.countryCode,
    cropTypeCode: formatCropTypeCode(inventory.cropTypeCode),
    vintageYear: inventory.vintageYear ?? "-",
    status: getCertificaGroupStatusChip(inventory.status),
    idRange: formatIdRange(inventory.idRange ?? undefined),
    eligibleIso: inventory.eligibleIso,
    eligibleVerra: inventory.eligibleVerra,
    footprint: inventory.footprint,
    isoQuantityReductionNet: inventory.isoQuantityReductionNet,
    isoQuantityRemovalNet: inventory.isoQuantityRemovalNet,
    isoQuantityReductionGross: inventory.isoQuantityReductionGross,
    isoQuantityRemovalGross: inventory.isoQuantityRemovalGross,
    isoQuantityPremiumPoolReduction: inventory.isoQuantityPremiumPoolReduction,
    isoQuantityPremiumPoolRemoval: inventory.isoQuantityPremiumPoolRemoval,
    isoQuantityBufferReduction: inventory.isoQuantityBufferReduction,
    isoQuantityBufferRemoval: inventory.isoQuantityBufferRemoval,
    isoQuantityReductionFee: inventory.isoQuantityReductionFee,
    isoQuantityRemovalFee: inventory.isoQuantityRemovalFee,
    verraQuantityReductionNet: inventory.verraQuantityReductionNet,
    verraQuantityRemovalNet: inventory.verraQuantityRemovalNet,
    verraQuantityReductionGross: inventory.verraQuantityReductionGross,
    verraQuantityRemovalGross: inventory.verraQuantityRemovalGross,
    verraQuantityPremiumPoolReduction:
      inventory.verraQuantityPremiumPoolReduction,
    verraQuantityPremiumPoolRemoval: inventory.verraQuantityPremiumPoolRemoval,
    verraQuantityBufferReduction: inventory.verraQuantityBufferReduction,
    verraQuantityBufferRemoval: inventory.verraQuantityBufferRemoval,
    verraQuantityReductionFee: inventory.verraQuantityReductionFee,
    verraQuantityRemovalFee: inventory.verraQuantityRemovalFee,
    createdAt: inventory.createdAt,
  }));
}
