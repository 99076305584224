import pick from "lodash/pick";

import {
  SoilCultivationPracticeAttribute,
  SoilCultivationPracticeData,
  SoilCultivationPracticeQuestionnaireAttribute,
  getQuestionnaireConfig,
} from "@ag/carbon/forms/baseline/iso";
import { checkDependencyValueMatch } from "@ag/components/Questionnaire";

import { FieldDefinitionDetails } from "~queries/carbon/fields";

export const usePartial = (
  fieldDefinition: FieldDefinitionDetails | undefined,
) => {
  const initialValues: SoilCultivationPracticeData | undefined = fieldDefinition
    ? pick<FieldDefinitionDetails, SoilCultivationPracticeAttribute>(
        fieldDefinition,
        Object.values(SoilCultivationPracticeAttribute),
      )
    : undefined;

  if (initialValues) {
    checkDependencyValueMatch({
      questions: getQuestionnaireConfig(),
      value: initialValues[SoilCultivationPracticeAttribute.Questionnaire],
      onDependencyValueMismatch: questionKey => {
        initialValues[SoilCultivationPracticeAttribute.Questionnaire][
          questionKey as SoilCultivationPracticeQuestionnaireAttribute
        ] = null;
      },
    });
  }

  return {
    initialValues,
  };
};
