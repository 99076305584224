import api from "@flatfile/api";
import {
  CellValueUnion,
  RecordWithLinks,
  ValidationMessage,
} from "@flatfile/api/api";
import axios from "axios";
import { SetStateAction } from "jotai";
import { chunk, groupBy } from "lodash";
import { ReactNode } from "react";

import { FieldActualsInputType } from "@ag/carbon/types";

import { Field, getField } from "~features/field";
import {
  UpdateFieldActualsData,
  updateFieldActuals,
} from "~features/field/api/update-field-actuals";
import {
  QualityControlStatus,
  runFieldsQualityControl,
} from "~features/quality-control";
import { changeFieldsStatus } from "~features/quality-control/api/change-fields-status";

import {
  Row,
  RowItem,
  getCompleteReportModalContent,
} from "../components/bulk-cqc-edits-modal/helpers";
import { CQCEditsData } from "../entities/cqc-edits";

type HandleUpdateFieldActualsParams = {
  recordKey: keyof CQCEditsData;
  recordCommentKey:
    | "tillingRateComment"
    | "energyConsumptionAmountHaComment"
    | "coverCropsAdoptionComment"
    | "cropGrossYieldComment"
    | "cropResidueManagementComment";
  row: Row<CQCEditsData>;
  field: Field;
  dataUpdateFailedRowItems: RowItem[];
  emptyCommentRowItems: RowItem[];
};

const handleUpdateFieldActuals = async ({
  row,
  field,
  recordKey,
  recordCommentKey,
  dataUpdateFailedRowItems: failedRequestsRowItems,
  emptyCommentRowItems,
}: HandleUpdateFieldActualsParams) => {
  const comment = row[recordCommentKey];

  try {
    /**
     * Check if comment is empty and warn user at completion
     * because can't do update without comment message
     */
    if (!comment) {
      emptyCommentRowItems.push({ recordKey: recordCommentKey, row });
      throw new Error("Comment is required");
    }

    const carbonFieldActualData: UpdateFieldActualsData = {
      carbonFieldActualInputType: FieldActualsInputType.V2022,
      carbonFieldActualInput: {
        [recordKey]: row[recordKey],
      },
      comment: {
        text: comment,
      },
    };

    await updateFieldActuals({
      fieldId: field.id,
      fieldActualsId: field.carbonFieldActual[0].id,
      carbonFieldActualData,
    });
  } catch (err) {
    failedRequestsRowItems.push({
      recordKey,
      recordCommentKey,
      row,
    });

    throw err;
  }
};

export type BulkUpdatCQCEditsParams = {
  context: {
    jobId: string;
    sheetId: string;
    records: RecordWithLinks[];
  };
  options: {
    harvestYear: number;
    setIsModalOpen: (value: SetStateAction<boolean>) => void;
    setModalContent: (value: SetStateAction<ReactNode>) => void;
  };
};

export async function bulkUpdatCQCEdits({
  context,
  options,
}: BulkUpdatCQCEditsParams) {
  const { jobId, sheetId, records } = context;
  const { harvestYear, setIsModalOpen, setModalContent } = options;
  /**
   * Keep track of status for info messages during progress,
   * and to add messages at completion/failure.
   */
  const updatedRows: Row<CQCEditsData>[] = [];

  // Array of rows with errors grouped by type
  const qcApprovedRowItems: RowItem[] = [];
  const nonExstingFieldsRowItems: RowItem[] = [];
  const nonExstingFielActualsRowItems: RowItem[] = [];
  const actualsNotBelongsToFieldRowItems: RowItem[] = [];
  const emptyCommentRowItems: RowItem[] = [];
  const dataUpdateFailedRowItems: RowItem[] = [];
  const fieldFallowRowItems: RowItem[] = [];
  const qcStatusUpdateFailedRowItems: RowItem[] = [];

  try {
    const cqcEditsRows: Row<CQCEditsData>[] = records.map(record => {
      const entity: Record<string, CellValueUnion | undefined> = {};
      for (const [key, value] of Object.entries(record.values)) {
        entity[key] = value.value;
      }
      return {
        ...(entity as CQCEditsData),
        record,
      };
    });

    // Fetch all fields
    const fieldIds = [...new Set(cqcEditsRows.map(row => row.fieldId))];

    const allFields = await Promise.all(
      fieldIds.map(async fieldId => {
        try {
          return await getField(fieldId, {
            actualHarvestYear: harvestYear,
          });
        } catch (err) {
          return null;
        }
      }),
    );

    const allFieldsById = groupBy(allFields, field => field?.id);

    await api.jobs.ack(jobId, {
      info: `Fetched ${cqcEditsRows.length} fields`,
      progress: 10,
    });

    const updateRequests: {
      row: Row<CQCEditsData>;
      field: Field;
    }[] = [];

    for (const cqcEditsRow of cqcEditsRows) {
      const { fieldId, actualId } = cqcEditsRow;
      const [field] = allFieldsById[fieldId] ?? [];

      /**
       * Check if fieldId exist and belongs to a harvest year and warn user at completion.
       */
      if (!field) {
        nonExstingFieldsRowItems.push({
          row: cqcEditsRow,
          recordKey: "fieldId",
        });
        continue;
      }

      const [fieldActuals] = field.carbonFieldActual;

      /**
       * Check if actualsId exist and warn user at completion.
       */
      if (!fieldActuals) {
        nonExstingFielActualsRowItems.push({
          row: cqcEditsRow,
          recordKey: "actualId",
        });
        continue;
      }

      // 1. Check if actuals belongs to the field and warn user at completion.
      // TODO: Fix id type in the schema as currently it's a number but should be a string
      if (String(field.carbonFieldActual[0].id) !== String(actualId)) {
        actualsNotBelongsToFieldRowItems.push({
          row: cqcEditsRow,
          recordKey: "actualId",
        });
        continue;
      }

      // 2. Check if actuals is already fallow and warn user at completion.
      if (field.carbonFieldActual[0].fallow) {
        fieldFallowRowItems.push({
          row: cqcEditsRow,
          recordKey: "fieldId",
        });
        continue;
      }

      // 3. Check if actual "qc status" is equal to qc_approved and warn user at completion.
      if (
        field.carbonFieldActual[0].qcStatus === QualityControlStatus.Approved
      ) {
        qcApprovedRowItems.push({ recordKey: "fieldId", row: cqcEditsRow });

        continue;
      }

      updateRequests.push({
        row: cqcEditsRow,
        field,
      });
    }

    if (updateRequests.length) {
      // Max limit for API is 100 fields per batch when updating the fieldsStatus
      const updateStatusBatches = chunk(updateRequests, 100);

      // 4. Change status of the field to non_conformance before update
      await updateStatusBatches.forEach(async updateBatch => {
        await changeFieldsStatus({
          fieldIds: updateBatch.map(({ row }) => String(row.fieldId)),
          payload: {
            newStatus: QualityControlStatus.NonConformance,
          },
        });
      });
    }

    const updateBatches = chunk(updateRequests, 20);

    for (const updateBatch of updateBatches) {
      const percentageDone =
        updateBatches.indexOf(updateBatch) / updateBatches.length;

      await Promise.all(
        updateBatch.map(async ({ row, field }) => {
          const rowAttributesUpdates = [];

          // 5. Update coverCropsAdoption data if exists
          if (row.coverCropsAdoption) {
            rowAttributesUpdates.push(
              handleUpdateFieldActuals({
                recordKey: "coverCropsAdoption",
                recordCommentKey: "coverCropsAdoptionComment",
                row,
                field,
                dataUpdateFailedRowItems,
                emptyCommentRowItems,
              }),
            );
          }

          // 6. Update energyConsumptionAmountHa data if exists
          if (row.energyConsumptionAmountHa) {
            rowAttributesUpdates.push(
              handleUpdateFieldActuals({
                recordKey: "energyConsumptionAmountHa",
                recordCommentKey: "energyConsumptionAmountHaComment",
                row,
                field,
                dataUpdateFailedRowItems,
                emptyCommentRowItems,
              }),
            );
          }

          // 7. Update tillingRate data if exists
          if (row.tillingRate) {
            rowAttributesUpdates.push(
              handleUpdateFieldActuals({
                recordKey: "tillingRate",
                recordCommentKey: "tillingRateComment",
                row,
                field,
                dataUpdateFailedRowItems,
                emptyCommentRowItems,
              }),
            );
          }

          // 7. Update cropGrossYield data if exists
          if (row.cropGrossYield) {
            rowAttributesUpdates.push(
              handleUpdateFieldActuals({
                recordKey: "cropGrossYield",
                recordCommentKey: "cropGrossYieldComment",
                row,
                field,
                dataUpdateFailedRowItems,
                emptyCommentRowItems,
              }),
            );
          }

          // 8. Update cropGrossYield data if exists
          if (row.cropResidueManagement) {
            rowAttributesUpdates.push(
              handleUpdateFieldActuals({
                recordKey: "cropResidueManagement",
                recordCommentKey: "cropResidueManagementComment",
                row,
                field,
                dataUpdateFailedRowItems,
                emptyCommentRowItems,
              }),
            );
          }

          try {
            await Promise.all(rowAttributesUpdates);

            try {
              // 9. Run assurance for updated fields
              await runFieldsQualityControl({
                year: harvestYear,
                fieldIds: [String(field.id)],
              });

              try {
                // 10. Change status of the field to the new status when update is successful
                const response = await changeFieldsStatus({
                  fieldIds: [String(field.id)],
                  payload: {
                    newStatus: row.cqcStatus,
                  },
                });

                // API retuers an error in the success part of the response so we need to check for it
                if (response[0].error) {
                  throw new Error(response[0].error.message);
                }

                updatedRows.push(row);
              } catch (err) {
                qcStatusUpdateFailedRowItems.push({
                  recordKey: "cqcStatus",
                  row,
                });

                throw err;
              }
            } catch (err) {
              try {
                // 11. If the errors occur, use the backup status of processing
                await changeFieldsStatus({
                  fieldIds: [String(field.id)],
                  payload: {
                    newStatus: QualityControlStatus.Processing,
                  },
                });
              } catch (err) {
                qcStatusUpdateFailedRowItems.push({
                  recordKey: "cqcStatus",
                  row,
                });
              }
            }
          } catch (err) {
            // Error is handled in the catch block inside handleUpdateFieldActuals function
          }
        }),
      );

      await api.jobs.ack(jobId, {
        info: `Updated ${updatedRows.length} fields`,
        // Progress from 30 -> 80
        progress: 30 + Math.floor(percentageDone * 50),
      });
    }

    // Show error on the record level
    if (nonExstingFieldsRowItems.length) {
      await api.records.update(
        sheetId,
        nonExstingFieldsRowItems.map(item => {
          const message: ValidationMessage = {
            type: "error",
            message: `FieldId does not exist in the harvestYear ${harvestYear}`,
          };

          return addRecordMessage(item.recordKey, item.row.record, message);
        }),
      );
    }

    // Show error on the record level
    if (nonExstingFielActualsRowItems.length) {
      await api.records.update(
        sheetId,
        nonExstingFielActualsRowItems.map(item => {
          const message: ValidationMessage = {
            type: "error",
            message: "Field actuals does not exist for the given field",
          };

          return addRecordMessage(item.recordKey, item.row.record, message);
        }),
      );
    }

    // Show error on the record level
    if (actualsNotBelongsToFieldRowItems.length) {
      await api.records.update(
        sheetId,
        actualsNotBelongsToFieldRowItems.map(item => {
          const message: ValidationMessage = {
            type: "error",
            message: "ActualsId does not belong to the fieldId",
          };

          return addRecordMessage(item.recordKey, item.row.record, message);
        }),
      );
    }

    // Show error on the record level
    if (qcApprovedRowItems.length) {
      await api.records.update(
        sheetId,
        qcApprovedRowItems.map(item => {
          const message: ValidationMessage = {
            type: "error",
            message:
              "CQC status is approved for this field, so the data can't be edited",
          };

          return addRecordMessage(item.recordKey, item.row.record, message);
        }),
      );
    }

    // Show error on the record level
    if (emptyCommentRowItems.length) {
      await api.records.update(
        sheetId,
        emptyCommentRowItems.map(item => {
          const message: ValidationMessage = {
            type: "error",
            message: "Comment is required to update the data",
          };

          return addRecordMessage(item.recordKey, item.row.record, message);
        }),
      );
    }

    // Show complete report modal with success/error messages
    const completeRepormModalContent = getCompleteReportModalContent({
      harvestYear,
      updatedRows,
      qcApprovedRowItems,
      dataUpdateFailedRowItems,
      nonExstingFieldsRowItems,
      nonExstingFielActualsRowItems,
      actualsNotBelongsToFieldRowItems,
      qcStatusUpdateFailedRowItems,
      fieldFallowRowItems,
    });

    setIsModalOpen(true);
    setModalContent(completeRepormModalContent);

    await api.jobs.complete(jobId);
  } catch (err) {
    await api.jobs.fail(jobId, {
      outcome: {
        acknowledge: true,
        heading: "Update failed :(",
        message: getErrorMessage(err),
      },
    });
  }
}

function getErrorMessage(err: unknown) {
  const message = axios.isAxiosError(err)
    ? err.response?.data?.errors ?? err?.message
    : (err as Error).message ?? err?.toString() ?? err;

  if (Array.isArray(message)) {
    return message.join(", ");
  }

  if (typeof message === "string") return message;

  return JSON.stringify(message);
}

function addRecordMessage(
  key: string,
  record: RecordWithLinks,
  message: ValidationMessage,
): RecordWithLinks {
  return {
    ...record,
    values: {
      ...record.values,
      [key]: {
        ...record.values[key],
        messages: [message],
      },
    },
  };
}
