import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { typography } from "@ag/design-system/tokens";
import { InputField } from "@ag/form-fields";

import {
  useImportIsoVcuMutation,
  useImportIsoVcuStore,
} from "~features/import-vcu";

import * as styles from "../../import-batch.css";
import * as pageStyles from "../../styles.css";

type Props = {
  onGoNextStep: () => void;
};

const validateVcuBatchImport = () =>
  z.object({
    quantity: z.string().nonempty({
      message: "Enter a valid number",
    }),
    vintage: z.string().nonempty({
      message: "Please select a year",
    }),
    methodology: z.string().nonempty({
      message: "Please select a methodology",
    }),
  });

export type ValidateVcuBatchImportFormValues = z.infer<
  ReturnType<typeof validateVcuBatchImport>
>;

const ImportBatch = ({ onGoNextStep }: Props) => {
  const { setId, isoVcuDetails, setIsFormValid, setIsoVcuDetails } =
    useImportIsoVcuStore();
  const { quantity, vintageYear, methodology } = isoVcuDetails;

  const importIsoVcuMutation = useImportIsoVcuMutation();

  const {
    formState: { errors, isValid },
    register,
    handleSubmit,
  } = useForm<ValidateVcuBatchImportFormValues>({
    resolver: zodResolver(validateVcuBatchImport()),
    defaultValues: {
      quantity: quantity ? quantity.toString() : "",
      vintage: vintageYear.toString(),
      methodology: methodology.toString(),
    },
  });

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid, setIsFormValid]);

  const onSubmit = (data: ValidateVcuBatchImportFormValues) => {
    const isoVcuDetails = {
      quantity: Number(data.quantity),
      vintageYear: Number(data.vintage),
      methodology: data.methodology,
    };

    setIsoVcuDetails(isoVcuDetails);

    importIsoVcuMutation.mutate(isoVcuDetails, {
      onSuccess: data => {
        setId(data.id);
        onGoNextStep();
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={pageStyles.root}>
      <div className={styles.container}>
        <h2 className={typography.h2}>
          Enter the details of the ISO certificates to import
        </h2>

        <div className={styles.detailsBox}>
          <div className={styles.detailsInputBox}>
            <InputField
              type="number"
              label="Quantity"
              placeholder="e.g. 12345"
              {...register("quantity")}
              error={errors.quantity}
            />

            <InputField
              type="number"
              label="Vintage"
              placeholder="Select year"
              {...register("vintage")}
              error={errors.vintage}
            />
            <InputField
              label="ISO methodology"
              placeholder="ISO methodology"
              {...register("methodology")}
              error={errors.methodology}
            />
          </div>
        </div>
      </div>
      <footer className={pageStyles.footer}>
        <div className={pageStyles.prevButtonBox}>
          <Button
            type="button"
            icon="chevron-left"
            iconPosition="before"
            variant="secondary"
            onClick={() => history.back()}
          >
            Previous
          </Button>
        </div>

        <div className={pageStyles.nextButtonBox}>
          <Button
            type="submit"
            icon="chevron-right"
            iconPosition="after"
            isLoading={importIsoVcuMutation.isLoading}
          >
            Next
          </Button>
        </div>
      </footer>
    </form>
  );
};

export default ImportBatch;
