import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "rooks";

import { Button, InfoBox } from "@ag/design-system/atoms";
import { ComboBox } from "@ag/design-system/molecules";

import { useUpdateRequestStore } from "~features/certificate";
import {
  useCertificateBeneficiariesQuery,
  useCreateCertificateBeneficiaryMutation,
} from "~features/certificate-beneficiary";

import * as pageStyles from "../styles.css";
import * as styles from "./beneficiaries.css";

type Props = {
  onGoPrevStep: () => void;
  onGoNextStep: () => void;
};

const SUGGESTED_BENEFICIARY = "beneficiary-0";

const BeneficiariesStep = ({ onGoPrevStep, onGoNextStep }: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");
  const { beneficiary, setBeneficiary } = useUpdateRequestStore();
  const [showBeneficiaryAddedBanner, setShowBeneficiaryAddedBanner] =
    useState(false);

  const setInputValueDebounced = useDebounce(setInputValue, 300);

  const { data: beneficiaries, isLoading } = useCertificateBeneficiariesQuery(
    {
      filters: {
        name: inputValue,
      },
    },
    {
      enabled: inputValue.length > 0,
    },
  );

  const isNewBeneficiary = useMemo(
    () =>
      beneficiaries &&
      inputValue.length &&
      !beneficiaries.items.find(b => b.name === inputValue),
    [beneficiaries, inputValue],
  );

  // Update input value once beneficiary is selected
  useEffect(() => {
    setInputValue(beneficiary?.name ?? "");
    setValue(beneficiary?.id ?? "");
  }, [beneficiary]);

  const createBeneficiary = useCreateCertificateBeneficiaryMutation();

  const handleSetBeneficiary = (id: string) => {
    if (id === SUGGESTED_BENEFICIARY) {
      setValue(SUGGESTED_BENEFICIARY);
      handleCreateBeneficiary();
    } else {
      const selectedBeneficiary = beneficiaries?.items.find(
        beneficiary => beneficiary.id === id,
      );

      if (selectedBeneficiary) {
        setBeneficiary({
          id: selectedBeneficiary.id,
          name: selectedBeneficiary.name,
        });
      }
    }
  };

  const handleCreateBeneficiary = () => {
    createBeneficiary.mutate(inputValue, {
      onSuccess: beneficiary => {
        setBeneficiary({
          id: beneficiary.id,
          name: beneficiary.name,
        });

        setShowBeneficiaryAddedBanner(true);
      },
    });
  };

  return (
    <>
      <div className={styles.container}>
        <h2 className={pageStyles.title}>Select beneficiary</h2>

        <ComboBox.Root
          key={value}
          value={value}
          emptyText="No beneficiaries found"
          loadingText="Loading beneficiaries..."
          isAsync
          isLoading={isLoading}
          onChange={handleSetBeneficiary}
          onInput={value => setInputValueDebounced(value)}
        >
          {isNewBeneficiary && (
            <ComboBox.Section title="Create new beneficiary">
              {[
                <ComboBox.Item key={SUGGESTED_BENEFICIARY} icon="plus">
                  {inputValue}
                </ComboBox.Item>,
              ]}
            </ComboBox.Section>
          )}

          {beneficiaries && beneficiaries.items.length > 0 && (
            <ComboBox.Section title="Search suggestions">
              {beneficiaries.items.map(beneficiary => (
                <ComboBox.Item key={beneficiary.id}>
                  {beneficiary.name}
                </ComboBox.Item>
              ))}
            </ComboBox.Section>
          )}
        </ComboBox.Root>

        {showBeneficiaryAddedBanner && beneficiary && (
          <InfoBox icon="check" variant="success">
            <span>
              <b>{beneficiary?.name}</b> has been created as a beneficiary, with
              ID <b>{beneficiary?.id}</b>
            </span>
          </InfoBox>
        )}
      </div>

      <footer className={pageStyles.footer}>
        <div className={pageStyles.prevButtonArea}>
          <Button
            icon="chevron-left"
            variant="secondary"
            onClick={onGoPrevStep}
          >
            Previous
          </Button>
        </div>

        <div className={pageStyles.nextButtonArea}>
          <Button
            icon="chevron-right"
            iconPosition="after"
            onClick={onGoNextStep}
            disabled={!beneficiary}
          >
            Next
          </Button>
        </div>
      </footer>
    </>
  );
};

export default BeneficiariesStep;
