import { useState } from "react";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { TextareaField } from "@ag/form-fields";

export type MarkForFollowUpModalProps = {
  fieldId: string;
  isOpen: boolean;
  onSubmitFollowUp: (comment: string) => void;
  onRequestClose: () => void;
};

export function MarkForFollowUpModal({
  fieldId,
  isOpen,
  onSubmitFollowUp,
  onRequestClose,
}: MarkForFollowUpModalProps) {
  const [comment, setComment] = useState("");

  if (!fieldId) return null;

  return (
    <Modal.Root size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <Modal.Header>
        <Modal.Title>Mark for follow up</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <TextareaField
          value={comment}
          cols={30}
          label="Describe the information you need"
          maxLength={150}
          rows={4}
          onChange={value => setComment(value)}
        />
      </Modal.Content>
      <Modal.Footer
        render={onClose => (
          <>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>

            <Button variant="primary" onClick={() => onSubmitFollowUp(comment)}>
              Submit
            </Button>
          </>
        )}
      />
    </Modal.Root>
  );
}
