import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  CertificaGroupStatus,
  Inventory,
  InventorySchema,
} from "../entities/inventory";

type InventoryCertificateGroupsParams = MetaArgs & {
  filters?: Partial<{
    importId?: string | undefined;
    fieldId?: string | undefined;
    status?: CertificaGroupStatus[] | undefined;
    vintageYear?: string[] | undefined;
    countryCode?: string[] | undefined;
    cropTypeCode?: string[] | undefined;
    eligibleIso?: string | undefined;
    eligibleVerra?: string | undefined;
  }>;
  sort?: Partial<Record<keyof Inventory, SortingDirection | undefined>>;
};

export const generateInventoryCertificateGroups = (
  params?: InventoryCertificateGroupsParams | undefined,
) => ["inventory-certificate-groups", params].filter(Boolean);

const getInventoryCertificateGroups = async (
  params: InventoryCertificateGroupsParams | undefined,
) => {
  const response = await axios.get<CommonPaginatedResponse<Inventory>>(
    "/api/v1/inventory/certificate-groups",
    {
      params: {
        ...params,
        limit: params?.limit ?? DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(InventorySchema),
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useInventoryCertificateGroupsQuery = (
  params?: InventoryCertificateGroupsParams,
) =>
  useQuery(
    generateInventoryCertificateGroups(params),
    () => getInventoryCertificateGroups(params),
    {
      keepPreviousData: true,
    },
  );
