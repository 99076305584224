import { withTypes } from "react-final-form";
import { useParams } from "react-router-dom";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import withRouteParams from "~hocs/withRouteParams";
import DetailsLayout from "~layouts/details-layout";
import { CropType, useCropTypeQuery } from "~queries/carbon/crop-types";

import Form from "./Form";
import { getInitialValues } from "./helpers";

const { Form: FinalForm } = withTypes<Partial<CropType>>();

const EditCropType = () => {
  const { id: cropTypeIdParam } = useParams();
  const cropTypeId = Number(cropTypeIdParam);

  const { data: cropType } = useCropTypeQuery(cropTypeId);

  const initialValues = getInitialValues(cropType);

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>Crop type details</DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        {cropType && (
          <FinalForm
            initialValues={initialValues}
            render={(): React.ReactNode => <Form />}
            onSubmit={() => {}}
          />
        )}
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default withRouteParams(EditCropType);
