import { z } from "zod";

import {
  CertificateGroupMethodology,
  CertificateGroupStandard,
  CertificateGroupStatus,
  CertificateGroupType,
} from "../types/certificate-group";

export const certificateGroupDocumentSchema = z.object({
  filename: z.string(),
  url: z.string(),
});

export type CertificateGroupDocument = z.infer<
  typeof certificateGroupDocumentSchema
>;

export const CertificateGroupSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(CertificateGroupType),
  status: z.nativeEnum(CertificateGroupStatus),
  quantity: z.number(),
  chosenQuantity: z.number().optional(),
  standard: z.nativeEnum(CertificateGroupStandard),
  chosenRange: z.string().optional(),
  methodology: z.nativeEnum(CertificateGroupMethodology),
  isPublic: z.boolean(),
  vintageYear: z.number(),
  minId: z.string(),
  maxId: z.string(),
  userId: z.string(),
  certificateBeneficiary: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  country: z.object({
    code: z.string(),
  }),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  documents: z.array(certificateGroupDocumentSchema),
});

export type CertificateGroup = z.infer<typeof CertificateGroupSchema>;
