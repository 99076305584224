import I18n from "@ag/i18n";

export const getCropTypeCategoryOptions = () => [
  {
    label: I18n.t("js.carbon.crop_types.category_type.perennial"),
    value: "perennial",
  },
  {
    label: I18n.t("js.carbon.crop_types.category_type.spring"),
    value: "spring",
  },
  {
    label: I18n.t("js.carbon.crop_types.category_type.winter"),
    value: "winter",
  },
];
