import {
  Cell,
  Header,
  RowData,
  Table as TTable,
  flexRender,
} from "@tanstack/react-table";
import { cva } from "class-variance-authority";

import { Icon } from "~assets";
import { IconButton, Tooltip } from "~atoms";
import { cn } from "~utils";

import { getGroupedState, getSortIconProps } from "./helpers";

/* -------------------------------------------------------------------------------------------------
 * Table
 * -----------------------------------------------------------------------------------------------*/
type TableProps<TData extends RowData> = {
  instance: TTable<TData>;
  className?: string;
  emptyState?: React.ReactNode;
  isLoading?: boolean;
};

export function Table<TData extends RowData>({
  instance,
  emptyState,
  className,
  isLoading,
}: TableProps<TData>) {
  const headers = instance
    .getFlatHeaders()
    .filter(header => !header.subHeaders.length && !header.isPlaceholder);

  const { rows } = instance.getRowModel();
  const { isUsingCellBackgrounds } = instance.options.meta ?? {};

  // Check if table have any footer columns defined
  const hasFooter =
    instance
      .getFooterGroups()
      .flatMap(footerGroup => footerGroup.headers)
      .map(header => header.column.columnDef.footer)
      .filter(Boolean).length > 0;

  return (
    <table
      className={cn(
        "[--cell-bg:theme(colors.grey.white)]",
        "[--cell-hover-bg:theme(colors.grey.100)]",

        !isUsingCellBackgrounds &&
          "[--cell-invalid-bg:theme(colors.messaging.error.100)] [--cell-selected-bg:theme(colors.data.purple.100)]",

        className,
      )}
    >
      {/**
       * Cols are used to represent each column,
       * and can style borders and backgrounds across multiple cells,
       * both in header and body.
       */}
      <colgroup>
        {headers.map(header => (
          <Col key={header.id} header={header} />
        ))}
      </colgroup>

      <thead>
        <tr className="sticky top-0 z-low bg-grey-white text-p2 text-grey-800 shadow-[0_1px_0_0_theme(colors.grey.300)]">
          {headers.map(header => (
            <HeaderCell key={header.id} header={header} />
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, index) => (
          <tr
            key={row.id}
            className={cn(
              "border-b text-p2 text-grey-black",
              // when footer is present, last row should have a border with different color
              hasFooter && rows.length - 1 === index
                ? "border-grey-600"
                : "border-grey-300",
              "bg-[--cell-bg] hover:bg-[--cell-hover-bg]",

              {
                "border-accent-100 bg-[--cell-selected-bg]":
                  row.getIsSelected(),
                "border-messaging-error-700 bg-[--cell-invalid-bg]": Boolean(
                  instance.options.meta?.errors?.[row.id]?.length,
                ),
              },
            )}
          >
            <>
              {row.getVisibleCells().map(cell => (
                <BodyCell
                  cell={cell}
                  key={cell.id}
                  testId={`${row.id}-${cell.column.id}`}
                />
              ))}
            </>
          </tr>
        ))}

        {!isLoading && !rows.length && (
          <tr>
            <td colSpan={headers.length}>{emptyState ?? "-"}</td>
          </tr>
        )}
      </tbody>

      {hasFooter && (
        <tfoot>
          {instance.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <FooterCell key={header.id} header={header} />
              ))}
            </tr>
          ))}
        </tfoot>
      )}
    </table>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Col
 * -----------------------------------------------------------------------------------------------*/
type ColProps<TData extends RowData> = {
  header: Header<TData, unknown>;
};

function Col<TData extends RowData>({ header }: ColProps<TData>) {
  const groupedState = getGroupedState(header.column);

  return (
    <col
      key={header.id}
      className={cn(
        "border-grey-300 bg-grey-white",

        groupedState.isGroupOpen && {
          "border-l": groupedState.isFirstInGroup,
          "border-r": groupedState.isLastInGroup,
        },
      )}
    />
  );
}

/* -------------------------------------------------------------------------------------------------
 * HeaderCell
 * -----------------------------------------------------------------------------------------------*/
type HeaderCellProps<TData extends RowData> = {
  header: Header<TData, unknown>;
};

function HeaderCell<TData extends RowData>({ header }: HeaderCellProps<TData>) {
  const groupedState = getGroupedState(header.column);

  const { tooltip } = header.column.columnDef.meta ?? {};

  // Toggles the grouping of the columns except the first one,
  // hiding / showing them depending on current grouping state.
  const handleGroupToggled = () =>
    groupedState.columns
      .slice(1)
      .forEach(otherColumn => otherColumn.toggleGrouping());

  return (
    <th
      key={header.id}
      className={cn(
        "whitespace-nowrap bg-grey-white p-4",

        groupedState.isGroupOpen && "bg-grey-100",
        header.column.getIsPinned() && "sticky z-low",
      )}
      style={{
        left:
          header.column.getIsPinned() === "left"
            ? header.column.getStart(header.column.getIsPinned())
            : undefined,
        right:
          header.column.getIsPinned() === "right"
            ? header.column.getStart(header.column.getIsPinned())
            : undefined,
      }}
    >
      <div className="flex items-center gap-4">
        {flexRender(header.column.columnDef.header, header.getContext())}

        {tooltip && (
          <Tooltip
            content={typeof tooltip === "function" ? tooltip() : tooltip}
          >
            <Icon name="info-circle" className="p-2 text-[10px]" />
          </Tooltip>
        )}

        {header.column.columnDef.enableSorting && (
          <IconButton
            variant="text"
            {...getSortIconProps(header.column.getIsSorted(), {
              sortIndex: header.column.getSortIndex(),
              isMulti: header.column.getCanMultiSort(),
            })}
            onClick={() => {
              const direction = header.column.getIsSorted();
              const isMulti = header.column.getCanMultiSort();

              /**
               * When multi sorting, we need a way to remove sorting from a column,
               * as clicking another column will not clear the others.
               * So this means that when the column is "ascending" and you click again,
               * it removes the sorting, instead of going to "descending" again.
               */
              if (isMulti && direction === "asc") {
                header.column.clearSorting();
              } else {
                header.column.toggleSorting(
                  direction !== "desc",
                  header.column.getCanMultiSort(),
                );
              }
            }}
          />
        )}

        {groupedState.isFirstInGroup && !groupedState.isLastInGroup && (
          <IconButton
            variant="text"
            icon={groupedState.isGroupOpen ? "chevron-left" : "chevron-right"}
            onClick={handleGroupToggled}
          />
        )}
      </div>
    </th>
  );
}

/* -------------------------------------------------------------------------------------------------
 * FooterCell
 * -----------------------------------------------------------------------------------------------*/
type FooterCellProps<TData extends RowData> = {
  header: Header<TData, unknown>;
};

function FooterCell<TData extends RowData>({ header }: FooterCellProps<TData>) {
  return (
    <th
      key={header.id}
      className={cn(
        "whitespace-nowrap bg-grey-white p-4",

        header.column.getIsPinned() && "sticky z-low",
      )}
      style={{
        left:
          header.column.getIsPinned() === "left"
            ? header.column.getStart(header.column.getIsPinned())
            : undefined,
        right:
          header.column.getIsPinned() === "right"
            ? header.column.getStart(header.column.getIsPinned())
            : undefined,
      }}
    >
      <div className="flex items-center gap-4 text-p2 text-grey-black">
        {flexRender(header.column.columnDef.footer, header.getContext())}
      </div>
    </th>
  );
}

/* -------------------------------------------------------------------------------------------------
 * BodyCell
 * -----------------------------------------------------------------------------------------------*/

const bodyCellVariants = cva(
  [
    "px-4 py-[18px]",

    // For any default text, we don't want wrapping.
    // Custom cells can overwrite that behavior with flex divs etc.
    "overflow-hidden whitespace-nowrap",
  ],
  {
    variants: {
      background: {
        blue: "bg-data-blue-100",
        green: "bg-data-green-100",
        orange: "bg-data-orange-100",
        red: "bg-messaging-error-100",
        yellow: "bg-messaging-warning-100",
      },

      isPinned: {
        left: "sticky left-0 bg-[--cell-bg]",
        right: "sticky right-0 bg-[--cell-bg]",
        false: "",
      },

      isLastPinned: {
        left: "overflow-visible after:absolute after:-right-2 after:top-0 after:h-full after:w-2 after:shadow-[inset_8px_0_8px_-8px_rgba(0,0,0,0.1)]",
        right:
          "overflow-visible after:absolute after:-left-2 after:top-0 after:h-full after:w-2 after:shadow-[inset_-8px_0_8px_-8px_rgba(0,0,0,0.1)]",
        false: "",
      },
    },
  },
);

type BodyCellProps<TData extends RowData> = {
  cell: Cell<TData, unknown>;
  testId: string;
};

function BodyCell<TData extends RowData>({
  cell,
  testId,
}: BodyCellProps<TData>) {
  const isPinned = cell.column.getIsPinned();

  const reversedColumns = cell.row
    .getVisibleCells()
    .map(cell => cell.column)
    .reverse();

  const lastPinnedColumn = reversedColumns.find(
    col => col.getIsPinned() === isPinned,
  );

  const isLastPinned =
    lastPinnedColumn?.id === cell.column.id ? isPinned : false;

  const context = cell.getContext();

  const cellValue = cell.getValue();

  const { minSize, size, maxSize } = cell.column.columnDef;
  const currentSize = cell.column.getSize();

  let maxWidth = maxSize || undefined;
  let minWidth = minSize || undefined;

  /**
   * If the consumer specified a size, let the cell be that exact size.
   * since we cannot use "width" style in tables, instead set both min/max-width to achieve the same.
   *
   * Note: the reason we use the columnSize, is that we still in some cases allow resizing of size,
   * so the current size might be different from the one defined in columnDef.
   */
  if (size && currentSize) {
    maxWidth = currentSize;
    minWidth = currentSize;
  }

  const background = cell.column.columnDef?.meta?.cellBackground?.(
    cellValue,
    cell.row.original,
  );

  const { id: rowId } = context.row;
  const rowErrors = context.table.options.meta?.errors?.[rowId];
  const cellError = rowErrors?.find(error => error.column === cell.column.id);

  return (
    <td
      key={cell.id}
      className={bodyCellVariants({
        background,
        isPinned,
        isLastPinned,
      })}
      style={{
        left: isPinned === "left" ? cell.column.getStart(isPinned) : undefined,
        right:
          isPinned === "right" ? cell.column.getStart(isPinned) : undefined,
        maxWidth,
        minWidth,
      }}
      data-testid={testId}
    >
      <>
        {flexRender(cell.column.columnDef.cell, context)}

        {cellError?.message ? (
          <Tooltip content={cellError.message}>
            <Icon
              name="circle-exclamation"
              variant="solid"
              className="z-low -mb-0.5 ml-[6px] text-messaging-error-900"
            />
          </Tooltip>
        ) : null}
      </>
    </td>
  );
}
