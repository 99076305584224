import api from "@flatfile/api";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";

import { ToastNotification } from "@ag/utils/services";

import BackButton from "~components/BackButton";
import {
  BulkCQCEditsModal,
  FlatfileEmbed,
  flatfileAccessTokenAtom,
  getEnviromentId,
} from "~features/bulk-upload";
import { bulkUpdatCQCEdits } from "~features/bulk-upload/helpers/bulk-cqc-edits";
import { useCQCEditsFlatfileListener } from "~features/bulk-upload/hooks/use-cqc-edits-listeners";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

const HARVEST_YEAR = 2023;

const BulkCQCEdits = () => {
  const [flatfileAccessToken] = useAtom(flatfileAccessTokenAtom);
  const params = useParams();

  /**
   * Shows an error message to the user, when invalid data is attempted to be submitted.
   */
  const handleInvalidData = async ({ jobId }: { jobId: string }) => {
    await api.jobs.fail(jobId, {
      outcome: {
        acknowledge: true,
        heading: "Invalid data",
        message: "Please fix invalid data before updating (see below)",
      },
    });
  };

  // Create a flatfile listener, and react to any bulk uploads.
  const { listener } = useCQCEditsFlatfileListener({
    harvestYear: HARVEST_YEAR,
    onValidationError: handleInvalidData,
    onStart: bulkUpdatCQCEdits,
    onError: error => ToastNotification.error(error),
  });

  const spaceId = params.spaceId;

  const environmentId = getEnviromentId();

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <BackButton />

        <ListLayout.TopBarTitle>
          Bulk update QC Edits 2023
        </ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <div className="flex-grow p-4">
          {spaceId && flatfileAccessToken ? (
            <FlatfileEmbed
              environmentId={environmentId}
              listener={listener}
              accessToken={flatfileAccessToken}
              spaceId={spaceId}
            />
          ) : null}

          <BulkCQCEditsModal />
        </div>
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default BulkCQCEdits;
