import { z } from "zod";

export enum CertificaGroupStatus {
  IMPORTED = "imported",
  ALLOCATED = "allocated",
  ISSUED = "issued",
  SOLD = "sold",
}

export const InventorySchema = z.object({
  id: z.string(),
  importId: z.string(),
  status: z.nativeEnum(CertificaGroupStatus),
  fieldId: z.string(),
  countryCode: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  cropTypeCode: z.string(),
  vintageYear: z.string().nullable(),
  idRange: z
    .object({
      minId: z.number(),
      maxId: z.number(),
    })
    .nullable(),
  eligibleIso: z.boolean().nullable(),
  eligibleVerra: z.boolean().nullable(),
  footprint: z.number().nullable(),
  isoQuantityReductionNet: z.number().nullable(),
  isoQuantityRemovalNet: z.number().nullable(),
  isoQuantityReductionGross: z.number().nullable(),
  isoQuantityRemovalGross: z.number().nullable(),
  isoQuantityPremiumPoolReduction: z.number().nullable(),
  isoQuantityPremiumPoolRemoval: z.number().nullable(),
  isoQuantityBufferReduction: z.number().nullable(),
  isoQuantityBufferRemoval: z.number().nullable(),
  isoQuantityReductionFee: z.number().nullable(),
  isoQuantityRemovalFee: z.number().nullable(),
  verraQuantityReductionNet: z.number().nullable(),
  verraQuantityRemovalNet: z.number().nullable(),
  verraQuantityReductionGross: z.number().nullable(),
  verraQuantityRemovalGross: z.number().nullable(),
  verraQuantityPremiumPoolReduction: z.number().nullable(),
  verraQuantityPremiumPoolRemoval: z.number().nullable(),
  verraQuantityBufferReduction: z.number().nullable(),
  verraQuantityBufferRemoval: z.number().nullable(),
  verraQuantityReductionFee: z.number().nullable(),
  verraQuantityRemovalFee: z.number().nullable(),
});
export type Inventory = z.infer<typeof InventorySchema>;

export const InventoryFiltersSchema = z.object({
  importId: z.string().optional(),
  fieldId: z.string().optional(),
  status: z.nativeEnum(CertificaGroupStatus).or(z.literal("")).optional(),
  vintageYear: z.string().optional(),
  countryCode: z.string().optional(),
  cropTypeCode: z.string().optional(),
  eligibleIso: z.string().optional(),
  eligibleVerra: z.string().optional(),
});
export type InventoryFilters = z.infer<typeof InventoryFiltersSchema>;
