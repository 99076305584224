import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { type ReactNode, createContext, useEffect } from "react";

import { ampli } from "./generated";

type AmplitudeProps = {
  apiKey: string;
  sessionReplayEnabled: boolean;
};

const AmplitudeContext = createContext<AmplitudeProps | null>(null);

type AmplitudeProviderProps = AmplitudeProps & {
  children: ReactNode;
};

export function AmplitudeProvider({
  children,
  apiKey,
  sessionReplayEnabled,
}: AmplitudeProviderProps) {
  useEffect(() => {
    if (ampli.isLoaded) return;

    ampli.load({
      client: {
        apiKey,
        configuration: {
          defaultTracking: true,
          transport: "beacon",
        },
      },
    });

    // Adds session replays to get detailed insights on user behavior.
    ampli.client.add(
      sessionReplayPlugin({ sampleRate: sessionReplayEnabled ? 1 : 0 }),
    );
  }, [apiKey, sessionReplayEnabled]);

  return (
    <AmplitudeContext.Provider value={{ apiKey, sessionReplayEnabled }}>
      {children}
    </AmplitudeContext.Provider>
  );
}
