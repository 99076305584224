import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { typography } from "@ag/design-system/tokens";

import { useUpdateRequestStore } from "../store/update-request-store";
import * as styles from "./reject-modal.css";

type Props = {
  onRejectTransfer: () => void;
};

export const RejectModal = ({ onRejectTransfer }: Props) => {
  const { setIsRejectModalVisible } = useUpdateRequestStore();

  const handleOnClose = () => {
    setIsRejectModalVisible(false);
  };

  return (
    <Modal.Root
      onRequestClose={handleOnClose}
      className="bg-gray-700"
      size="small"
      isCentered
    >
      <Modal.Content>
        <div className={styles.modalContentWrapper}>
          <div>
            <Icon
              name="shield-check"
              variant="light"
              fontSize={56}
              className={styles.dangerIcon}
            />
            <Icon name="minus" fontSize={56} className={styles.dangerIcon} />
            <Icon
              name="shield-x-mark"
              variant="solid"
              fontSize={56}
              className={styles.dangerIcon}
            />
          </div>

          <div className={styles.modalContentMessage}>
            <span className={typography.h3}>Reject transfer process!</span>
            <p
              className={`${typography.p2} ${styles.alignedTypography} ${styles.typographyArea}`}
            >
              This action will permanently reject and delete this pre-transfer
              request. A new pre-transfer can always be started from scratch.
            </p>
          </div>

          <div className={styles.buttonArea}>
            <Button variant="primary" isDanger onClick={onRejectTransfer}>
              Reject Transfer
            </Button>
            <Button variant="text" onClick={handleOnClose}>
              Let me ask first
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
