import { useJsApiLoader } from "@react-google-maps/api";
import { useIsFetching } from "@tanstack/react-query";
import log from "loglevel";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";

import ErrorBoundary from "~components/error-boundary";
import GlobalLoader from "~components/global-loader";
import { env } from "~config";
import { useAppHistory } from "~hooks";
import Pages from "~pages";

const googleMapsLibraries: ["drawing"] = ["drawing"];

function App() {
  useAppHistory();

  // Higher version of google maps than 3.42.9 crash the Marketplace Markers
  useJsApiLoader({
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    version: "3.42.9",
    libraries: googleMapsLibraries,
  });

  // Set default logger level
  useEffect(() => {
    if (env.REACT_APP_APP_ENVIRONMENT === "production") {
      log.setDefaultLevel(log.levels.ERROR);

      return;
    }

    log.setDefaultLevel(log.levels.DEBUG);
  }, []);

  const isFetching = useIsFetching();

  return (
    <div className="ct-app">
      <ErrorBoundary>
        <Pages />
      </ErrorBoundary>

      <ToastContainer className="text-p2" limit={5} />

      <GlobalLoader isVisible={isFetching > 0} />

      <ReactTooltip className="ct-tooltip" effect="solid" html />
    </div>
  );
}

export default App;
