import { RecordWithLinks } from "@flatfile/api/api";

import { stack } from "@ag/design-system/utils";

import CollapseBox from "~components/collapse-box";
import { CQCFertilisersEditsData } from "~features/bulk-upload/entities/cqc-fertilisers-edits";

import * as styles from "./bulk-cqc-fertilisers-edits-modal.css";

export type Row<T> = T & {
  record: RecordWithLinks;
};

type GetUpdateCompleteMessage = {
  harvestYear: number;
  updatedRows: Row<CQCFertilisersEditsData>[];
  qcApprovedRows: Row<CQCFertilisersEditsData>[];
  dataUpdateFailedRows: Row<CQCFertilisersEditsData>[];
  nonExstingFieldsRows: Row<CQCFertilisersEditsData>[];
  nonExstingFielActualsRows: Row<CQCFertilisersEditsData>[];
  actualsNotBelongsToFieldRows: Row<CQCFertilisersEditsData>[];
  qcStatusUpdateFailedRows: Row<CQCFertilisersEditsData>[];
  fieldFallowRows: Row<CQCFertilisersEditsData>[];
};

export const getCompleteReportModalContent = ({
  harvestYear,
  updatedRows,
  qcApprovedRows,
  dataUpdateFailedRows,
  nonExstingFieldsRows,
  nonExstingFielActualsRows,
  qcStatusUpdateFailedRows,
  actualsNotBelongsToFieldRows,
  fieldFallowRows,
}: GetUpdateCompleteMessage) => {
  // Merge all error types and count unique fields
  const failedRowsAmount = [
    ...new Set(
      [
        ...qcApprovedRows,
        ...dataUpdateFailedRows,
        ...nonExstingFieldsRows,
        ...nonExstingFielActualsRows,
        ...qcStatusUpdateFailedRows,
        ...actualsNotBelongsToFieldRows,
        ...fieldFallowRows,
      ].map(row => row.fieldId),
    ),
  ].length;

  return (
    <div className={stack()}>
      <p className={styles.header}>
        <h4 className={styles.successLabel}>
          Updated {updatedRows.length} fields
        </h4>

        {failedRowsAmount ? (
          <h4 className={styles.errorLabel}>
            Failed {failedRowsAmount} fields
          </h4>
        ) : null}
      </p>

      {failedRowsAmount ? (
        <div className={styles.collapseBox}>
          <CollapseBox.Root isInitiallyOpen>
            <CollapseBox.Title>Errors report</CollapseBox.Title>
            <CollapseBox.Content>
              <div className={styles.collapseBoxContent}>
                {qcApprovedRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: QC status already Approved:
                    </h3>

                    <ul className={styles.errorList}>
                      {qcApprovedRows.map(row => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {nonExstingFieldsRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Field does not exist in the {harvestYear}:
                    </h3>

                    <ul className={styles.errorList}>
                      {nonExstingFieldsRows.map(row => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {nonExstingFielActualsRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Field does not exist in the {harvestYear}:
                    </h3>

                    <ul className={styles.errorList}>
                      {nonExstingFielActualsRows.map(row => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, ActualsId: {row.actualId},
                            UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {actualsNotBelongsToFieldRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: ActualsId does not belongs to the fieldId:
                    </h3>

                    <ul className={styles.errorList}>
                      {actualsNotBelongsToFieldRows.map(row => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, ActualsId: {row.actualId},
                            UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {fieldFallowRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Field is marked as fallow:
                    </h3>

                    <ul className={styles.errorList}>
                      {fieldFallowRows.map(row => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, ActualsId: {row.actualId},
                            UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {dataUpdateFailedRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Update data failed:
                    </h3>

                    <ul className={styles.errorList}>
                      {dataUpdateFailedRows.map(row => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, ActualsId: {row.actualId},
                            UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                {qcStatusUpdateFailedRows.length ? (
                  <div className={styles.errorGroup}>
                    <h3 className={styles.errorTitle}>
                      Reason: Update qc status failed:
                    </h3>

                    <ul className={styles.errorList}>
                      {qcStatusUpdateFailedRows.map(row => (
                        <li key={row.fieldId}>
                          <p>
                            FieldId: {row.fieldId}, ActualsId: {row.actualId},
                            UserId: {row.userId}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </CollapseBox.Content>
          </CollapseBox.Root>
        </div>
      ) : null}
    </div>
  );
};
