import { useNavigate } from "react-router-dom";

import { Button } from "@ag/design-system/atoms";
import { typography } from "@ag/design-system/tokens";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import { Page, withPageAccess } from "~features/permission";
import ListLayout from "~layouts/list-layout";

import * as pageStyles from "./styles.css";

const ImportVcu = () => {
  const navigate = useNavigate();

  const handleVcuTypeSelection = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const vcuType = event.currentTarget.textContent?.toLocaleLowerCase();

    navigate(`/certificates-management/import/${vcuType}`);
  };

  return (
    <>
      <ListLayout.Root>
        <ListLayout.TopBar>
          <BackButton />

          <ListLayout.TopBarTitle>Import and distribute</ListLayout.TopBarTitle>
        </ListLayout.TopBar>

        <ListLayout.Sidebar>
          <AuthorizedSidebar />
        </ListLayout.Sidebar>

        <ListLayout.Content>
          <div className={pageStyles.root}>
            <div className={pageStyles.importVcu}>
              <h2 className={typography.h2}>
                Select the type of VCUs to import
              </h2>

              <div className={pageStyles.vcuTypeBox}>
                <Button
                  variant="secondary"
                  className={pageStyles.vcuTypeButton}
                  onClick={handleVcuTypeSelection}
                >
                  Verra
                </Button>
                <Button
                  variant="secondary"
                  className={pageStyles.vcuTypeButton}
                  onClick={handleVcuTypeSelection}
                >
                  ISO
                </Button>
              </div>
            </div>
          </div>
        </ListLayout.Content>
      </ListLayout.Root>
    </>
  );
};

export default withPageAccess(Page.ImportVcu)(ImportVcu);
