import { atom, useAtom, useAtomValue } from "jotai";
import { ReactNode } from "react";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";

export const isModalOpenAtom = atom(false);
export const modalContentAtom = atom<ReactNode>(null);

export const BulkCQCFertilisersEditsModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(isModalOpenAtom);
  const modalContent = useAtomValue(modalContentAtom);

  return (
    <Modal.Root
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
    >
      <Modal.Header>
        <Modal.Title>Update completed</Modal.Title>
      </Modal.Header>

      <Modal.Content>{modalContent}</Modal.Content>

      <Modal.Footer render={onClose => <Button onClick={onClose}>OK</Button>} />
    </Modal.Root>
  );
};
