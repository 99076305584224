import { ExportToCsv } from "@agreena-aps/export-to-csv";
import { zodResolver } from "@hookform/resolvers/zod";
import { RowSelectionState, SortingState } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { Select } from "@ag/design-system/molecules";
import { Confirmation, Modal, parseSorting } from "@ag/design-system/organisms";
import { InputField, SelectField } from "@ag/form-fields";
import { useDialog, usePagination } from "@ag/utils/hooks";

import {
  Filters,
  transformInitialFilterValues,
  useSearchParamForm,
} from "~components/filters";
import Table from "~components/table";
import {
  FieldsActions,
  FieldsBulkActionType,
  mapFieldsToCSVData,
  useFieldsBulkActions,
  useFieldsQuery,
  useFieldsTable,
} from "~features/field";
import { UpdateFieldNamesModal } from "~features/field/components/update-field-names-modal/update-field-names-modal";
import {
  FieldFilters,
  FieldFiltersSchema,
} from "~features/field/entities/field";
import { useOpenHarvestYearsOptions } from "~features/initial-resources";
import { AuthorizedSidebar } from "~features/navigation";
import {
  CarbonResourceClass,
  useCarbonPermissions,
} from "~features/permission";
import { transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const Fields = () => {
  const [selection, setSelection] = useState<RowSelectionState>({});

  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const {
    selectedFields,
    bulkDeleteFields,
    bulkUnlockDefinitions,
    bulkUnlockActuals,
    bulkEditFieldnames,
    setSelectedFields,
    bulkExpireFields,
    bulkUnexpireFields,
  } = useFieldsBulkActions();

  const [query, setQuery] = useQueryParams({
    userId: withDefault(StringParam, undefined),
    id: withDefault(StringParam, undefined),
    fieldName: withDefault(StringParam, undefined),
    actualSubmitted: withDefault(StringParam, undefined),
    fallowActualsYears: withDefault(StringParam, undefined),
    activeContract: withDefault(StringParam, undefined),
    definitionSubmitted: withDefault(StringParam, undefined),
    expired: withDefault(StringParam, undefined),
  });

  const { data: carbonPermissions } = useCarbonPermissions();

  const { data: openHarvestYearsOptions } = useOpenHarvestYearsOptions();

  const { data: fieldsData, isLoading } = useFieldsQuery({
    ...pagination,
    filters: {
      ...query,
      userId: query.userId ? Number(query.userId) : undefined,
      id: query.id ? [query.id] : undefined,
      fallowActualsYears: query.fallowActualsYears
        ? [query.fallowActualsYears]
        : undefined,
      definitionSubmitted: query.definitionSubmitted
        ? query.definitionSubmitted === "true"
        : undefined,
      withoutActiveContract: query.activeContract
        ? query.activeContract === "false"
        : undefined,
      expired: query.expired ? query.expired === "true" : undefined,
    },
    sort: parseSorting(sorting),
  });

  const bulkActionAccess = useMemo(() => {
    const access: FieldsBulkActionType[] = [];

    // TODO: remove
    if (
      carbonPermissions?.unlock?.includes(
        CarbonResourceClass.CarbonFieldDefinition,
      )
    ) {
      access.push("unlock-definition");
    }

    if (
      carbonPermissions?.unlock?.includes(CarbonResourceClass.CarbonFieldActual)
    ) {
      access.push("unlock-actuals");
    }

    if (
      carbonPermissions?.deleteBulk?.includes(CarbonResourceClass.CarbonField)
    ) {
      access.push("delete");
    }

    if (
      carbonPermissions?.updateBulk?.includes(CarbonResourceClass.CarbonField)
    ) {
      access.push("bulk-edit-field-name");
      access.push("expire");
      access.push("unexpire");
    }

    return access;
  }, [carbonPermissions]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FieldFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(FieldFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<FieldFilters>(query, setQuery, resetPagination, () =>
    setIsFiltersOpen(false),
  );

  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  const table = useFieldsTable(fieldsData?.items, {
    selection,
    sorting,
    isSelectionEnabled: bulkActionAccess.length > 0,
    setSelection: selectionOrUpdater => {
      if (!fieldsData) return;

      const lookup =
        typeof selectionOrUpdater === "function"
          ? selectionOrUpdater(selection)
          : selectionOrUpdater;

      setSelectedFields(
        fieldsData?.items.filter(field => lookup[field.id] === true),
      );
    },
    setSorting,
  });

  useMemo(() => {
    const lookup: Record<string, boolean> = {};
    for (const field of selectedFields) {
      lookup[field.id] = true;
    }
    setSelection(lookup);
  }, [selectedFields]);

  const renderFilterBarItem = (
    key: keyof FieldFilters,
    value: FieldFilters[keyof FieldFilters],
  ) => {
    const fieldValues = {
      true: "Yes",
      false: "No",
    };

    const label = (
      {
        userId: "User ID",
        id: "Field ID",
        fieldName: "Field name",
        actualSubmitted: "Actuals submitted",
        fallowActualsYears: "Fallow actuals",
        activeContract: "Active contract",
        definitionSubmitted: "Field definition submitted",
        expired: "Is expired",
      } as unknown as Record<keyof FieldFilters, string>
    )[key];

    if (
      key === "activeContract" ||
      key === "definitionSubmitted" ||
      key === "expired"
    ) {
      return `${label}: ${fieldValues[value as "true" | "false"]}`;
    }

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const { show: showDeleteConfirmationDialog } = useDialog(({ hide }) => (
    <Modal.Root className="p-0" isOpen onRequestClose={hide}>
      <Modal.Content>
        <Confirmation.Root variant="danger">
          <Confirmation.Title>Delete fields</Confirmation.Title>

          <Confirmation.Description>
            Are you sure you want to delete the selected fields?
          </Confirmation.Description>

          <Confirmation.Actions>
            <Confirmation.Cancel onClick={hide}>Cancel</Confirmation.Cancel>

            <Confirmation.Confirm
              onClick={() =>
                bulkDeleteFields({
                  onSuccess: () => {
                    hide();
                  },
                })
              }
            >
              Delete
            </Confirmation.Confirm>
          </Confirmation.Actions>
        </Confirmation.Root>
      </Modal.Content>
    </Modal.Root>
  ));

  const { show: showBulkEditFieldNamesModal } = useDialog(({ hide }) => (
    <UpdateFieldNamesModal
      fields={selectedFields}
      isOpen
      onSaveNames={bulkEditFieldnames}
      onRequestClose={hide}
    />
  ));

  const handlePaginationChanged = (value: {
    limit?: number;
    page?: number;
  }) => {
    updatePagination(value);
    setSelection({});
  };

  const handleExportToCSV = () => {
    const dataForCSV = mapFieldsToCSVData(fieldsData?.items);

    const csvExporter = new ExportToCsv({
      showLabels: true,
      useKeysAsHeaders: true,
      filename: "carbon-fields-changes",
    });

    csvExporter.generateCsv(dataForCSV);
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Carbon - fields</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField
            {...register("userId")}
            label="User ID"
            error={errors.userId}
            type="number"
          />

          <InputField
            {...register("id")}
            label="Field ID"
            error={errors.id}
            type="number"
          />

          <InputField
            {...register("fieldName")}
            label="Field name"
            error={errors.fieldName}
          />

          <Controller
            name="actualSubmitted"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Actuals submitted"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {(openHarvestYearsOptions ?? []).map(year => (
                  <Select.Option
                    key={year?.value}
                    value={year?.value.toString()}
                  >
                    {year?.label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            name="fallowActualsYears"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Fallow actuals"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {(openHarvestYearsOptions ?? []).map(year => (
                  <Select.Option
                    key={year?.value.toString()}
                    value={year?.value.toString()}
                  >
                    {year?.label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            name="definitionSubmitted"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Field definition submitted"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>
                <Select.Option value="true">Yes</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </SelectField>
            )}
          />

          <Controller
            name="activeContract"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                value={field.value ?? ""}
                label="Active contract"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>
                <Select.Option value="true">Yes</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </SelectField>
            )}
          />

          <Controller
            name="expired"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                value={field.value ?? ""}
                label="Is expired"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>
                <Select.Option value="true">Yes</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </SelectField>
            )}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />

          <ListLayout.Actions>
            <FieldsActions
              bulkActionAccess={bulkActionAccess}
              hasSelection={Object.keys(selection).length > 0}
              onBulkDeleteFields={showDeleteConfirmationDialog}
              onBulkUnlockDefinitions={bulkUnlockDefinitions}
              onBulkUnlockActuals={bulkUnlockActuals}
              onDownloadCSV={handleExportToCSV}
              onBulkEditFieldNames={showBulkEditFieldNamesModal}
              onBulkExpireFields={bulkExpireFields}
              onBulkUnexpireFields={bulkUnexpireFields}
            />
          </ListLayout.Actions>
        </ListLayout.Header>

        <Table
          instance={table}
          meta={fieldsData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={handlePaginationChanged}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default Fields;
