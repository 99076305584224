import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

type UpdateCQAFieldDefinitionArgs = {
  fieldId: string;
  fieldDefinitionId: string;
  fieldDefinition: any;
};

export async function updateCQAFieldDefinition({
  fieldId,
  fieldDefinitionId,
  fieldDefinition,
}: UpdateCQAFieldDefinitionArgs) {
  await axios.put(
    `/api/farmer/carbon/v1/admin/carbon_fields/carbon_quality_assurances/carbon_fields/${fieldId}/carbon_field_definitions/${fieldDefinitionId}`,
    {
      carbonFieldDefinition: fieldDefinition,
    },
  );
}

export const useUpdateCQAFieldDefinitionMutation = () =>
  useMutation(updateCQAFieldDefinition, {
    onError: err => ToastNotification.error(err),
  });
