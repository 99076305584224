import { ReactNode } from "react";

import { typography } from "@ag/design-system/tokens";
import { cn } from "@ag/design-system/utils";

import * as styles from "./update-request-summary-card.css";

type Props = {
  title: string;
  children: ReactNode;
  className?: string;
};

export const UpdateRequestSummaryCard = ({
  title,
  className,
  children,
}: Props) => {
  return (
    <div className={cn(styles.card, className)} data-testid="summary-card">
      <h3 className={typography.h3}>{title}</h3>
      <div className={styles.cardContent}>{children}</div>
    </div>
  );
};
