import { CQAFieldDefinitionData } from "../entities/cqa-edits";
import { CQAFieldDefinitionUpdateData } from "../types/cqa-edits";

export function getCQAFieldDefinitionUpdateData(
  data: CQAFieldDefinitionData,
): CQAFieldDefinitionUpdateData {
  const {
    // Base
    soilDrainage,
    soilMgmtHistoricConversion,
    soilMoisture,
    soilPhRange,
    soilTexture,

    // Questionaire
    cultivatedFieldInTheLastFiveYears,
    rotatedCropsInTheLastFiveYears,
    nitrificationInhibitorsConsistentlyTheLastFiveYears,
    cropProtectionInAnyOfTheLastFiveYears,
    tillageConventionalInAnyOfTheLastFiveYears,
    tillageReducedInAnyTheLastFiveYears,
    tillageNoTillConsistentlyTheLastFiveYears,
    cropResidueBurnedInAnyOfTheLastFiveYears,
    cropResidueRemovedInAnyTheLastFiveYears,
    cropResidueMulchedConsistentlyTheLastFiveYears,
    noCoverCropGrownInTheLastFiveYears,
    coverCropWinterConsistentlyTheLastFiveYears,
    coverCropSpringConsistentlyTheLastFiveYears,
    catchCropConsistentlyTheLastFiveYears,
    fertilizersSyntheticNitrogenInAnyOfTheLastFiveYears,
    fertilizersMixedNitrogenInAnyOfTheLastFiveYears,
    fertilizersOrganicNitrogenConsistentlyTheLastFiveYears,

    // Comment
    comment,
  } = data;

  return {
    soilDrainage,
    soilMoisture,
    soilPhRange,
    soilTexture,
    soilMgmtHistoricConversion,
    questionnaire: {
      cultivatedFieldInTheLastFiveYears,
      rotatedCropsInTheLastFiveYears,
      nitrificationInhibitorsConsistentlyTheLastFiveYears,
      cropProtectionInAnyOfTheLastFiveYears,
      tillageConventionalInAnyOfTheLastFiveYears,
      tillageReducedInAnyTheLastFiveYears,
      tillageNoTillConsistentlyTheLastFiveYears,
      cropResidueBurnedInAnyOfTheLastFiveYears,
      cropResidueRemovedInAnyTheLastFiveYears,
      cropResidueMulchedConsistentlyTheLastFiveYears,
      noCoverCropGrownInTheLastFiveYears,
      coverCropWinterConsistentlyTheLastFiveYears,
      coverCropSpringConsistentlyTheLastFiveYears,
      catchCropConsistentlyTheLastFiveYears,
      fertilizersSyntheticNitrogenInAnyOfTheLastFiveYears,
      fertilizersMixedNitrogenInAnyOfTheLastFiveYears,
      fertilizersOrganicNitrogenConsistentlyTheLastFiveYears,
    },
    comment: {
      text: comment,
    },
  };
}
