import { ICONS_LIGHT, ICONS_REGULAR, ICONS_SOLID } from "./constants";

export const iconSizes = {
  small: 10,
  medium: 14,
};

export type IconConfig<
  TNameKey extends string = "name",
  TVariantKey extends string = "variant",
  TExtras extends object = object,
> =
  | ({
      [key in TNameKey]: keyof typeof ICONS_REGULAR;
    } & {
      [key in TVariantKey]?: "regular" | undefined;
    } & TExtras)
  | ({
      [key in TNameKey]: keyof typeof ICONS_SOLID;
    } & {
      [key in TVariantKey]: "solid";
    } & TExtras)
  | ({
      [key in TNameKey]: keyof typeof ICONS_LIGHT;
    } & {
      [key in TVariantKey]: "light";
    } & TExtras);

export const getIconConfig = <
  TNameKey extends string,
  TVariantKey extends string,
  TProps extends IconConfig<TNameKey, TVariantKey> = IconConfig<
    TNameKey,
    TVariantKey
  >,
>(
  props: TProps,
  nameKey: TNameKey,
  variantKey: TVariantKey,
): IconConfig => {
  return {
    name: props[nameKey],
    variant: props[variantKey],
  };
};
