import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { typography } from "@ag/design-system/tokens";

import { useUpdateRequestStore } from "~features/certificate";

import * as styles from "./submit-modal.css";

export const SuccessSubmitModal = () => {
  const { successModalAction, setSuccessModalAction } = useUpdateRequestStore();

  const handleOnClose = () => {
    setSuccessModalAction(null);
  };

  return (
    <Modal.Root
      onRequestClose={handleOnClose}
      className="bg-gray-700"
      size="small"
      isCentered
    >
      <Modal.Content>
        <div className={styles.modalContentWrapper}>
          <div>
            <Icon
              name="shield-check"
              variant="light"
              fontSize={56}
              className={styles.shieldCheckIconSuccess}
            />
            <Icon name="minus" fontSize={56} className={styles.minusIcon} />
            <Icon
              name="shield-check"
              variant="solid"
              fontSize={56}
              className={styles.shieldCheckIconDisabled}
            />
          </div>
          <div className={styles.modalContentMessage}>
            <span className={typography.h3}>Pre-submission successful</span>
            <p className={`${typography.p2} ${styles.paragraphWithMargin}`}>
              Your submission will now be reviewed by an Approver to complete
              the{" "}
              {successModalAction === "transfer" ? "transfer" : "retirement"}.
            </p>
          </div>
          <div>
            <Button variant="primary" onClick={handleOnClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
