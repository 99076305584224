import { useSetAtom } from "jotai";
import { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@ag/design-system/atoms";

import BackButton from "~components/BackButton";
import { useSessionContext } from "~features/auth";
import {
  flatfileAccessTokenAtom,
  useCreateFlatfileSpaceMutation,
  useSpacesStore,
} from "~features/bulk-upload";
import { useFertilisersQuery } from "~features/fertiliser";
import { AuthorizedSidebar } from "~features/navigation";
import DetailsLayout from "~layouts/details-layout";

const Bulk = () => {
  const navigate = useRef(useNavigate());

  const spacesStore = useSpacesStore();

  const setFlatfileAccessToken = useSetAtom(flatfileAccessTokenAtom);

  const { currentAdmin, isLoading: isCurrentUserLoading } = useSessionContext();

  const createCQCEditsFlatfileSpaceMutation = useCreateFlatfileSpaceMutation();

  const { data: fertilisers } = useFertilisersQuery({
    filters: {
      harvestYear: 2023,
      methodologyVersion: 2,
    },
  });

  const cqcEditsSpace = useMemo(() => {
    if (!currentAdmin) return null;

    const spaces = spacesStore.getUserSpaces({ adminId: currentAdmin.id });

    return spaces.findLast(space => space.type === "cqc-edits");
  }, [currentAdmin, spacesStore]);

  const cqcFertilisersEditsSpace = useMemo(() => {
    if (!currentAdmin) return null;

    const spaces = spacesStore.getUserSpaces({ adminId: currentAdmin.id });

    return spaces.findLast(space => space.type === "cqc-edits-fertilisers");
  }, [currentAdmin, spacesStore]);

  const createCQCEditsSpace = async () => {
    createCQCEditsFlatfileSpaceMutation.mutate(
      {
        label: "cqc-edits",
        name: currentAdmin?.id ?? "",
        namespace: "cqc-edits",
      },
      {
        onSuccess: data => {
          if (!currentAdmin) return;

          // Not sure if this would ever be empty
          setFlatfileAccessToken(data.accessToken ?? "");
          spacesStore.add({
            adminId: currentAdmin.id,
            spaceId: data.id,
            type: "cqc-edits",
          });

          navigate.current(`/carbon/cq-control/bulk/${data.id}`);
        },
      },
    );
  };

  const createCQCFertilisersEditsSpace = async () => {
    createCQCEditsFlatfileSpaceMutation.mutate(
      {
        label: "cqc-edits-fertilisers",
        name: currentAdmin?.id ?? "",
        namespace: "cqc-edits-fertilisers",
        metadata: {
          fertilisers: fertilisers?.data?.map(({ id, name }) => ({
            id,
            name,
          })),
        },
      },
      {
        onSuccess: data => {
          if (!currentAdmin) return;

          // Not sure if this would ever be empty
          setFlatfileAccessToken(data.accessToken ?? "");
          spacesStore.add({
            adminId: currentAdmin.id,
            spaceId: data.id,
            type: "cqc-edits-fertilisers",
          });

          navigate.current(`/carbon/cq-control/bulk/fertilisers/${data.id}`);
        },
      },
    );
  };

  return (
    <DetailsLayout.Root>
      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>Bulk update</DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.Content>
        <div className="grid gap-6 p-4">
          <section className="flex flex-col space-y-4">
            <h2>CQC Edits 2023</h2>

            <div className="flex flex-wrap space-x-4">
              {cqcEditsSpace ? (
                <Button to={`/carbon/bulk/cqc-edits/${cqcEditsSpace.id}`}>
                  Open existing space
                </Button>
              ) : null}

              <Button
                variant={cqcEditsSpace ? "secondary" : "primary"}
                isLoading={
                  isCurrentUserLoading ||
                  createCQCEditsFlatfileSpaceMutation.isLoading
                }
                onClick={createCQCEditsSpace}
              >
                Create new space
              </Button>
            </div>
          </section>

          <section className="flex flex-col space-y-4">
            <h2>CQC Edits Fertilisers 2023</h2>

            <div className="flex flex-wrap space-x-4">
              {cqcFertilisersEditsSpace ? (
                <Button
                  to={`/carbon/bulk/cqc-edits/fertilisers/${cqcFertilisersEditsSpace.id}`}
                >
                  Open existing space
                </Button>
              ) : null}

              <Button
                variant={cqcEditsSpace ? "secondary" : "primary"}
                isLoading={
                  isCurrentUserLoading ||
                  createCQCEditsFlatfileSpaceMutation.isLoading
                }
                onClick={createCQCFertilisersEditsSpace}
              >
                Create new space
              </Button>
            </div>
          </section>
        </div>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default Bulk;
