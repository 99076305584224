import { useState } from "react";

import { Button } from "@ag/design-system/atoms";

import {
  AddNewFertiliserModal,
  useFertilisersQuery,
} from "~features/fertilisers";
import { AuthorizedSidebar } from "~features/navigation";
import { withFeatureFlagAccess } from "~hocs/withFeatureFlagAccess";
import ListLayout from "~layouts/list-layout";

const Fertilisers = () => {
  const [newFertiliserModalOpen, setNewFertiliserModalOpen] = useState(false);
  const { data } = useFertilisersQuery();

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Fertilisers</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        {/* TODO => Filters */}
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header variant="slim">
          <ListLayout.Actions>
            <div />
            <Button onClick={() => setNewFertiliserModalOpen(true)}>
              Add new fertiliser
            </Button>
          </ListLayout.Actions>
        </ListLayout.Header>

        <AddNewFertiliserModal
          isOpen={newFertiliserModalOpen}
          onClose={() => setNewFertiliserModalOpen(false)}
        />

        {/* TODO: Fertilisers Table with HY tabs */}
        {data?.map(fertiliser => {
          const formattedName = fertiliser.name
            .replace(/\s+/g, "-")
            .toLowerCase();
          return (
            <div key={fertiliser.id}>
              {fertiliser.name}
              <a href={`fertilisers/${fertiliser.id}?name=${formattedName}`}>
                {fertiliser.id}
              </a>
            </div>
          );
        })}
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default withFeatureFlagAccess("fertilisers-v2", {
  redirectPath: "/carbon/fertilisers",
})(Fertilisers);
