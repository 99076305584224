import { FieldRenderProps } from "react-final-form";

import { Checkbox, CheckboxCommonProps } from "~Checkbox";

type Props = FieldRenderProps<boolean> & CheckboxCommonProps;

export const FormCheckbox = ({ input, meta, ...rest }: Props) => (
  <Checkbox
    checked={input.checked}
    error={meta.touched ? meta.error : null}
    onChange={input.onChange}
    {...rest}
  />
);
