import { z } from "zod";

const UpdateRequestActionSchema = z.enum(["transfer", "retirement"]);
export type UpdateRequestAction = z.infer<typeof UpdateRequestActionSchema>;

const UpdateRequestStatusSchema = z.enum([
  "draft",
  "pending",
  "approved",
  "cancelled",
]);
export type UpdateRequestStatus = z.infer<typeof UpdateRequestStatusSchema>;

export const UpdateRequestSchema = z.object({
  action: UpdateRequestActionSchema,
  approverAdminId: z.string().nullable(),
  createdAt: z.string().datetime(),
  id: z.string(),
  certificateBeneficiaryId: z.string().nullable(),
  certificateBeneficiary: z
    .object({
      id: z.string(),
      name: z.string(),
      createdAt: z.string().datetime(),
      updatedAt: z.string().datetime(),
    })
    .nullable()
    .optional(),
  requesterAdminId: z.string(),
  status: UpdateRequestStatusSchema,
  updatedAt: z.string().datetime(),
  ranges: z.array(z.string()),
});

export type UpdateRequest = z.infer<typeof UpdateRequestSchema>;
