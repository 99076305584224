import { FieldRenderProps } from "react-final-form";

import { Select, SelectCommonProps } from "~selects/Select";

type Props = FieldRenderProps<string | number | undefined> & SelectCommonProps;

export const FormSelect = ({ input, meta, ...rest }: Props) => (
  <Select
    value={input.value}
    error={meta.touched ? meta.error : null}
    onChange={input.onChange}
    {...rest}
  />
);
