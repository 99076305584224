import api from "@flatfile/api";
import {
  CellValueUnion,
  RecordWithLinks,
  ValidationMessage,
} from "@flatfile/api/api";
import axios from "axios";
import { SetStateAction } from "jotai";
import { chunk, groupBy } from "lodash";
import { ReactNode } from "react";

import { FieldActualsInputType } from "@ag/carbon/types";

import { Field, getField } from "~features/field";
import {
  UpdateFieldActualsData,
  updateFieldActuals,
} from "~features/field/api/update-field-actuals";
import {
  QualityControlStatus,
  runFieldsQualityControl,
} from "~features/quality-control";
import { changeFieldsStatus } from "~features/quality-control/api/change-fields-status";

import {
  Row,
  getCompleteReportModalContent,
} from "../components/bulk-cqc-fertilisers-edits-modal/helpers";
import { CQCFertilisersEditsData } from "../entities/cqc-fertilisers-edits";

export type BulkUpdatCQCFertilisersEditsParams = {
  context: {
    jobId: string;
    sheetId: string;
    records: RecordWithLinks[];
  };
  options: {
    harvestYear: number;
    setIsModalOpen: (value: SetStateAction<boolean>) => void;
    setModalContent: (value: SetStateAction<ReactNode>) => void;
  };
};

export async function bulkUpdatCQCFertilisersEdits({
  context,
  options,
}: BulkUpdatCQCFertilisersEditsParams) {
  const { jobId, sheetId, records } = context;
  const { harvestYear, setIsModalOpen, setModalContent } = options;
  /**
   * Keep track of status for info messages during progress,
   * and to add messages at completion/failure.
   */
  const updatedRows: Row<CQCFertilisersEditsData>[] = [];

  // Array of rows with errors grouped by type
  const qcApprovedRows: Row<CQCFertilisersEditsData>[] = [];
  const nonExstingFieldsRows: Row<CQCFertilisersEditsData>[] = [];
  const nonExstingFielActualsRows: Row<CQCFertilisersEditsData>[] = [];
  const actualsNotBelongsToFieldRows: Row<CQCFertilisersEditsData>[] = [];
  const dataUpdateFailedRows: Row<CQCFertilisersEditsData>[] = [];
  const fieldFallowRows: Row<CQCFertilisersEditsData>[] = [];
  const qcStatusUpdateFailedRows: Row<CQCFertilisersEditsData>[] = [];

  try {
    const cqcFertilisersEditsRows: Row<CQCFertilisersEditsData>[] = records.map(
      record => {
        const entity: Record<string, CellValueUnion | undefined> = {};
        for (const [key, value] of Object.entries(record.values)) {
          entity[key] = value.value;
        }
        return {
          ...(entity as CQCFertilisersEditsData),
          record,
        };
      },
    );

    // Fetch all fields
    const fieldIds = [
      ...new Set(cqcFertilisersEditsRows.map(row => row.fieldId)),
    ];

    const allFields = await Promise.all(
      fieldIds.map(async fieldId => {
        try {
          return await getField(fieldId, {
            actualHarvestYear: harvestYear,
          });
        } catch (err) {
          return null;
        }
      }),
    );

    const allFieldsById = groupBy(allFields, field => field?.id);

    await api.jobs.ack(jobId, {
      info: `Fetched ${cqcFertilisersEditsRows.length} fields`,
      progress: 10,
    });

    const updateRequests: {
      row: Row<CQCFertilisersEditsData>;
      field: Field;
    }[] = [];

    // Group rows by actualsId, to reflect fertilisers nested structure
    const rowsGroupedByActualsId = groupBy(
      cqcFertilisersEditsRows,
      row => row.actualId,
    );

    for (const [_key, rows] of Object.entries(rowsGroupedByActualsId)) {
      // Check only first row, as all rows in the group have the same actualId
      const [cqcFertilisersEditsRow] = rows;
      const { fieldId, actualId } = cqcFertilisersEditsRow;
      const [field] = allFieldsById[fieldId] ?? [];

      /**
       * Check if fieldId exist and belongs to a harvest year and warn user at completion.
       */
      if (!field) {
        nonExstingFieldsRows.push(cqcFertilisersEditsRow);

        delete rowsGroupedByActualsId[actualId];
        continue;
      }

      const [fieldActuals] = field.carbonFieldActual;

      /**
       * Check if actualsId exist and warn user at completion.
       */
      if (!fieldActuals) {
        nonExstingFielActualsRows.push(cqcFertilisersEditsRow);

        delete rowsGroupedByActualsId[actualId];
        continue;
      }

      // 1. Check if actuals belongs to the field and warn user at completion.
      // TODO: Fix id type in the schema as currently it's a number but should be a string
      if (String(field.carbonFieldActual[0].id) !== String(actualId)) {
        actualsNotBelongsToFieldRows.push(cqcFertilisersEditsRow);

        delete rowsGroupedByActualsId[actualId];
        continue;
      }

      // 2. Check if actuals is already fallow and warn user at completion.
      if (field.carbonFieldActual[0].fallow) {
        fieldFallowRows.push(cqcFertilisersEditsRow);

        delete rowsGroupedByActualsId[actualId];
        continue;
      }

      // 3. Check if actual "qc status" is equal to qc_approved and warn user at completion.
      if (
        field.carbonFieldActual[0].qcStatus === QualityControlStatus.Approved
      ) {
        qcApprovedRows.push(cqcFertilisersEditsRow);

        delete rowsGroupedByActualsId[actualId];
        continue;
      }

      updateRequests.push({
        row: cqcFertilisersEditsRow,
        field,
      });
    }

    if (updateRequests.length) {
      // Max limit for API is 100 fields per batch when updating the fieldsStatus
      const updateStatusBatches = chunk(updateRequests, 100);

      // 4. Change status of the field to non_conformance before update
      await updateStatusBatches.forEach(async updateBatch => {
        await changeFieldsStatus({
          fieldIds: updateBatch.map(({ row }) => String(row.fieldId)),
          payload: {
            newStatus: QualityControlStatus.NonConformance,
          },
        });
      });
    }

    const updateBatches = chunk(updateRequests, 20);

    // 5. Update data
    for (const updateBatch of updateBatches) {
      const percentageDone =
        updateBatches.indexOf(updateBatch) / updateBatches.length;

      await Promise.all(
        updateBatch.map(async ({ row, field }) => {
          try {
            const comment = row.comment;

            const getFertiliserData = (row: Row<CQCFertilisersEditsData>) => {
              // Organic
              if (row.fertiliserType === "organic") {
                return {
                  mode: row.fertiliserType,
                  carbonFertiliserId: row.organicCarbonFertiliser,
                  applicationRate: row.organicApplicationRate,
                  nitrificationApplicationRate:
                    row.organicNitrificationApplicationRate,
                  emissionsInhibitors: row.organicEmissionsInhibitors,
                };
              }

              // Synthetic
              return {
                mode: row.fertiliserType,
                nitrogenApplicationRate: row.syntheticNitrogenApplicationRate,
                phosphorusApplicationRate:
                  row.syntheticPhosphorusApplicationRate,
                phosphorusType: row.syntheticPhosphorusType,
                potassiumApplicationRate: row.syntheticPotassiumApplicationRate,
                potassiumType: row.syntheticPotassiumType,
                emissionsInhibitors: row.syntheticEmissionsInhibitors,
                nitrificationApplicationRate:
                  row.syntheticNitrificationApplicationRate,
              };
            };

            // Any as old type are no longer suppoerted by app
            const fertilisers: any = rowsGroupedByActualsId[row.actualId].map(
              row => getFertiliserData(row),
            );

            const carbonFieldActualData: UpdateFieldActualsData = {
              carbonFieldActualInputType: FieldActualsInputType.V2022,
              carbonFieldActualInput: {
                fertilisers,
                fertilisersCount: fertilisers.length,
              },
              comment: {
                text: comment,
              },
            };

            await updateFieldActuals({
              fieldId: field.id,
              fieldActualsId: field.carbonFieldActual[0].id,
              carbonFieldActualData,
            });

            // 6. Run assurance for updated fields
            await runFieldsQualityControl({
              year: harvestYear,
              fieldIds: [String(field.id)],
            });

            try {
              // 7. Change status of the field to the new status when update is successful
              const response = await changeFieldsStatus({
                fieldIds: [String(field.id)],
                payload: {
                  newStatus: row.cqcStatus,
                },
              });

              // API retuers an error in the success part of the response so we need to check for it
              if (response[0].error) {
                throw new Error(response[0].error.message);
              }

              updatedRows.push(row);
            } catch (err) {
              qcStatusUpdateFailedRows.push(row);

              throw err;
            }
          } catch (err) {
            dataUpdateFailedRows.push(row);

            try {
              // 8. If the errors occur, use the backup status of processing
              await changeFieldsStatus({
                fieldIds: [String(field.id)],
                payload: {
                  newStatus: QualityControlStatus.Processing,
                },
              });
            } catch (err) {
              qcStatusUpdateFailedRows.push(row);
            }
          }
        }),
      );

      await api.jobs.ack(jobId, {
        info: `Updated ${updatedRows.length} fields`,
        // Progress from 30 -> 80
        progress: 30 + Math.floor(percentageDone * 50),
      });
    }

    // Show error on the record level
    if (nonExstingFieldsRows.length) {
      await api.records.update(
        sheetId,
        nonExstingFieldsRows.map(row => {
          const message: ValidationMessage = {
            type: "error",
            message: `FieldId does not exist in the harvestYear ${harvestYear}`,
          };

          return addRecordMessage("fieldId", row.record, message);
        }),
      );
    }

    // Show error on the record level
    if (nonExstingFielActualsRows.length) {
      await api.records.update(
        sheetId,
        nonExstingFielActualsRows.map(row => {
          const message: ValidationMessage = {
            type: "error",
            message: "Field actuals does not exist for the given field",
          };

          return addRecordMessage("actualId", row.record, message);
        }),
      );
    }

    // Show error on the record level
    if (actualsNotBelongsToFieldRows.length) {
      await api.records.update(
        sheetId,
        actualsNotBelongsToFieldRows.map(row => {
          const message: ValidationMessage = {
            type: "error",
            message: "ActualsId does not belong to the fieldId",
          };

          return addRecordMessage("actualId", row.record, message);
        }),
      );
    }

    // Show error on the record level
    if (qcApprovedRows.length) {
      await api.records.update(
        sheetId,
        qcApprovedRows.map(row => {
          const message: ValidationMessage = {
            type: "error",
            message:
              "CQC status is approved for this field, so the data can't be edited",
          };

          return addRecordMessage("fieldId", row.record, message);
        }),
      );
    }

    // Show complete report modal with success/error messages
    const completeRepormModalContent = getCompleteReportModalContent({
      harvestYear,
      updatedRows,
      qcApprovedRows,
      dataUpdateFailedRows,
      nonExstingFieldsRows,
      nonExstingFielActualsRows,
      actualsNotBelongsToFieldRows,
      qcStatusUpdateFailedRows,
      fieldFallowRows,
    });

    setIsModalOpen(true);
    setModalContent(completeRepormModalContent);

    await api.jobs.complete(jobId);
  } catch (err) {
    await api.jobs.fail(jobId, {
      outcome: {
        acknowledge: true,
        heading: "Update failed :(",
        message: getErrorMessage(err),
      },
    });
  }
}

function getErrorMessage(err: unknown) {
  const message = axios.isAxiosError(err)
    ? err.response?.data?.errors ?? err?.message
    : (err as Error).message ?? err?.toString() ?? err;

  if (Array.isArray(message)) {
    return message.join(", ");
  }

  if (typeof message === "string") return message;

  return JSON.stringify(message);
}

function addRecordMessage(
  key: string,
  record: RecordWithLinks,
  message: ValidationMessage,
): RecordWithLinks {
  return {
    ...record,
    values: {
      ...record.values,
      [key]: {
        ...record.values[key],
        messages: [message],
      },
    },
  };
}
