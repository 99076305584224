import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";

import { CertificatesValidation } from "../entities/certificates-validation";
import { UpdateRequestAction } from "../entities/update-request";

type UpdateRequestStoreData = {
  validationResult: CertificatesValidation | null;
  beneficiary: {
    id: string;
    name: string;
  } | null;
  successModalAction: UpdateRequestAction | null;
  isRejectModalVisible: boolean;

  setValidationResult: (result: CertificatesValidation | null) => void;
  setBeneficiary: (beneficiary: { id: string; name: string } | null) => void;
  setSuccessModalAction: (action: UpdateRequestAction | null) => void;
  setIsRejectModalVisible: (isShow: boolean) => void;
};

export const useUpdateRequestStore = create<UpdateRequestStoreData>(set => ({
  validationResult: null,
  beneficiary: null,
  successModalAction: null,
  isRejectModalVisible: false,

  setValidationResult: result => {
    set(() => ({ validationResult: result }));
  },
  setBeneficiary: beneficiary => {
    set(() => ({ beneficiary }));
  },
  setSuccessModalAction: action => {
    set(() => ({ successModalAction: action }));
  },
  setIsRejectModalVisible: isVisible => {
    set(() => ({ isRejectModalVisible: isVisible }));
  },
}));

export const useCertificateIds = () =>
  useUpdateRequestStore(
    useShallow(
      state => state.validationResult?.certificateIds.map(range => range.query),
    ),
  );
