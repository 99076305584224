import { FlatfileClient } from "@flatfile/api";

import { env } from "~config";

export function createFlatfileClient(accessToken?: string) {
  const publishableKey = env.REACT_APP_FLATFILE_PUBLISHABLE_KEY;

  if (!publishableKey)
    throw new Error(`"REACT_APP_FLATFILE_PUBLISHABLE_KEY" not set`);

  return new FlatfileClient({
    token: accessToken ?? publishableKey,
    apiUrl: "https://platform.flatfile.com/api",
    environment: "https://platform.flatfile.com/api/v1",
  });
}

export function getEnviromentId() {
  const environmentId = env.REACT_APP_FLATFILE_ENVIRONMENT_ID;

  if (!environmentId)
    throw new Error(`"REACT_APP_FLATFILE_ENVIRONMENT_ID" not set`);

  return environmentId;
}
