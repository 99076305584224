import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";

type ImportErrorsModalProps = {
  isOpen: boolean;
  errors: string[];
  onClose: () => void;
  onDiscardImport: () => void;
};

const ImportErrorsModal = ({
  isOpen,
  errors,
  onClose,
  onDiscardImport,
}: ImportErrorsModalProps) => {
  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onClose} size="medium">
      <Modal.Header key="header">
        <Modal.Title>Inventory import errors</Modal.Title>
        <Modal.SubTitle>
          Below are the errors that occurred during the import process.
        </Modal.SubTitle>
      </Modal.Header>
      <Modal.Content key="content">
        <ul>
          {errors.map(error => (
            <li key={error} className="py-2 text-red-800">
              <Icon name="triangle-exclamation" className="px-2 text-red-600" />
              <span>{error}</span>
            </li>
          ))}
        </ul>
      </Modal.Content>
      <Modal.Footer
        key="footer"
        render={() => (
          <Button onClick={onDiscardImport}>
            Discard and start new import
          </Button>
        )}
      />
    </Modal.Root>
  );
};

export default ImportErrorsModal;
