import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { colorsObject } from "@ag/design-system/tokens";

import { BoundaryStatus } from "../entities/field-with-all-boundaries";

export const FarmerFollowUpInfoCard = ({
  boundaryStatus,
  onFollowUpResolve,
}: {
  boundaryStatus: BoundaryStatus;
  onFollowUpResolve: () => void;
}) => {
  return (
    <div
      className="
        flex flex-row items-center gap-3 rounded-lg bg-data-purple-100 p-[10px]"
    >
      <div className="flex flex-col items-start gap-2">
        <div className="flex flex-row items-center gap-2">
          <div className="flex items-center rounded-full bg-data-purple-300 p-2">
            <Icon
              name="flag"
              color={colorsObject.data.purple[900]}
              fontSize={11}
            />
          </div>
          <p className=" text-h5 text-grey-900">Follow up</p>
        </div>

        <p className="border-l-2 border-data-purple-300 pl-[10px] text-p2 italic text-grey-800">
          {boundaryStatus.metadata.comment}
        </p>
        <Button
          icon="check"
          variant="text"
          size="small"
          onClick={onFollowUpResolve}
        >
          Resolve
        </Button>
      </div>
    </div>
  );
};
