import { withTypes } from "react-final-form";

import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import { AuthorizedSidebar } from "~features/navigation";
import DetailsLayout from "~layouts/details-layout";
import { useApplicationSettingQueryByName } from "~queries/application-settings";
import { useUpdateTranslationsMutation } from "~queries/github";

import Form from "./Form";
import { validate } from "./helpers";
import { AddTranslationsFormData } from "./types";

const { Form: FinalForm } = withTypes<AddTranslationsFormData>();

const Translations = () => {
  const { data: translationsGithubToken } = useApplicationSettingQueryByName(
    "translations_gh_token",
  );
  const updateTranslationsMutation = useUpdateTranslationsMutation();

  const handleSubmit = (values: AddTranslationsFormData) => {
    const locales = Object.keys(values).filter(key => Boolean(values[key]));

    if (translationsGithubToken) {
      updateTranslationsMutation.mutate(
        {
          locales: Object.values(locales),
          translationsGithubToken,
        },
        {
          onSuccess: () => {
            ToastNotification.success(
              I18n.t("js.settings.translations.update_success_notification"),
            );
          },
        },
      );
    }
  };

  return (
    <DetailsLayout.Root>
      <DetailsLayout.TopBar key="topbar">
        <DetailsLayout.TopBarTitle>
          {I18n.t("js.settings.translations.title")}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Sidebar key="sidebar">
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.Content key="content">
        <FinalForm
          validate={validate}
          render={({ handleSubmit }) => (
            <Form
              isSubmitting={updateTranslationsMutation.isLoading}
              onSubmit={handleSubmit}
            />
          )}
          onSubmit={handleSubmit}
        />
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default Translations;
