import { FieldNamesMarkedBoolean } from "react-hook-form";

import { FormValues } from "../components/user-edit/user-edit-company-form";

/**
 *
 * this function is used to get the list of updated
 * signatory fields by user
 */
export const getUpdatedSignatoryFields = (
  formDirtyField: Partial<Readonly<FieldNamesMarkedBoolean<FormValues>>>,
) => {
  const updatedSignatoryFieldsList = [];

  if (formDirtyField.contractSignerCountryCode) {
    updatedSignatoryFieldsList.push("countryCode");
  }

  if (formDirtyField.contractSignerEmail) {
    updatedSignatoryFieldsList.push("email");
  }

  if (formDirtyField.contractSignerFirstName) {
    updatedSignatoryFieldsList.push("firstName");
  }

  if (formDirtyField.contractSignerLastName) {
    updatedSignatoryFieldsList.push("lastName");
  }

  if (formDirtyField.contractSignerPhoneNumber) {
    updatedSignatoryFieldsList.push("phoneNumber");
  }

  return updatedSignatoryFieldsList.join(", ");
};
