import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import capitalize from "lodash/capitalize";
import { useMemo, useState } from "react";
import { Collapse } from "react-collapse";
import { Field } from "react-final-form";

import { SFontAwesomeIcon } from "@ag/components/Icon";
import { ActionButton } from "@ag/components/buttons";
import {
  FormButtonsGroup,
  FormInput,
  FormMultiSelect,
  FormSelect,
} from "@ag/components/form";
import { SFlexDiv, SRow } from "@ag/components/styled";
import { Spinner } from "@ag/design-system/assets";
import { grid, stack } from "@ag/design-system/utils";
import I18n, { Locale, getLocales } from "@ag/i18n";
import {
  getYesNoOptions,
  parseInputNumber,
  parseStringInput,
} from "@ag/utils/helpers";

import Card from "~components/card";
import {
  METHODOLOGY_VERSIONS_OPTIONS,
  useOpenHarvestYearsOptions,
} from "~features/initial-resources";
import { getCropTypeCategoryOptions } from "~pages/_legacy/carbon/pages/CropTypes/helpers";
import { SGridContainer } from "~pages/_legacy/carbon/pages/CropTypes/styled";

import { CropTypeEditAttribute, CropTypeEditOptionalAttribute } from "../types";

const Form = () => {
  const [isTranslationsVisible, setIsTranslationsVisible] = useState(false);

  const {
    data: openHarvestYearsOptions,
    isLoading: isOpenHarvestYearsOptionsLoading,
  } = useOpenHarvestYearsOptions();

  const availableLocales: string[] = useMemo(
    () => getLocales().filter(locale => locale !== Locale.En),
    [],
  );

  const handleTranslationsToggleClicked = () => {
    setIsTranslationsVisible(!isTranslationsVisible);
  };

  if (isOpenHarvestYearsOptionsLoading) {
    return (
      <SFlexDiv justifyContent="center">
        <Spinner />
      </SFlexDiv>
    );
  }

  const cropTypeCategoryOptions = getCropTypeCategoryOptions();

  return (
    <Card.Root>
      <div className={stack()}>
        <div className={grid()}>
          <Field
            key={Locale.En}
            name={`name${capitalize(Locale.En)}`}
            label={`${I18n.t("js.carbon.crop_type_attribute.name")} (${
              Locale.En
            })`}
            component={FormInput}
            disabled
            required
          />

          <Field
            name={CropTypeEditAttribute.Identifier}
            label={I18n.t("js.carbon.crop_type_attribute.identifier")}
            component={FormInput}
            disabled
          />

          <Field
            name={CropTypeEditAttribute.ID}
            label={I18n.t("js.carbon.crop_type_attribute.id")}
            component={FormInput}
            type="number"
            parse={parseInputNumber}
            disabled
          />
        </div>

        <div className={grid()}>
          <Field
            name={CropTypeEditAttribute.CoolfarmIdentifier}
            label={I18n.t("js.carbon.crop_type_attribute.coolfarm_identifier")}
            type="number"
            parse={parseInputNumber}
            component={FormInput}
            disabled
            required
          />

          <Field
            name={CropTypeEditAttribute.CoolfarmName}
            label={I18n.t("js.carbon.crop_type_attribute.coolfarm_name")}
            component={FormInput}
            disabled
            required
          />
        </div>

        <div className={grid()}>
          <Field
            name={CropTypeEditAttribute.MethodologyVersion}
            label={I18n.t("js.shared.methodology_version")}
            component={FormSelect}
            options={METHODOLOGY_VERSIONS_OPTIONS}
            isDisabled
          />

          <Field
            name={CropTypeEditAttribute.HarvestYear}
            label={I18n.t("js.carbon.crop_type_attribute.harvest_year")}
            component={FormSelect}
            type="number"
            parse={parseInputNumber}
            options={openHarvestYearsOptions}
            isDisabled
          />
        </div>

        <div className={grid()}>
          <Field
            name={CropTypeEditAttribute.NetGross}
            label={I18n.t("js.carbon.crop_type_attribute.net_gross")}
            component={FormInput}
            type="number"
            parse={parseInputNumber}
            disabled
            required
          />

          <Field
            name={CropTypeEditOptionalAttribute.GrossYieldMin}
            label={I18n.t(
              "js.admin.carbon.crop_type_attribute.min_gross_yield",
            )}
            type="number"
            parse={parseInputNumber}
            component={FormInput}
            disabled
          />

          <Field
            name={CropTypeEditAttribute.GrossYieldMax}
            label={I18n.t(
              "js.admin.carbon.crop_type_attribute.max_gross_yield",
            )}
            type="number"
            parse={parseInputNumber}
            component={FormInput}
            disabled
            required
          />
        </div>

        <div className={grid()}>
          <Field
            name={CropTypeEditAttribute.KgNHaMin}
            type="number"
            label={I18n.t(
              "js.admin.carbon.crop_type_attribute.min_fertilizer_consumption",
            )}
            component={FormInput}
            parse={parseInputNumber}
            disabled
            required
          />

          <Field
            name={CropTypeEditAttribute.KgNHaMax}
            label={I18n.t(
              "js.admin.carbon.crop_type_attribute.max_fertilizer_consumption",
            )}
            type="number"
            parse={parseInputNumber}
            component={FormInput}
            disabled
            required
          />
        </div>

        <div className={grid()}>
          <Field
            name={CropTypeEditAttribute.DryMatterFraction}
            label={I18n.t(
              "js.admin.carbon.crop_type_attribute.dry_matter_fraction",
            )}
            type="number"
            parse={parseInputNumber}
            component={FormInput}
            disabled
            required
          />

          <Field
            name={CropTypeEditAttribute.ShowResidue}
            label={I18n.t("js.carbon.crop_type_attribute.show_residue")}
            component={FormButtonsGroup}
            buttons={getYesNoOptions()}
            disabled
          />
        </div>

        <div className={grid()}>
          <Field
            name={CropTypeEditAttribute.Slope}
            label={I18n.t("js.admin.carbon.crop_type_attribute.slope")}
            type="number"
            parse={parseInputNumber}
            component={FormInput}
            disabled
            required
          />

          <Field
            name={CropTypeEditAttribute.Intercept}
            label={I18n.t("js.admin.carbon.crop_type_attribute.intercept")}
            type="number"
            parse={parseInputNumber}
            component={FormInput}
            disabled
            required
          />

          <Field
            name={CropTypeEditAttribute.Categories}
            label={I18n.t("js.admin.carbon.crop_type_attribute.category")}
            options={cropTypeCategoryOptions}
            component={FormMultiSelect}
            isDisabled
          />
        </div>

        <SFlexDiv>
          <ActionButton
            type="text"
            className="m-0 text-accent-200"
            onClick={handleTranslationsToggleClicked}
          >
            {isTranslationsVisible
              ? I18n.t("js.shared.hide_translations")
              : I18n.t("js.shared.show_translations")}

            <SFontAwesomeIcon
              marginLeft={8}
              icon={isTranslationsVisible ? faChevronUp : faChevronDown}
            />
          </ActionButton>
        </SFlexDiv>

        <Collapse isOpened={isTranslationsVisible}>
          <SFlexDiv marginTop={16} direction="column">
            <SRow style={{ marginBottom: 16 }}>
              <SGridContainer>
                {availableLocales.map(locale => (
                  <Field
                    key={locale}
                    name={`name${capitalize(locale)}`}
                    label={`${I18n.t(
                      "js.carbon.crop_type_attribute.name",
                    )} (${locale})`}
                    component={FormInput}
                    parse={parseStringInput}
                    disabled
                  />
                ))}
              </SGridContainer>
            </SRow>
          </SFlexDiv>
        </Collapse>
      </div>
    </Card.Root>
  );
};

export default Form;
