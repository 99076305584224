import "src/tokens/colors.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/colors.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4WYy27jOBBF9/0VAWZtgO9H94qP4n940p5MELcd2A6CxmD+fSBVMTFFMmMtsjhXFOvqkizl++V8vj388+3hYbd7PB/Pl93T5XA47Thj3x/+SAFUCj86KlbqbbI+9VSu1OhgHe+pWqkUTprBvXqljOmkfU9NpUoPRrZEVVCup46oLBJ66iuVUtzT8+v+SGZkgAKxg+hFSmCAdxCtiCnnbDuIToSYZcodRCN8SCy6DqIPzkcZQgfRBmt99n0pjt6Ns7YvBU3Q0nKj7uFtXwNRfJGtBwtDC4oE21a5MHQAUo6pGxMNAJ1KFFuG9eecZGBbhuUnEbzr5oLVB+uZNVuGxbtkremeh7VbrkE18fjz+Hag4iPkALmDWH1QSwQ6iOW7GCGXDmL9VkbW+rZCNECHIFJ/JzqgjPOxvxMtkMoy388WPRBau3ZxrhBN4E6W1qDzZX96qjaUAizpAaYYlCyCH2BJOGmXB1gRjt6qAdaEA+g4wGgICFfa10cYLUnGcsUHGE0JXhs5KsxTbiSIZrn9PhyP5/cPW0rIcoCrLUUFO8DVFsjtiiRcbQGjR8+utuQg1ACjLTlGy0d3oy3Res3FAKMtXlrNRlOjJRM0sCb4l8PPD09yArZl1ZDgA2xZdcNtNq+FVSussnbLqg86tvFYWDVBufbUWFgNhuK6G7OmQmott6xGQgTVnGLvfz/fPhfK8msX2f7x5eOsWX739Lq/HY7Hz/u5YEmbsQI9ZMBkG+hPBTrJPBOiceT68pvGBwmhsC2j84xnWfiW0W6mfIFmnewfHw+nGw1rsrZt2YRxZGUly3GAcXDJuIrb47vOuIjl6qCgc2S5OijpfF5+HcRIRbFcHcRMWbVcHaQdOC9XB2kHXn8dxFjxsFwdpGzk5ergGq5hsFa8pmuYrJ/72373+nZ5PdZsQYbYDnGvQUNzyGauIV91hLmGOpwcxFxDjY5wfK6hfkdaCWqmobaHG5XMTIPW66RF4DMNvgHFZbCi02wOwqLLVFN3OQiDOW+OxDya8/ZcjIM5bw5Hn+dzNnX39INsNMckKJPmGkc7poa5Bj0MXOWB5v4DA2wpZSahFKpSQE4k1F+HUvJMgv75WELMEwktdA28/Zy5k6B7widuw0SC5vHii5nNhZa+tptN405SNwDDZD+X1+fTy2cXFsogxIuCoheyHRi3Kih4OkCyYwXFjjkd4liBrkHUyQ0W7qKggzdLqSe10PHruVaTWhzt0IyL3rJVQUexYkz0Y9y17yBLGGwxdz18YkXOFLWRB1dgrKBunmc3SPRdS69CMoP3ctfXSx5lntRCH7nF++jHivqha6yd1FIbEAWmecqvw/W6f3o+Pe0Ol8v5UpMGIEB8pavbWEghfaWjbcpZbr98LrWlnif+5Xj05mF73H3q3veX0/L3Y8P28D/Kz3a8/XjqlVRNjKVtvXsl1pO9tW2j3CuxImNEYn6sfD79da7/kBDZl69kWItNQcOkllVGX5rZaXBfyKipAaPa9G5kFK4gRZ4Ue317fDxcr3VZ8sJn8apKLCQYEO0XQ6+kWlwKbhKxqqRynM96ErKqpK9i7YzMP779+x8rgFpCMxMAAA==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/elevation.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/elevation.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tXzFnbW1heDYwOiAwIDRweCA4cHggcmdiYSgwLDAsMCwwLjA1KTsKICAtLV8xZ21tYXg2MTogMCA4cHggOHB4IHJnYmEoMCwwLDAsMC4xKTsKfQ==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/radius.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/radius.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tcmFkaXVzLTEwMDogNHB4OwogIC0tcmFkaXVzLTIwMDogOHB4Owp9\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/spacing.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/spacing.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tXzEzYTYxd3QwOiA0cHg7CiAgLS1fMTNhNjF3dDE6IDhweDsKICAtLV8xM2E2MXd0MjogMTZweDsKICAtLV8xM2E2MXd0MzogMjRweDsKICAtLV8xM2E2MXd0NDogMzJweDsKICAtLV8xM2E2MXd0NTogNDBweDsKfQ==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/typography.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/typography.css.ts.vanilla.css\",\"source\":\"Ll83YnVxdGIwIHsKICBmb250LXNpemU6IDMycHg7CiAgbGluZS1oZWlnaHQ6IDQ4cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKfQouXzdidXF0YjEgewogIGZvbnQtc2l6ZTogMjRweDsKICBsaW5lLWhlaWdodDogMzZweDsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fN2J1cXRiMiB7CiAgZm9udC1zaXplOiAyMHB4OwogIGxpbmUtaGVpZ2h0OiAzMHB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl83YnVxdGIzIHsKICBmb250LXNpemU6IDE2cHg7CiAgbGluZS1oZWlnaHQ6IDI0cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKfQouXzdidXF0YjQgewogIGZvbnQtc2l6ZTogMTRweDsKICBsaW5lLWhlaWdodDogMjBweDsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fN2J1cXRiNSB7CiAgZm9udC1zaXplOiAxMnB4OwogIGxpbmUtaGVpZ2h0OiAyMHB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl83YnVxdGI2IHsKICBmb250LXNpemU6IDExcHg7CiAgbGluZS1oZWlnaHQ6IDE2cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKfQouXzdidXF0YjcgewogIGZvbnQtc2l6ZTogMTBweDsKICBsaW5lLWhlaWdodDogMTRweDsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fN2J1cXRiOCB7CiAgZm9udC1zaXplOiAxNnB4OwogIGxpbmUtaGVpZ2h0OiAyNHB4OwogIGZvbnQtd2VpZ2h0OiA0MDA7Cn0KLl83YnVxdGI5IHsKICBmb250LXNpemU6IDE0cHg7CiAgbGluZS1oZWlnaHQ6IDIwcHg7CiAgZm9udC13ZWlnaHQ6IDQwMDsKfQouXzdidXF0YmEgewogIGZvbnQtc2l6ZTogMTJweDsKICBsaW5lLWhlaWdodDogMjBweDsKICBmb250LXdlaWdodDogNDAwOwp9Ci5fN2J1cXRiYiB7CiAgZm9udC1zaXplOiAxNHB4OwogIGxpbmUtaGVpZ2h0OiAyMHB4OwogIGZvbnQtd2VpZ2h0OiA2MDA7Cn0=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/animations.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/animations.css.ts.vanilla.css\",\"source\":\"QGtleWZyYW1lcyBfMXNybzl5NzAgewogIGZyb20gewogICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC0xMDAlKTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzEgewogIHRvIHsKICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgtMTAwJSk7CiAgfQp9CkBrZXlmcmFtZXMgXzFzcm85eTcyIHsKICBmcm9tIHsKICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgxMDAlKTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzMgewogIHRvIHsKICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgxMDAlKTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzQgewogIGZyb20gewogICAgb3BhY2l0eTogMDsKICB9CiAgdG8gewogICAgb3BhY2l0eTogMTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzUgewogIGZyb20gewogICAgb3BhY2l0eTogMTsKICB9CiAgdG8gewogICAgb3BhY2l0eTogMDsKICB9Cn0=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/pages/certificates-management/update-request/[requestId]/approve/steps/submit.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/pages/certificates-management/update-request/[requestId]/approve/steps/submit.css.ts.vanilla.css\",\"source\":\"Ll8xMm9vMm1vMCB7CiAgZmxleDogMTsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZ2FwOiA1MnB4OwogIHdpZHRoOiA0NjBweDsKICBtYXJnaW46IDAgYXV0bzsKfQouXzEyb28ybW8xIHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7CiAgZ2FwOiAxNnB4OwogIHBhZGRpbmc6IDMycHg7CiAgYm9yZGVyOiAxcHggc29saWQgdmFyKC0tY29sb3ItZ3JleS0yMDApOwogIGJhY2tncm91bmQ6IHZhcigtLWNvbG9yLXdoaXRlLTEwMCk7CiAgYm9yZGVyLXJhZGl1czogdmFyKC0tcmFkaXVzLTEwMCk7CiAgYm94LXNoYWRvdzogdmFyKC0tXzFnbW1heDYwKTsKICB0ZXh0LWFsaWduOiBjZW50ZXI7Cn0KLl8xMm9vMm1vMiB7CiAgZGlzcGxheTogZ3JpZDsKICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmciBhdXRvIDFmcjsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGdhcDogMTZweDsKfQouXzEyb28ybW8zIHsKICBwYWRkaW5nOiA0cHggOHB4OwogIHRleHQtYWxpZ246IGxlZnQ7CiAgYm9yZGVyLXJhZGl1czogdmFyKC0tcmFkaXVzLTEwMCk7Cn0KLl8xMm9vMm1vNCB7CiAgYmFja2dyb3VuZDogdmFyKC0tY29sb3ItZ3JleS0yMDApOwp9Ci5fMTJvbzJtbzUgewogIGJhY2tncm91bmQ6IHZhcigtLWNvbG9yLWdyZWVuLTEwMCk7Cn0=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var card = '_12oo2mo1';
export var container = '_12oo2mo0';
export var senderRecipient = '_12oo2mo2';
export var userBox = _7a468({defaultClassName:'_12oo2mo3',variantClassNames:{isActive:{false:'_12oo2mo4',true:'_12oo2mo5'}},defaultVariants:{isActive:false},compoundVariants:[]});