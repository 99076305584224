import { Fragment } from "react";

import { typography } from "@ag/design-system/tokens";
import { box, stack } from "@ag/design-system/utils";

import { useCarbonCountries } from "~features/countries";
import { getCountryWithFlag } from "~helpers/countries";

import { UpdateRequestAction } from "../entities/update-request";
import { UpdateRequestSummary as TUpdateRequestSummary } from "../entities/update-request-summary";
import { UpdateRequestSummaryCard } from "./update-request-summary-card";

type Props = {
  summary: TUpdateRequestSummary;
  action: UpdateRequestAction;
};

export const UpdateRequestSummary = ({ summary, action }: Props) => {
  const { data: countries } = useCarbonCountries();

  return (
    <section className={stack({ gap: 24 })}>
      <section className={box({ justify: "between" })}>
        <h3 className={typography.h3}>
          Total certificates to be{" "}
          {action === "transfer" ? "transferred" : "retired"}
        </h3>
        <h1 className={typography.h1} data-testid="total">
          {summary.quantity}
        </h1>
      </section>

      <div
        className={box({ gap: 8, align: "start" })}
        style={{ width: "100%" }}
      >
        <div className={stack({ gap: 8 })} style={{ flex: 1 }}>
          <UpdateRequestSummaryCard title="Standard">
            <div>Verra</div>
            <div>{summary.breakdown.standards.verra}</div>

            <div>ISO</div>
            <div>{summary.breakdown.standards.iso}</div>
          </UpdateRequestSummaryCard>

          <UpdateRequestSummaryCard title="Type">
            <div>Removal</div>
            <div>{summary.breakdown.types.removal}</div>

            <div>Reduction</div>
            <div>{summary.breakdown.types.reduction}</div>
          </UpdateRequestSummaryCard>

          <UpdateRequestSummaryCard title="Vintage year">
            {Object.entries(summary.breakdown.vintageYears).map(
              ([year, count]) => (
                <Fragment key={year}>
                  <div>{year}</div>
                  <div>{count}</div>
                </Fragment>
              ),
            )}
          </UpdateRequestSummaryCard>
        </div>

        <div style={{ flex: 1 }}>
          <UpdateRequestSummaryCard title="Country of origin">
            {Object.entries(summary.breakdown.countries).map(
              ([countryCode, count]) => {
                const certificateCountry = countries?.find(
                  country => country.code === countryCode,
                );

                const { flag, name } = getCountryWithFlag(
                  certificateCountry?.name,
                );

                return (
                  <Fragment key={countryCode}>
                    <div>{`${flag} ${name}`}</div>
                    <div data-testid={`summary-card-${countryCode}`}>
                      {count}
                    </div>
                  </Fragment>
                );
              },
            )}
          </UpdateRequestSummaryCard>
        </div>
      </div>
    </section>
  );
};
