import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~config/react-query";

import { generateCertificateBeneficiariesQueryKey } from "./get-certificate-beneficiaries";

type Beneficiary = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

const createCerificateBeneficiary = async (name: string) => {
  const response = await axios.post<CommonResponse<Beneficiary>>(
    "/api/v1/certificate-beneficiaries",
    { name },
    { apiSource: "node-markets" },
  );

  return response.data.success!.data;
};

export const useCreateCertificateBeneficiaryMutation = () =>
  useMutation(createCerificateBeneficiary, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateCertificateBeneficiariesQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
