/**
 * Helper for parsing the string input
 * (final-form deletes the key from the values if the input value is an empty string - we want to keep it)
 */
export const parseStringInput = (value: string) => value ?? "";

/**
 * Helper for converting empty strings coming from the form into nulls for the API
 */
export const convertEmptyStringsToNulls = <T extends Record<string, unknown>>(
  values: T,
) =>
  Object.keys(values).reduce((acc, key) => {
    const parsedValue = values[key] === "" ? null : values[key];

    return {
      ...acc,
      [key]: parsedValue,
    };
  }, {} as T);
