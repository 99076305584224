import { useSetAtom } from "jotai";
import { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@ag/design-system/atoms";

import BackButton from "~components/BackButton";
import { useSessionContext } from "~features/auth";
import {
  flatfileAccessTokenAtom,
  useCreateFlatfileSpaceMutation,
  useSpacesStore,
} from "~features/bulk-upload";
import { AuthorizedSidebar } from "~features/navigation";
import DetailsLayout from "~layouts/details-layout";

const Bulk = () => {
  const navigate = useRef(useNavigate());

  const spacesStore = useSpacesStore();

  const setFlatfileAccessToken = useSetAtom(flatfileAccessTokenAtom);

  const { currentAdmin, isLoading: isCurrentUserLoading } = useSessionContext();

  const createCQAEditsFlatfileSpaceMutation = useCreateFlatfileSpaceMutation();

  const cqaEditsSpace = useMemo(() => {
    if (!currentAdmin) return null;

    const spaces = spacesStore.getUserSpaces({ adminId: currentAdmin.id });

    return spaces.findLast(space => space.type === "cqa-edits");
  }, [currentAdmin, spacesStore]);

  const createCQAEditsSpace = async () => {
    createCQAEditsFlatfileSpaceMutation.mutate(
      {
        label: "cqa-edits",
        name: currentAdmin?.id ?? "",
        namespace: "cqa-edits",
      },
      {
        onSuccess: data => {
          if (!currentAdmin) return;

          // Not sure if this would ever be empty
          setFlatfileAccessToken(data.accessToken ?? "");
          spacesStore.add({
            adminId: currentAdmin.id,
            spaceId: data.id,
            type: "cqa-edits",
          });

          navigate.current(`/carbon/assurance/bulk/${data.id}`);
        },
      },
    );
  };

  return (
    <DetailsLayout.Root>
      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>Bulk update</DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.Content>
        <div className="grid gap-6 p-4">
          <section className="flex flex-col space-y-4">
            <h2>CQA Edits 2023</h2>

            <div className="flex flex-wrap space-x-4">
              {cqaEditsSpace ? (
                <Button to={`/carbon/bulk/cqa-edits/${cqaEditsSpace.id}`}>
                  Open existing space
                </Button>
              ) : null}

              <Button
                variant={cqaEditsSpace ? "secondary" : "primary"}
                isLoading={
                  isCurrentUserLoading ||
                  createCQAEditsFlatfileSpaceMutation.isLoading
                }
                onClick={createCQAEditsSpace}
              >
                Create new space
              </Button>
            </div>
          </section>
        </div>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default Bulk;
