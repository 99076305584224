import {
  Experiment,
  ExperimentClient,
  Variant,
} from "@amplitude/experiment-js-client";

import { env } from "~config";

export type FeatureFlag = "crops-v2" | "fertilisers-v2";

type AdminExperimentClient = Omit<ExperimentClient, "variant"> & {
  variant(key: FeatureFlag, fallback?: string | Variant): Variant;
};

export const experiment: AdminExperimentClient = Experiment.initialize(
  env.REACT_APP_AMPLITUDE_API_TOKEN as string,
  {
    serverZone: "eu",
  },
);
