import { useMemo } from "react";

import { Spinner } from "@ag/design-system/assets";

const PdfViewer = ({
  title,
  url,
  isFetching,
  errorState,
}: {
  title?: string;
  url: string | undefined;
  isFetching: boolean;
  errorState?: React.ReactNode;
}) => {
  const isValidUrl = useMemo(() => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return Boolean(url && urlRegex.test(url));
  }, [url]);

  const renderErrorState = () => (
    <div className="flex h-96 items-center justify-center">{errorState}</div>
  );

  const renderSpinner = () => (
    <div className="flex h-96 items-center justify-center">
      <Spinner />
    </div>
  );
  const renderPdfIframe = () => (
    <iframe className="h-[700px] w-full" src={url} />
  );

  return (
    <div>
      <h3 className="pb-4 text-h3 text-grey-900">{title}</h3>
      {!isFetching && (!url || !isValidUrl)
        ? renderErrorState()
        : isFetching
          ? renderSpinner()
          : renderPdfIframe()}
    </div>
  );
};

export default PdfViewer;
