import I18n from "i18n-js";

import {
  AdminCommentAttribute,
  AdminCommentFormData,
} from "./admin-comment-form/types";

export const validate = (values: AdminCommentFormData) => {
  const errors: Partial<Record<AdminCommentAttribute, string>> = {};

  if (!values.comment) {
    errors.comment = I18n.t("js.shared.required");
  }

  return errors;
};
