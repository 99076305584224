import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Button, InfoBox } from "@ag/design-system/atoms";
import { Card, Modal } from "@ag/design-system/organisms";
import {
  InputField,
  TextareaField,
  setInputValueAsNumber,
} from "@ag/form-fields";

import { useUpdateSalesOfferDetailsMutation } from "../api/update-sales-offer-details";
import { OfferDetails, SalesOffer } from "../entities/sales-offer";
// import { OfferStatus } from "../types/offer-status";
import SalesOfferDetailsTable from "./details-table";

const checkDecimalPlaces = (value: number) => {
  const [, decimal] = value.toString().split(".");
  return !decimal || decimal.length <= 2;
};

const updateResaleFeesSchema = z.object({
  percentageFee: z
    .number()
    .min(1, "Percentage fee is required")
    .refine(checkDecimalPlaces, {
      message:
        "Percentage fee must be a number with up to 2 decimal places (e.g. 1.23)",
    }),
  note: z.string().min(1, "A note is required"),
});

type UpdateResaleFees = z.infer<typeof updateResaleFeesSchema>;

type Props = Pick<
  OfferDetails,
  | "brokerPercentageFee"
  | "notes"
  | "estimatedIsoReductionCertificates"
  | "estimatedIsoRemovalCertificates"
  | "estimatedVerraReductionCertificates"
  | "estimatedVerraRemovalCertificates"
> &
  Pick<SalesOffer, "id" | "status">;

const ResaleFeesCard = ({
  id,
  brokerPercentageFee,
  notes, // status,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const updateSalesOfferDetails = useUpdateSalesOfferDetailsMutation();

  const { control, handleSubmit, register, reset, formState } =
    useForm<UpdateResaleFees>({
      resolver: zodResolver(updateResaleFeesSchema),
      defaultValues: {
        percentageFee: Number(brokerPercentageFee),
      },
    });

  const handleCloseModal = () => {
    reset();
    setShowModal(false);
  };

  const handleUpdateResaleFees = (data: UpdateResaleFees) => {
    updateSalesOfferDetails.mutate(
      {
        id,
        data: {
          offerDetails: {
            brokerPercentageFee: data.percentageFee.toString(),
            notes: data.note,
          },
        },
      },
      { onSuccess: handleCloseModal },
    );
  };

  return (
    <>
      <Card className="w-full" testid="resale-fees-card">
        <p className="mb-4 text-p2 font-bold">Resale pricing & fees</p>
        {notes && (
          <InfoBox icon="circle-info" variant="neutral">
            Note: {notes}
          </InfoBox>
        )}
        <div className="mt-4 flex w-1/2 flex-wrap gap-4">
          <SalesOfferDetailsTable
            rows={[
              {
                label: "Percentage fee",
                value: `${brokerPercentageFee}%`,
              },
            ]}
          />
          <div className="flex items-end">
            {/* TODO => Check role permissions to show edit button */}
            <Button
              size="x-small"
              variant="text"
              theme="light"
              icon="pen-to-square"
              onClick={() => setShowModal(true)}
              className="flex gap-2"
              disabled={true}
              // TODO [ASS-266] => Enable editing resale options
              // disabled={Boolean(status === OfferStatus.ContractCreated)}
            >
              Edit
            </Button>
          </div>
        </div>
      </Card>
      <Modal.Root
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        className="flex gap-6 p-10"
      >
        <Modal.Header align="center">
          <Modal.Title>Update Resale fees</Modal.Title>

          <Modal.SubTitle>
            Update suggested fees under authorisation of Finance only.
          </Modal.SubTitle>
        </Modal.Header>

        <Modal.Content>
          <form className="flex flex-col gap-6">
            <InputField
              {...register("percentageFee", {
                setValueAs: setInputValueAsNumber,
              })}
              label="Percentage fee"
              rightAddon="%"
              type="number"
              placeholder="Enter percentage fee"
              error={formState.errors.percentageFee}
            />

            <Controller
              name="note"
              control={control}
              render={({ field, fieldState }) => (
                <TextareaField
                  {...field}
                  error={fieldState.error}
                  value={field.value ?? ""}
                  label="Note"
                  placeholder="Add a note (required)"
                  rows={6}
                  maxLength={150}
                />
              )}
            />

            <InfoBox variant="neutral" icon="circle-info">
              The Customer Success team will receive an email
              (support@agreena.com) notifying of any changes.
            </InfoBox>
          </form>
        </Modal.Content>

        <Modal.Footer
          render={onClose => (
            <div className="flex gap-6">
              <Button
                type="button"
                variant="text"
                size="x-small"
                onClick={onClose}
              >
                Close
              </Button>

              <Button
                variant="primary"
                size="x-small"
                onClick={handleSubmit(handleUpdateResaleFees)}
              >
                Save & notify
              </Button>
            </div>
          )}
        />
      </Modal.Root>
    </>
  );
};

export default ResaleFeesCard;
