import api from "@flatfile/api";
import {
  CellValueUnion,
  RecordWithLinks,
  ValidationMessage,
} from "@flatfile/api/api";
import axios from "axios";
import { SetStateAction } from "jotai";
import { chunk, groupBy } from "lodash";
import { ReactNode } from "react";

import { AssuranceStatus, changeFieldsStatus } from "~features/assurance";
import { Field, QualityAssuranceStatus, getField } from "~features/field";

import { updateCQAFieldDefinition } from "../api/update-cqa-field-definition";
import { getCompleteReportModalContent } from "../components/bulk-cqa-edits-modal/helpers";
import { CQAFieldDefinitionData } from "../entities/cqa-edits";
import { Row } from "../types/cqa-edits";
import { getCQAFieldDefinitionUpdateData } from "./get-field-definition-data";

type UpdateCQAStatusBatchesArgs = {
  updateRequestsRows: Row<CQAFieldDefinitionData>[];
  status: AssuranceStatus;
  failedRows: UpdateFailedRow[];
};

const updateCQAStatusBatches = async ({
  updateRequestsRows,
  status,
  failedRows,
}: UpdateCQAStatusBatchesArgs) => {
  // Max limit for API is 100 fields per batch when updating the fieldsStatus
  const updateStatusBatches = chunk(updateRequestsRows, 100);

  const succeededRows: Row<CQAFieldDefinitionData>[] = [];

  for await (const rows of updateStatusBatches) {
    try {
      const response = await changeFieldsStatus({
        fieldIds: rows.map(({ fieldId }) => String(fieldId)),
        payload: {
          newStatus: status,
          description: "Changed by bulk update CQA edits",
        },
      });

      response.forEach((result, index) => {
        if (result.error) {
          failedRows.push({
            row: rows[index],
            updateStatus: status,
            errorMessage: result.error!.message,
          });

          return;
        }

        succeededRows.push(rows[index]);
      });
    } catch (err) {
      rows.forEach(row => {
        failedRows.push({
          row,
          updateStatus: status,
        });
      });
    }
  }

  return succeededRows;
};

export type BulkUpdatCQCEditsParams = {
  context: {
    jobId: string;
    sheetId: string;
    records: RecordWithLinks[];
  };
  options: {
    harvestYear: number;
    setIsModalOpen: (value: SetStateAction<boolean>) => void;
    setModalContent: (value: SetStateAction<ReactNode>) => void;
  };
};

export type UpdateFailedRow = {
  row: Row<CQAFieldDefinitionData>;
  updateStatus?: AssuranceStatus;
  errorMessage?: string;
};

export async function bulkUpdatCQAEdits({
  context,
  options,
}: BulkUpdatCQCEditsParams) {
  const { jobId, sheetId, records } = context;
  const { harvestYear, setIsModalOpen, setModalContent } = options;

  const updatedRows: Row<CQAFieldDefinitionData>[] = [];

  // Negative scenarios
  const nonExstingFieldsRows: Row<CQAFieldDefinitionData>[] = [];
  const qaStatusNotApprovedRows: Row<CQAFieldDefinitionData>[] = [];
  const dataUpdateFailedRows: UpdateFailedRow[] = [];
  const qaNonConformanceStatusUpdateFailedRows: UpdateFailedRow[] = [];
  const qaApprovedStatusUpdateFailedRows: UpdateFailedRow[] = [];

  try {
    const cqaEditsRows: Row<CQAFieldDefinitionData>[] = records.map(record => {
      const entity: Record<string, CellValueUnion | undefined> = {};
      for (const [key, value] of Object.entries(record.values)) {
        entity[key] = value.value;
      }
      return {
        ...(entity as CQAFieldDefinitionData),
        record,
      };
    });

    // Fetch all fields
    const fieldIds = [...new Set(cqaEditsRows.map(row => row.fieldId))];

    const allFields = await Promise.all(
      fieldIds.map(async fieldId => {
        try {
          return await getField(fieldId, {
            actualHarvestYear: harvestYear,
          });
        } catch (err) {
          return null;
        }
      }),
    );

    const allFieldsById = groupBy(allFields, field => field?.id);

    await api.jobs.ack(jobId, {
      info: `Fetched ${cqaEditsRows.length} fields`,
      progress: 10,
    });

    const updateRequests: {
      row: Row<CQAFieldDefinitionData>;
      field: Field;
    }[] = [];

    for (const cqaEditsRow of cqaEditsRows) {
      const { fieldId } = cqaEditsRow;
      const [field] = allFieldsById[fieldId] ?? [];

      // 1. Check if fieldId exist and belongs to a harvest year
      if (!field) {
        nonExstingFieldsRows.push(cqaEditsRow);
        continue;
      }

      // 2. Check if qa status is equal to approved
      if (field.qaStatus !== QualityAssuranceStatus.Approved) {
        qaStatusNotApprovedRows.push(cqaEditsRow);
        continue;
      }

      updateRequests.push({
        row: cqaEditsRow,
        field,
      });
    }

    if (updateRequests.length) {
      // 3. Change qa status to non_conformance before update
      const succeededNonConformanceStatusUpdateRows =
        await updateCQAStatusBatches({
          updateRequestsRows: updateRequests.map(({ row }) => row),
          status: AssuranceStatus.NonConformance,
          failedRows: qaNonConformanceStatusUpdateFailedRows,
        });

      const updateBatches = chunk(succeededNonConformanceStatusUpdateRows, 20);

      for (const updateBatch of updateBatches) {
        const percentageDone =
          updateBatches.indexOf(updateBatch) / updateBatches.length;

        // 4. Update field definition
        const updateFieldsDefinitionResponses = await Promise.all(
          updateBatch.map(async row => {
            const { fieldId } = row;
            const [field] = allFieldsById[fieldId] ?? [];

            try {
              await updateCQAFieldDefinition({
                fieldId: field!.id,
                fieldDefinitionId: field!.carbonFieldDefinition.id,
                fieldDefinition: getCQAFieldDefinitionUpdateData(row),
              });

              return {
                status: "success",
                row,
              };
            } catch (err) {
              return {
                status: "failed",
                row,
                errorMessage: String(err),
              };
            }
          }),
        );

        updateFieldsDefinitionResponses.map(({ status, row, errorMessage }) => {
          if (status === "success") {
            updatedRows.push(row);
          } else {
            dataUpdateFailedRows.push({ row, errorMessage });
          }
        });

        await api.jobs.ack(jobId, {
          info: `Updated ${updatedRows.length} fields`,
          // Progress from 30 -> 80
          progress: 30 + Math.floor(percentageDone * 50),
        });
      }

      /**
       * 5. Bring back original Approved status for all fields,
       * that were previosuly changes to non_conformance before update
       * Bring it back not only for the fields that were successfully updated,
       * but also for the ones that failed.
       **/
      await updateCQAStatusBatches({
        updateRequestsRows: succeededNonConformanceStatusUpdateRows,
        status: AssuranceStatus.Approved,
        failedRows: qaApprovedStatusUpdateFailedRows,
      });
    }

    // Show error on the record level
    if (nonExstingFieldsRows.length) {
      await api.records.update(
        sheetId,
        nonExstingFieldsRows.map(row => {
          const message: ValidationMessage = {
            type: "error",
            message: `FieldId does not exist in the harvestYear ${harvestYear}`,
          };

          return addRecordMessage(row.record.id, row.record, message);
        }),
      );
    }

    // Show complete report modal with success/error messages
    const completeRepormModalContent = getCompleteReportModalContent({
      harvestYear,
      updatedRows,
      dataUpdateFailedRows,
      nonExstingFieldsRows,
      qaStatusNotApprovedRows,
      qaNonConformanceStatusUpdateFailedRows,
      qaApprovedStatusUpdateFailedRows,
    });

    setIsModalOpen(true);
    setModalContent(completeRepormModalContent);

    await api.jobs.complete(jobId);
  } catch (err) {
    await api.jobs.fail(jobId, {
      outcome: {
        acknowledge: true,
        heading: "Update failed :(",
        message: getErrorMessage(err),
      },
    });
  }
}

function getErrorMessage(err: unknown) {
  const message = axios.isAxiosError(err)
    ? err.response?.data?.errors ?? err?.message
    : (err as Error).message ?? err?.toString() ?? err;

  if (Array.isArray(message)) {
    return message.join(", ");
  }

  if (typeof message === "string") return message;

  return JSON.stringify(message);
}

function addRecordMessage(
  key: string,
  record: RecordWithLinks,
  message: ValidationMessage,
): RecordWithLinks {
  return {
    ...record,
    values: {
      ...record.values,
      [key]: {
        ...record.values[key],
        messages: [message],
      },
    },
  };
}
