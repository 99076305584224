import { FieldRenderProps } from "react-final-form";

import { MultiSelect, MultiSelectCommonProps } from "~selects";

type Props = FieldRenderProps<string[]> & MultiSelectCommonProps;

export const FormMultiSelect = ({ input, meta, ...rest }: Props) => (
  <MultiSelect
    value={input.value}
    error={meta.touched ? meta.error : null}
    onChange={input.onChange}
    {...rest}
  />
);
