import I18n from "i18n-js";

import { FieldOverview } from "../entities/field-overview";

export function validateCanUnlockDefinition(field: FieldOverview) {
  if (!field.carbonFieldDefinition.submittedAt) {
    return { error: I18n.t("js.fields.only_unlock_submitted_definition") };
  }

  if (field.activeCarbonContractId) {
    return {
      error: I18n.t("js.fields.only_unlock_field_definition_without_contract"),
    };
  }

  return { error: null };
}

export function validateCanUnlockActuals(
  field: FieldOverview,
  bulkActionYear: number,
) {
  const submittedActuals = (field.carbonFieldActuals ?? []).filter(
    actuals => actuals.submittedAt,
  );

  if (!submittedActuals.length) {
    return { error: I18n.t("js.fields.cannot_unlock_empty_actuals") };
  }

  if (
    submittedActuals.some(actuals => actuals.harvestYear !== bulkActionYear)
  ) {
    return { error: I18n.t("js.fields.only_unlock_actuals_same_harvest_year") };
  }

  return { error: null };
}

export function validateCanDeleteField(field: FieldOverview) {
  if (field.activeCarbonContractId) {
    return { error: I18n.t("js.fields.only_delete_non_contracted_fields") };
  }

  return { error: null };
}
