import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type JobReference = {
  id: string;
  bulkUploadId: number;
  estimatedCompletionAt: Date;
};

type SpaceData = {
  type: "cqc-edits" | "cqa-edits" | "cqc-edits-fertilisers";
  createdAt: number;
  id: string;
  adminId: string;
  jobs: JobReference[];
};

type SpacesStoreData = {
  spaces: Record<string, SpaceData>;

  add: (options: {
    type: "cqc-edits" | "cqa-edits" | "cqc-edits-fertilisers";
    spaceId: string;
    adminId: string;
    jobs?: JobReference[];
  }) => void;
  getUserSpaces: (options: { adminId: string }) => SpaceData[];
  delete: (options: { spaceId: string }) => void;
  addJob: (options: { spaceId: string; job: JobReference }) => void;
};

// TODO: move this to API, we don't want to rely on client side data.
export const useSpacesStore = create(
  persist(
    immer<SpacesStoreData>((set, get) => ({
      spaces: {},

      add: ({ spaceId, adminId, jobs, type }) =>
        set(state => {
          if (state.spaces[spaceId]) return;

          state.spaces[spaceId] = {
            type,
            createdAt: Date.now(),
            id: spaceId,
            adminId,
            jobs: jobs ?? [],
          };
        }),
      delete: ({ spaceId }) =>
        set(state => {
          delete state.spaces[spaceId];
        }),

      addJob: ({ spaceId, job }) =>
        set(state => {
          if (
            state.spaces[spaceId]?.jobs?.some(
              existingJob => existingJob.id === job.id,
            )
          ) {
            return;
          }

          state.spaces[spaceId]?.jobs?.push(job);
        }),

      getUserSpaces: ({ adminId }) =>
        Object.values(get().spaces).filter(space => space.adminId === adminId),
    })),
    {
      name: "bulk-upload-spaces",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
