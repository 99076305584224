import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Spinner } from "@ag/design-system/assets";
import { Tabs, useTabs } from "@ag/design-system/organisms";

import {
  FertiliserLegacyPropertiesForm,
  FertiliserPropertiesForm,
} from "~features/fertilisers";
import { useFertiliserByHarvestYearQuery } from "~features/fertilisers/api/get-fertiliser-by-harvest-year";
import { useYearsDataQuery } from "~features/initial-resources";
import { AuthorizedSidebar } from "~features/navigation";
import { withFeatureFlagAccess } from "~hocs/withFeatureFlagAccess";
import DetailsLayout from "~layouts/details-layout";

const capitalizeWords = (str: string) => {
  return str.replace(/-/g, " ").replace(/\b\w/g, char => char.toUpperCase());
};

const FertiliserDetails = () => {
  const { id } = useParams<{ id: string }>();
  // TODO: remove query params when there is going to be a new endpoint
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");

  const formattedName = name ? capitalizeWords(name) : "";

  const navigate = useNavigate();
  const { activeTab, onChange: onTabChange } = useTabs();

  if (!id) {
    navigate("/fertilisers");
  }

  const { data: yearsData } = useYearsDataQuery();
  const harvestYears = useMemo(
    // spread operator otherwise it keeps change the order of the years
    () =>
      yearsData ? [...yearsData.allHarvestYears].reverse().map(String) : [],
    [yearsData],
  );

  const { data, isLoading, isFetching } = useFertiliserByHarvestYearQuery(
    {
      id: id!,
      harvestYear: activeTab || harvestYears[0],
    },
    { enabled: Boolean(activeTab) },
  );

  useEffect(() => {
    if (!activeTab && harvestYears.length > 0) {
      onTabChange(harvestYears[0]);
    }
  }, [activeTab, harvestYears, onTabChange]);

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <DetailsLayout.TopBarTitle>
          Fertiliser details
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        <div className="space-y-4">
          <div>
            <span className="text-h2">{formattedName}</span>
          </div>
          {isLoading || isFetching ? (
            <div className="flex h-5 items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <Tabs.Root value={activeTab} onChange={onTabChange}>
              <Tabs.List key="details">
                {harvestYears.map((harvestYear, index) => (
                  <Tabs.Trigger value={harvestYear} key={`tab-${index}`}>
                    <Tabs.TriggerTitle>{harvestYear}</Tabs.TriggerTitle>
                  </Tabs.Trigger>
                ))}
              </Tabs.List>

              {harvestYears.map((harvestYear, index) => (
                <Tabs.Content key={`tab-content-${index}`} value={harvestYear}>
                  {/* when it is before harvest year 2024, it has legacy properties */}
                  {activeTab && activeTab < "2024" ? (
                    <FertiliserLegacyPropertiesForm
                      data={data}
                      key={activeTab}
                    />
                  ) : (
                    <FertiliserPropertiesForm
                      data={data}
                      activeTab={activeTab || harvestYears[0]}
                      key={activeTab}
                    />
                  )}
                </Tabs.Content>
              ))}
            </Tabs.Root>
          )}
        </div>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default withFeatureFlagAccess("fertilisers-v2", {
  redirectPath: "/carbon/fertilisers",
})(FertiliserDetails);
