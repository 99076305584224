import { useCallback } from "react";
import { StringParam } from "use-query-params";

import { Filters, useFilters } from "@ag/components/Filters";
import { InputField } from "@ag/form-fields";
import { SIGN_IN_AS_PARAM } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";
import { usePagination } from "@ag/utils/hooks";

import Table from "~components/table";
import { env } from "~config";
import { useOwnAssignedUsersQuery } from "~features/admin";
import { AuthorizedSidebar } from "~features/navigation";
import { useSignInAsAssociatedUserMutation } from "~features/user";
import { useOwnAssignedUsersTable } from "~features/user/hooks/use-own-assigned-users-table";
import ListLayout from "~layouts/list-layout";

const filtersConfig = {
  search: [StringParam, { isDebounced: true }],
} as const;

const AssignedUsers = () => {
  const [pagination, updatePagination, resetPagination] = usePagination();
  const { mutate: signInAsAssociatedUser } =
    useSignInAsAssociatedUserMutation();

  const filters = useFilters(filtersConfig);

  const { data: usersData, isLoading } = useOwnAssignedUsersQuery({
    ...pagination,
    filters: filters.values,
  });

  const onSignIn = useCallback(
    (userId: string) => {
      signInAsAssociatedUser(userId.toString(), {
        onSuccess: ({ ticket }) => {
          const params = getSearchParams({
            [SIGN_IN_AS_PARAM]: ticket,
          });

          window.open(env.REACT_APP_FARMER_URL + params);
        },
      });
    },
    [signInAsAssociatedUser],
  );

  const table = useOwnAssignedUsersTable(usersData?.items, onSignIn);

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Assigned Users</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <ListLayout.Filters>
            <Filters.Root
              values={filters.values}
              onChange={(...args) => {
                filters.onChange(...args);
                resetPagination();
              }}
            >
              <Filters.Item
                render={({ register }) => (
                  <InputField {...register("search")} label="search" />
                )}
              />
              <Filters.Reset
                onClear={() => {
                  filters.onClear();
                  resetPagination();
                }}
              />
            </Filters.Root>
          </ListLayout.Filters>
        </ListLayout.Header>

        <Table
          instance={table}
          meta={usersData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default AssignedUsers;
