import {
  Cell,
  Header,
  RowData,
  Table as TList,
  flexRender,
} from "@tanstack/react-table";
import React, { ForwardedRef, useState } from "react";

import { fixedForwardRef } from "@ag/utils/types";

import { Icon } from "~assets";
import { IconButton, Tooltip } from "~atoms";
import { cn } from "~utils";

import { getSortIconProps } from "./helpers";

/* -------------------------------------------------------------------------------------------------
 * List
 * -----------------------------------------------------------------------------------------------*/
type ListProps<TData extends RowData> = {
  instance: TList<TData>;
  className?: string;
  emptyState?: React.ReactNode;
  isLoading?: boolean;
  onRowClick?: (original: TData) => void;
};

export const List = fixedForwardRef(ListComponent);

function ListComponent<TData extends RowData>(
  { instance, emptyState, className, isLoading, onRowClick }: ListProps<TData>,
  ref: ForwardedRef<HTMLTableSectionElement>,
) {
  const [rowHoveredId, setRowHoveredId] = useState<string | null>(null);

  const headers = instance.getFlatHeaders();

  const { rows } = instance.getRowModel();

  return (
    <table
      className={cn(
        "[--cell-bg:theme(colors.grey.white)]",
        "[--cell-bg-disabled:theme(colors.grey.200)]",

        "[--cell-hover-border:theme(colors.accent.200)]",
        "[--cell-invalid-border:theme(colors.messaging.error.700)]",
        "[--cell-clickable-border:theme(colors.sky.200)]",

        "border-separate border-spacing-y-2",
        className,
      )}
    >
      <thead>
        <tr className="text-p3 uppercase text-grey-600">
          {headers.map(header => (
            <HeaderCell key={header.id} header={header} />
          ))}
        </tr>
      </thead>

      <tbody ref={ref}>
        {rows.map(row => {
          /**
           * In order to control whether a row is disabled
           * we use the state from the select feature `row.getCanSelect()`,
           * but it returns false even when the selection feature is disabled.
           * To fix this, we need to check if the select feature is enabled
           * before using this function.
           */
          const isDisabled =
            instance.options.enableRowSelection === false
              ? false
              : !row.getCanSelect();

          return (
            <tr
              key={row.id}
              aria-disabled={isDisabled}
              className={cn(
                "bg-[--cell-bg] text-p2 text-grey-900",
                "shadow-100 hover:shadow-200",
                "aria-disabled:bg-[--cell-bg-disabled] aria-disabled:hover:bg-[--cell-bg-disabled]",
              )}
              onClick={() => {
                if (onRowClick) {
                  onRowClick(row.original);
                }
              }}
              onMouseOver={() => setRowHoveredId(row.id)}
              onMouseOut={() => setRowHoveredId(null)}
            >
              <>
                {row.getVisibleCells().map(cell => (
                  <BodyCell
                    isRowHovered={rowHoveredId === row.id}
                    isRowClickable={Boolean(onRowClick)}
                    isRowInvalid={Boolean(
                      instance.options.meta?.errors?.[row.id]?.length,
                    )}
                    cell={cell}
                    key={cell.id}
                    testid={`${row.id}-${cell.column.id}`}
                  />
                ))}
              </>
            </tr>
          );
        })}

        {!isLoading && !rows.length && (
          <tr>
            <td colSpan={headers.length}>{emptyState ?? "-"}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

/* -------------------------------------------------------------------------------------------------
 * HeaderCell
 * -----------------------------------------------------------------------------------------------*/
type HeaderCellProps<TData extends RowData> = {
  header: Header<TData, unknown>;
};

function HeaderCell<TData extends RowData>({ header }: HeaderCellProps<TData>) {
  const { tooltip, onTooltipHover } = header.column.columnDef.meta ?? {};

  return (
    <th className="whitespace-nowrap px-4 py-0">
      <div className="flex items-center gap-1 text-p3">
        <span className="font-bold text-grey-500">
          {flexRender(header.column.columnDef.header, header.getContext())}
        </span>

        {tooltip && (
          <Tooltip
            content={typeof tooltip === "function" ? tooltip() : tooltip}
            onHover={onTooltipHover}
          >
            <Icon name="info-circle" className="text-grey-600" />
          </Tooltip>
        )}

        {header.column.columnDef.enableSorting && (
          <IconButton
            className="text-grey-600"
            variant="text"
            {...getSortIconProps(header.column.getIsSorted(), {
              sortIndex: header.column.getSortIndex(),
              isMulti: header.column.getCanMultiSort(),
            })}
            onClick={() => {
              const direction = header.column.getIsSorted();
              const isMulti = header.column.getCanMultiSort();

              /**
               * When multi sorting, we need a way to remove sorting from a column,
               * as clicking another column will not clear the others.
               * So this means that when the column is "ascending" and you click again,
               * it removes the sorting, instead of going to "descending" again.
               */
              if (isMulti && direction === "asc") {
                header.column.clearSorting();
              } else {
                header.column.toggleSorting(
                  direction !== "desc",
                  header.column.getCanMultiSort(),
                );
              }
            }}
          />
        )}
      </div>
    </th>
  );
}

/* -------------------------------------------------------------------------------------------------
 * BodyCell
 * -----------------------------------------------------------------------------------------------*/
type BodyCellProps<TData extends RowData> = {
  cell: Cell<TData, unknown>;
  testid?: string;
  isRowHovered: boolean;
  isRowClickable?: boolean;
  isRowInvalid?: boolean;
};

function BodyCell<TData extends RowData>({
  cell,
  testid,
  isRowHovered,
  isRowClickable,
  isRowInvalid,
}: BodyCellProps<TData>) {
  const context = cell.getContext();

  const { minSize, size, maxSize } = cell.column.columnDef;
  const currentSize = cell.column.getSize();

  let maxWidth = maxSize || undefined;
  let minWidth = minSize || undefined;

  /**
   * If the consumer specified a size, let the cell be that exact size.
   * since we cannot use "width" style in tables, instead set both min/max-width to achieve the same.
   *
   * Note: the reason we use the columnSize, is that we still in some cases allow resizing of size,
   * so the current size might be different from the one defined in columnDef.
   */
  if (size && currentSize) {
    maxWidth = currentSize;
    minWidth = currentSize;
  }

  const { id: rowId } = context.row;
  const rowErrors = context.table.options.meta?.errors?.[rowId];
  const cellError = rowErrors?.find(error => error.column === cell.column.id);

  return (
    <td
      key={cell.id}
      className={cn(
        "box-content cursor-pointer overflow-hidden whitespace-nowrap",
        "p-4",

        "border-b border-l-0 border-r-0 border-t border-double border-transparent",
        "first-of-type:rounded-l first-of-type:border-l",
        "last-of-type:rounded-r last-of-type:border-r",

        isRowClickable && "border-[--cell-clickable-border]",
        isRowHovered && "border-[--cell-hover-border]",
        isRowInvalid && "border-[--cell-invalid-border]",
      )}
      style={{
        maxWidth,
        minWidth,
      }}
      data-testid={testid}
    >
      <>
        {flexRender(cell.column.columnDef.cell, context)}

        {cellError ? (
          <Tooltip content={cellError.message}>
            <Icon
              name="circle-exclamation"
              variant="solid"
              className="-mb-[1px] ml-[6px] text-messaging-error-700"
            />
          </Tooltip>
        ) : null}
      </>
    </td>
  );
}
