import { Flatfile } from "@flatfile/api";
import { useMutation } from "@tanstack/react-query";

import { ToastNotification } from "@ag/utils/services";

import {
  createFlatfileClient,
  getEnviromentId,
} from "../helpers/flatfile-client";

const client = createFlatfileClient();
const environmentId = getEnviromentId();

type FlatfileSpaceArgs = Flatfile.spaces.SpaceConfig & {
  namespace: string;
  label: string;
};

async function createFlatfileSpace({ label, ...args }: FlatfileSpaceArgs) {
  const { data: space } = await client.spaces.create({
    environmentId,
    ...args,
    // TODO: figure out the general security model, with and without guest auth.
    guestAuthentication: ["magic_link", "shared_link"],
    labels: [label, "embedded"],
  });

  return space;
}

export const useCreateFlatfileSpaceMutation = () =>
  useMutation(createFlatfileSpace, {
    onError: err => ToastNotification.error(err),
  });
