import { Navigate, useLocation } from "react-router-dom";

import { ToastNotification } from "@ag/utils/services";

import { FeatureFlag, experiment } from "~lib/ampli";

export function withFeatureFlagAccess<T extends object>(
  name: FeatureFlag,
  options?: { redirectPath?: string },
) {
  return (WrappedComponent: () => JSX.Element) => {
    const WithFeatureFlagAccessComponent = (props: T) => {
      const location = useLocation();

      const featureFlag = experiment.variant(name);
      const isFeatureFlagActive = featureFlag.value === "on";

      if (!isFeatureFlagActive) {
        if (!options?.redirectPath) {
          ToastNotification.error("You don't have access to this page");
        }

        return (
          <Navigate
            to={options?.redirectPath ?? "/"}
            state={{ from: location }}
            replace
          />
        );
      }

      return <WrappedComponent {...props} />;
    };

    return WithFeatureFlagAccessComponent;
  };
}
