import { cva } from "class-variance-authority";
import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";

import { withProvider } from "@ag/utils/hocs";

import { Icon } from "~assets";
import { cn } from "~utils";

const rootVariants = cva(
  [
    "fixed bottom-0 top-0 z-drawer gap-6 overflow-hidden bg-grey-white p-6",
    "duration-500 ease-in-out [animation-fill-mode:forwards] max-md:w-screen md:min-w-[442px]",
  ],
  {
    variants: {
      side: {
        left: "left-0",
        right: "right-0",
      },
      isOpen: {
        false: {},
      },
    },
    compoundVariants: [
      {
        side: "left",
        isOpen: true,
        class: "animate-[slideInRight]",
      },
      {
        side: "left",
        isOpen: false,
        class: "animate-[slideOutLeft]",
      },
      {
        side: "right",
        isOpen: true,
        class: "animate-[slideInLeft]",
      },
      {
        side: "right",
        isOpen: false,
        class: "animate-[slideOutRight]",
      },
    ],
  },
);

/* -------------------------------------------------------------------------------------------------
 * Drawer
 * -----------------------------------------------------------------------------------------------*/
type DrawerProps = React.PropsWithChildrenRequired<{
  side?: "left" | "right";
  isOpen?: boolean;
  onClose: () => void;
}>;

const onCloseAtom = atom<(() => void) | undefined>(undefined);

function Drawer({
  children,
  side = "left",
  isOpen = false,
  onClose,
}: DrawerProps) {
  const [, setOnClose] = useAtom(onCloseAtom);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setOnClose(() => onClose);
  }, [onClose, setOnClose]);

  useEffect(() => {
    if (isOpen) setIsMounted(true);
  }, [isOpen]);

  const onOverlayClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  if (!isMounted) return null;

  return (
    <>
      <div
        className={cn(
          "fixed left-0 top-0 z-drawer flex h-full w-full animate-[fadeOut] justify-center overflow-y-auto bg-[rgba(0,0,0,0.3)] duration-500 ease-in-out [animation-fill-mode:forwards]",
          isOpen && "animate-[fadeIn]",
        )}
        onClick={onOverlayClick}
      />
      <div
        className={rootVariants({ side, isOpen })}
        onAnimationEnd={() => setIsMounted(isOpen)}
      >
        <RemoveScroll
          enabled={isOpen}
          className="grid h-full grid-rows-[auto_1fr_auto] gap-4 [grid-template-areas:'header''body''footer']"
        >
          {children}
        </RemoveScroll>
      </div>
    </>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Header
 * -----------------------------------------------------------------------------------------------*/
function DrawerHeader({ children }: React.PropsWithChildrenRequired) {
  const [onClose] = useAtom(onCloseAtom);

  if (!onClose) return null;

  return (
    <div className="flex place-items-center justify-between gap-2 overflow-y-auto [grid-area:header]">
      <h3 className="text-h3">{children}</h3>

      <button
        className="inline-grid place-content-center border-none bg-transparent p-px text-p1 text-grey-900 outline-none hover:cursor-pointer"
        onClick={onClose}
      >
        <Icon name="close" />
      </button>
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
type DrawerContentProps = React.PropsWithChildrenRequired<{
  className?: string;
}>;

function DrawerContent({ children, className }: DrawerContentProps) {
  return (
    <div className={cn("min-h-0 overflow-y-auto [grid-area:body]", className)}>
      {children}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Footer
 * -----------------------------------------------------------------------------------------------*/
function DrawerFooter({ children }: React.PropsWithChildrenRequired) {
  return (
    <div className="grid grid-flow-col gap-2 [grid-area:footer]">
      {children}
    </div>
  );
}

const Root = withProvider(Drawer);
const Header = DrawerHeader;
const Content = DrawerContent;
const Footer = DrawerFooter;

export { Root, Header, Content, Footer };
