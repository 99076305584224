import I18n from "@ag/i18n";

import { EditFieldAttribute, EditFieldData } from "./types";

export const validate = (values: EditFieldData) => {
  const errors: Partial<Record<EditFieldAttribute, string>> = {};

  if (!values.name) {
    errors.name = I18n.t("js.shared.required");
  }

  if (!values.sizeHa) {
    errors.sizeHa = I18n.t("js.shared.required");
  }

  if (!values.latitude) {
    errors.latitude = I18n.t("js.shared.required");
  }

  if (!values.longitude) {
    errors.longitude = I18n.t("js.shared.required");
  }

  return errors;
};
