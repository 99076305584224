import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icon } from "@ag/design-system/assets";
import { Button, FileRejection, InfoBox } from "@ag/design-system/atoms";
import { colors, typography } from "@ag/design-system/tokens";
import { stack } from "@ag/design-system/utils";
import { DropzoneField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import {
  useDistributeVcuMutation,
  useImportVerraVcuStore,
} from "~features/import-vcu";

import * as styles from "../../select-distribution.css";
import * as pageStyles from "../../styles.css";

type Props = {
  onGoPrevStep: () => void;
  unblockRoute: () => void;
};

const SelectVerraDistribution = ({ onGoPrevStep, unblockRoute }: Props) => {
  const [fileErrors, setFileErrors] = useState<string[] | undefined>(undefined);

  const navigate = useNavigate();

  const { distributionFile, setDistributionFile, resetState } =
    useImportVerraVcuStore();

  const distributeVcuMutation = useDistributeVcuMutation();

  const handleFileDropped = (
    acceptedFile: File | undefined,
    fileRejection: FileRejection | undefined,
  ) => {
    setFileErrors(undefined);

    if (fileRejection) {
      const errors = fileRejection.errors.map(error => {
        if (error.code === "file-too-large") {
          return "File is too large, please upload a file smaller than 500KB";
        }
        if (error.code === "file-invalid-type") {
          return "Invalid file type, please upload a .csv file";
        }

        return error.message;
      });

      setFileErrors(errors);

      return;
    }

    setDistributionFile(acceptedFile);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!distributionFile) return;

    distributeVcuMutation.mutate(distributionFile, {
      onSuccess: () => {
        unblockRoute();
        navigate("/certificates-management");
        resetState();
        ToastNotification.success("Certificates successfully imported");
      },
    });
  };

  return (
    <form className={pageStyles.root} onSubmit={onSubmit}>
      <div className={styles.container}>
        <h2 className={typography.h2}>Select the distribution file</h2>

        {fileErrors?.map(fileError => (
          <InfoBox key={fileError} variant="danger" icon="triangle-exclamation">
            {fileError}
          </InfoBox>
        ))}

        {distributionFile ? (
          <div className={styles.detailsBox}>
            <Icon name="check" fontSize={32} />
            <h5 className={typography.p2}>Upload complete</h5>
            <div
              style={{
                display: "flex",
                gap: "8px",
              }}
            >
              <h5
                className={typography.p3}
                style={{
                  color: colors.grey[700],
                }}
                data-testid="distribution-file-name"
              >
                {distributionFile?.name}
              </h5>
              <Button
                type="button"
                size="x-small"
                variant="text"
                onClick={() => setDistributionFile(undefined)}
                isDanger
              >
                Remove
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.dropzoneBox}>
            <DropzoneField
              onChange={handleFileDropped}
              label=""
              accept=".csv"
              maxSize={500 * 1024} // max 500KB
              isMultiple={false}
              value={distributionFile}
            >
              <div
                key="content"
                className={stack({
                  justify: "center",
                  align: "center",
                  gap: 16,
                })}
              >
                <Icon
                  name="chart-network"
                  fontSize={32}
                  style={{
                    color: colors.grey[700],
                  }}
                />

                <div className={styles.dropzoneBoxContent}>
                  <h5 className={typography.p2}>
                    Drag and drop distribution file
                  </h5>
                  <h6
                    className={typography.p3}
                    style={{
                      color: colors.grey[700],
                    }}
                  >
                    .CSV, max 500KB
                  </h6>
                </div>

                <span className={typography.p2}>or</span>

                <Button
                  type="button"
                  size="small"
                  variant="text"
                  onClick={() => null}
                >
                  Select from folder
                </Button>
              </div>
            </DropzoneField>
          </div>
        )}
      </div>
      <footer className={pageStyles.footer}>
        <div className={pageStyles.prevButtonBox}>
          <Button
            type="button"
            icon="chevron-left"
            iconPosition="before"
            variant="secondary"
            onClick={onGoPrevStep}
          >
            Previous
          </Button>
        </div>

        <div className={pageStyles.nextButtonBox}>
          <Button
            type="submit"
            icon="chevron-right"
            iconPosition="after"
            disabled={!distributionFile}
            isLoading={distributeVcuMutation.isLoading}
          >
            Distribute
          </Button>
        </div>
      </footer>
    </form>
  );
};

export default SelectVerraDistribution;
