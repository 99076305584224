import { cva } from "class-variance-authority";
import clsx from "clsx";
import omit from "lodash/omit";
import {
  cloneElement,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Arrow,
  TriggerProps,
  UseHoverOptions,
  UseHoverProps,
  UseLayerOptions,
  mergeRefs,
  useHover,
  useLayer,
} from "react-laag";

import { cn } from "~utils";
import { getInheritedZIndex } from "~utils/inherited-z-index";

import { TooltipPlacement } from "./types";

const contentVariants = cva(
  "block max-w-60 break-words rounded px-3 py-2 text-p3 shadow-200 animate-in",
  {
    variants: {
      layerSide: {
        top: "slide-in-from-bottom-3",
        bottom: "slide-in-from-top-3",
        left: "slide-in-from-right-3",
        right: "slide-in-from-left-3",
        center: "transform-none",
      },
      theme: {
        dark: "bg-grey-white text-grey-900",
        light: "bg-grey-800 text-grey-white",
      },
    },
  },
);

const arrowVariants = cva("pointer-events-none", {
  variants: {
    theme: {
      dark: "text-grey-white",
      light: "text-grey-800",
    },
  },
});

type LayerConfig = {
  placement?: TooltipPlacement;
  possiblePlacements?: TooltipPlacement[];
  preferX?: UseLayerOptions["preferX"];
  preferY?: UseLayerOptions["preferY"];
};

type HoverConfig = {
  delayEnter?: UseHoverOptions["delayEnter"];
  delayLeave?: UseHoverOptions["delayLeave"];
};

type TriggerElement = React.ReactElement<
  UseHoverProps &
    TriggerProps & {
      className?: string;
      onFocus?: React.FocusEventHandler;
      onBlur?: React.FocusEventHandler;
    }
>;

type Props = LayerConfig &
  HoverConfig &
  React.PropsWithChildrenRequired<{
    content: string;
    variant?: "rich" | "plain";
    isDelayed?: boolean;
    /**
     * @deprecated For testing only, should be removed/renamed.
     */
    forceShow?: boolean;
    theme?: "light" | "dark";
    className?: string;
    onClose?: () => void;
    onHover?: () => void;
  }>;

export const Tooltip = ({
  children,
  content,

  placement = "top-center",
  possiblePlacements,
  preferX,
  preferY,
  theme = "light",

  isDelayed = true,
  className,
  forceShow = false,

  onClose,
  onHover,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const triggerRef = createRef<HTMLElement>();
  const prevIsOpen = useRef<boolean | null>(null);

  // Casting here to add more flexibility to end user without having to ignore TS.
  const triggerElement = children as TriggerElement;

  /**
   * We use the inherited z-index value of the trigger (if any), to pass along to the layer.
   * That way you can set a higher z-index on things like sticky headers,
   * and use that to make sure the layer also gets the same value and avoids stacking issues.
   */
  const [triggerZIndex, setTriggerZIndex] = useState<string | undefined>();

  const [isOver, hoverProps] = useHover({
    delayEnter: isDelayed ? 200 : undefined,
    delayLeave: isDelayed ? 140 : undefined,
  });

  const isOpen = isFocused || isOver || forceShow;

  useEffect(() => {
    if (!isOpen) return;

    const inheritedZIndex = getInheritedZIndex(triggerRef.current);
    setTriggerZIndex(inheritedZIndex);
  }, [isOpen, triggerRef]);

  useEffect(() => {
    if (onHover && isOpen) onHover();
  }, [isOpen, onHover]);

  useEffect(() => {
    if (onClose && !isOpen && isOpen !== prevIsOpen.current) onClose();

    prevIsOpen.current = isOpen;
  }, [isOpen, prevIsOpen, onClose]);

  // layerSide is the computed side based on "auto"
  const { triggerProps, layerProps, arrowProps, renderLayer, layerSide } =
    useLayer({
      container: () => document.body,
      isOpen,
      auto: true,
      placement,
      possiblePlacements,
      preferX,
      preferY,
      triggerOffset: 8,
    });

  const onFocus = useCallback<React.FocusEventHandler>(
    (...args) => {
      if (!triggerElement.props.onFocus) return;

      setIsFocused(true);
      triggerElement.props.onFocus(...args);
    },
    [triggerElement.props],
  );

  const onBlur = useCallback<React.FocusEventHandler>(
    (...args) => {
      if (!triggerElement.props.onBlur) return;

      setIsFocused(false);
      triggerElement.props.onBlur(...args);
    },
    [triggerElement.props],
  );

  const TooltipTrigger = cloneElement(triggerElement, {
    // These are the mouse + touch listeners
    ...hoverProps,
    ...omit(triggerProps, "ref"),
    ref: mergeRefs(triggerProps.ref, triggerRef),
    className: clsx(
      triggerElement.props.className,
      "z-low hover:cursor-pointer",
    ),
    onFocus,
    onBlur,
  });

  return (
    <>
      {TooltipTrigger}
      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            style={{
              ...layerProps.style,
              zIndex: triggerZIndex,
            }}
            className={cn(contentVariants({ layerSide, theme }), className)}
          >
            <span dangerouslySetInnerHTML={{ __html: content }} />

            <Arrow
              {...arrowProps}
              backgroundColor="currentColor"
              className={cn(arrowVariants({ theme }))}
            />
          </div>,
        )}
    </>
  );
};
