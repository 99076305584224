import { zodResolver } from "@hookform/resolvers/zod";
import { RowSelectionState } from "@tanstack/react-table";
import { uniqBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHref, useParams } from "react-router-dom";
import {
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { Button } from "@ag/design-system/atoms";
import { ComboBox, Select } from "@ag/design-system/molecules";
import { ComboBoxField, InputField, SelectField } from "@ag/form-fields";
import { SIGN_IN_AS_PARAM } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";
import { usePagination } from "@ag/utils/hooks";

import BackButton from "~components/BackButton";
import {
  Filters,
  transformInitialFilterValues,
  useSearchParamForm,
} from "~components/filters";
import Table from "~components/table";
import { env } from "~config";
import { FieldValidationStatus } from "~features/field";
import { AuthorizedSidebar } from "~features/navigation";
import { useSignInAsMutation } from "~features/user";
import {
  ValidationFieldFilters,
  ValidationFieldFiltersSchema,
  ValidationRequestFieldsActions,
  useValidationRequestFieldsBulkActions,
  useValidationRequestFieldsQuery,
  useValidationRequestFieldsTable,
  useValidationRequestQuery,
} from "~features/validation-request";
import { getValidationRequestFieldStatusLabel } from "~features/validation-request/helpers/lookups";
import { transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const ValidationStatusParam = createEnumParam(
  Object.values(FieldValidationStatus),
);

const ValidationRequestFields = () => {
  const params = useParams<{ validationRequestId: string }>();
  const validationRequestId = params.validationRequestId!;

  const [pagination, setPagination, resetPagination] = usePagination();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [query, setQuery] = useQueryParams({
    carbonFarmId: withDefault(StringParam, undefined),
    fieldName: withDefault(StringParam, undefined),
    id: withDefault(StringParam, undefined),
    validationStatus: withDefault(ValidationStatusParam, undefined),
  });

  const fieldValidationStatuses = Object.values(FieldValidationStatus).filter(
    value => value !== FieldValidationStatus.New,
  );

  const { selectedFields, setSelectedFields, bulkAction } =
    useValidationRequestFieldsBulkActions(validationRequestId);

  const { data: validationRequest, isLoading: isValidationRequestLoading } =
    useValidationRequestQuery(validationRequestId);

  const userLink = useHref(`/users/${validationRequest?.carbonUser.userId}`);

  const selection = useMemo(() => {
    const lookup: RowSelectionState = {};
    for (const field of selectedFields) {
      lookup[field.id] = true;
    }
    return lookup;
  }, [selectedFields]);

  const { data: fieldsData, isLoading: isFieldsLoading } =
    useValidationRequestFieldsQuery(validationRequestId, {
      ...pagination,
      filters: {
        ...query,
      },
    });

  const signInAsMutation = useSignInAsMutation();

  const handleSignInAs = () => {
    if (!validationRequest) return;

    signInAsMutation.mutate(validationRequest?.carbonUser.userId.toString(), {
      onSuccess: ({ ticket }) => {
        const params = getSearchParams({
          [SIGN_IN_AS_PARAM]: ticket,
        });

        window.open(env.REACT_APP_FARMER_URL + params);
      },
    });
  };

  const farms = useMemo(
    () =>
      uniqBy(
        (validationRequest?.carbonFields ?? []).map(field => field.carbonFarm),
        "id",
      ),
    [validationRequest],
  );

  const isSelectionEnabled = useMemo(
    () => validationRequest?.status === "new",
    [validationRequest?.status],
  );
  const handleSetSelection = useCallback(
    (
      updater:
        | RowSelectionState
        | ((state: RowSelectionState) => RowSelectionState),
    ) => {
      if (!fieldsData) return;

      const lookup =
        typeof updater === "function" ? updater(selection) : updater;

      setSelectedFields(
        fieldsData.items.filter(field => lookup[field.id] === true),
      );
    },
    [fieldsData, selection, setSelectedFields],
  );

  const renderFilterBarItem = (
    key: keyof ValidationFieldFilters,
    value: ValidationFieldFilters[keyof ValidationFieldFilters],
  ) => {
    const label = (
      {
        contractType: "Contract type",
        creditingPeriod: "Crediting period in years",
        status: "Status",
        year: "Year",
        docusignEnvelopeId: "DocuSign ID",
        compValidationFieldFiltersVatNumber:
          "CompValidationFieldFilters VAT number",
        signerEmail: "Signer email",
        signerPhoneNumber: "Signer phone number",
        userId: "User ID",
      } as unknown as Record<keyof ValidationFieldFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ValidationFieldFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(ValidationFieldFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<ValidationFieldFilters>(
    query,
    setQuery,
    resetPagination,
    () => setIsFiltersOpen(false),
  );
  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  const table = useValidationRequestFieldsTable({
    fields: fieldsData?.items ?? [],
    farms,
    selection,
    isSelectionEnabled,
    setSelection: handleSetSelection,
  });

  return (
    <ListLayout.Root>
      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <Controller
            control={control}
            name="carbonFarmId"
            render={({ field, fieldState }) => (
              <ComboBoxField
                {...field}
                error={fieldState.error}
                value={field.value ?? ""}
                loadingText="Loading..."
                emptyText="No matching results"
                label="Farm name"
              >
                {farms.map(farm => (
                  <ComboBox.Item key={farm.id}>{farm.name}</ComboBox.Item>
                ))}
              </ComboBoxField>
            )}
          />

          <InputField
            {...register("fieldName")}
            label="Field name"
            error={errors.fieldName}
          />

          <InputField
            {...register("id")}
            type="number"
            label="Field ID"
            error={errors.id}
          />

          <Controller
            control={control}
            name="validationStatus"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Validation status"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {fieldValidationStatuses.map(status => (
                  <Select.Option key={status} value={status}>
                    {getValidationRequestFieldStatusLabel(status)}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.TopBar>
        <BackButton />

        <ListLayout.TopBarTitle
          title={`Validation details - Carbon User ${
            validationRequest?.carbonUser.userId ?? ""
          }`}
        >
          {validationRequest ? (
            <span
              dangerouslySetInnerHTML={{
                __html: `Validation details - Carbon User <a href="${userLink}">${validationRequest.carbonUser.userId}</a>`,
              }}
            />
          ) : null}
        </ListLayout.TopBarTitle>

        <ListLayout.TopBarActions>
          <Button
            onClick={handleSignInAs}
            isLoading={isValidationRequestLoading}
          >
            Sign in as
          </Button>
        </ListLayout.TopBarActions>
      </ListLayout.TopBar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />

          <ListLayout.Actions>
            <ValidationRequestFieldsActions
              selectedFields={selectedFields}
              onBulkReject={() => bulkAction("reject")}
              onBulkValidate={() => bulkAction("validate")}
            />
          </ListLayout.Actions>
        </ListLayout.Header>

        <Table
          instance={table}
          meta={fieldsData?.meta}
          pagination={pagination}
          isLoading={isFieldsLoading}
          onPaginationChange={setPagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default ValidationRequestFields;
