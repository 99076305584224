import arrayMutators from "final-form-arrays";
import { Form } from "react-final-form";
import ReactJson from "react-json-view";

import {
  AdditionalInformationForm,
  CropProtectionForm,
  CultivationStrategyForm,
  FertiliserForm,
  FuelConsumptionForm,
} from "@ag/carbon/forms/inputs-v2021";
import { FieldActualsInputType } from "@ag/carbon/types";
import { Button, InfoBox } from "@ag/design-system/atoms";
import { box, stack } from "@ag/design-system/utils";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import CollapseBox from "~components/collapse-box";
import { ALL_AVAILABLE_ITEMS_NUMBER } from "~constants/params";
import { useFieldHarvestYearContext } from "~features/harvest-year";
import {
  CarbonResourceClass,
  UserManagementResourceClass,
  useCarbonPermissions,
  useUserManagementPermissions,
} from "~features/permission";
import { useSignInAsMutation } from "~features/user";
import { getInitialResources } from "~helpers/initial-resources";
import { ampli } from "~lib/ampli";
import { redirectToAppFarmerFieldDetailsPage } from "~pages/_legacy/carbon/helpers";
import { FieldStepTabId } from "~pages/_legacy/carbon/types";
import { useFertilisersQuery } from "~queries/carbon/fertilisers";

import { useUnlockFieldActualsMutation } from "../api/unlock-field-actuals";
import { Field } from "../entities/field";
import {
  getStepStatusIcon,
  getStepStatusText,
  getStepStatusVariant,
} from "../helpers/field-step";
import { useIsFieldActualsUnlockable } from "../hooks/use-is-field-actuals-unlockable";

type Props = {
  field: Field;
};

export const FieldActualsV2021Overview = ({ field }: Props) => {
  const [fieldActuals] = field.carbonFieldActual ?? [];

  const { selectedHarvestYear } = useFieldHarvestYearContext();

  const { data: carbonPermissions } = useCarbonPermissions();
  const { data: userManagementPermissions } = useUserManagementPermissions();
  const hasFertilisersAccess = carbonPermissions?.list?.includes(
    CarbonResourceClass.CarbonFertiliser,
  );

  const { data: fertilisers } = useFertilisersQuery(
    {
      filters: {
        harvestYear: selectedHarvestYear,
        methodologyVersion: fieldActuals?.methodologyVersion,
      },
      metaArgs: {
        limit: ALL_AVAILABLE_ITEMS_NUMBER,
      },
    },
    {
      enabled: hasFertilisersAccess,
    },
  );

  const isActualsUnlockable = useIsFieldActualsUnlockable(fieldActuals);

  const unlockFieldActualsMutation = useUnlockFieldActualsMutation();
  const signInAsMutation = useSignInAsMutation();

  const handleSignInAs = (userId: string) => {
    signInAsMutation.mutate(userId, {
      onSuccess: ({ ticket }) => {
        redirectToAppFarmerFieldDetailsPage({
          ticket,
          fieldId: field.id,
          harvestYear: selectedHarvestYear,
          activeFieldDetailsTab: FieldStepTabId.FieldActuals,
        });
      },
    });
  };

  const handleUnlockActuals = () => {
    unlockFieldActualsMutation.mutate(
      {
        fieldId: field.id,
        fieldActualsId: fieldActuals.id,
      },
      {
        onSuccess: () => {
          ToastNotification.success(
            I18n.t("js.admin.field_details.actuals_unlocked"),
          );

          if (selectedHarvestYear) {
            ampli.adminUnlockFieldActuals({
              fieldId: Number(field.id),
              harvestYear: selectedHarvestYear,
            });
          }
        },
      },
    );
  };

  const initialResources = getInitialResources();

  if (fieldActuals.carbonFieldActualInputType !== FieldActualsInputType.V2021) {
    return null;
  }

  return (
    <div className={stack()}>
      <InfoBox
        variant={getStepStatusVariant(fieldActuals.status)}
        icon={getStepStatusIcon(fieldActuals.status)}
      >
        {getStepStatusText(fieldActuals.status, fieldActuals.submittedAt)}
      </InfoBox>

      <div className={box({ gap: 8 })}>
        {carbonPermissions?.unlock?.includes(
          CarbonResourceClass.CarbonFieldActual,
        ) ? (
          <Button disabled={!isActualsUnlockable} onClick={handleUnlockActuals}>
            {I18n.t("js.shared.unlock")}
          </Button>
        ) : null}

        {userManagementPermissions?.loginAsUser?.includes(
          UserManagementResourceClass.User,
        ) ? (
          <Button onClick={() => handleSignInAs(field.user.id)}>
            {I18n.t("js.shared.sign_in")}
          </Button>
        ) : null}
      </div>

      {/* Field cultivation practice */}
      <Form
        initialValues={{
          ...fieldActuals.carbonFieldActualInput,
          isFertilisersUsed:
            Number(fieldActuals.carbonFieldActualInput.fertilisersCount) > 0,
        }}
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={() => {}}
        render={() => (
          <>
            <CollapseBox.Root isInitiallyOpen>
              <CollapseBox.Title>
                {I18n.t("js.carbon.field_details.field_cultivation_practice")}
              </CollapseBox.Title>
              <CollapseBox.Content>
                <CultivationStrategyForm
                  cropTypes={
                    fieldActuals.carbonFieldActualInput.carbonCropType
                      ? [fieldActuals.carbonFieldActualInput.carbonCropType]
                      : []
                  }
                  cropResidueManagementOptions={
                    initialResources?.field.cropResidueManagement ?? []
                  }
                  isReadonly
                />
              </CollapseBox.Content>
            </CollapseBox.Root>

            {/* Fertiliser */}
            <CollapseBox.Root isInitiallyOpen>
              <CollapseBox.Title>
                {I18n.t("js.carbon.fertiliser_title")}
              </CollapseBox.Title>
              <CollapseBox.Content>
                <FertiliserForm
                  initialValues={{
                    ...fieldActuals.carbonFieldActualInput,
                  }}
                  fertilisers={fertilisers?.fertilisers ?? []}
                  applicationModes={[]}
                  isReadonly
                />
              </CollapseBox.Content>
            </CollapseBox.Root>

            {/* Crop protection */}
            <CollapseBox.Root isInitiallyOpen>
              <CollapseBox.Title>
                {I18n.t("js.carbon.crop_protection")}
              </CollapseBox.Title>
              <CollapseBox.Content>
                <CropProtectionForm
                  protectionTypes={
                    initialResources?.field
                      .cropProtectionsProtectionType[2021] ?? []
                  }
                  isReadonly
                />
              </CollapseBox.Content>
            </CollapseBox.Root>

            {/* Fuel consumption */}
            <CollapseBox.Root isInitiallyOpen>
              <CollapseBox.Title>
                {I18n.t("js.carbon.energy_consumption")}
              </CollapseBox.Title>
              <CollapseBox.Content>
                <FuelConsumptionForm
                  energySource={
                    initialResources?.field.energyConsumptionEnergySource ?? []
                  }
                  isReadonly
                />
              </CollapseBox.Content>
            </CollapseBox.Root>

            {/* Additional information */}
            <CollapseBox.Root isInitiallyOpen>
              <CollapseBox.Title>
                {I18n.t("js.carbon.additional_information")}
              </CollapseBox.Title>
              <CollapseBox.Content>
                <AdditionalInformationForm isReadonly />
              </CollapseBox.Content>
            </CollapseBox.Root>
          </>
        )}
      />

      {/* Sent to CFT */}
      <CollapseBox.Root>
        <CollapseBox.Title>
          {I18n.t("js.carbon.field_details.sent_to_cft")}
        </CollapseBox.Title>
        <CollapseBox.Content>
          {fieldActuals.latestCarbonFootprint ? (
            <ReactJson
              src={fieldActuals.latestCarbonFootprint}
              displayDataTypes={false}
              shouldCollapse={key => key.name !== "root"}
            />
          ) : (
            <InfoBox variant="danger" icon="triangle-exclamation">
              {I18n.t("js.carbon.cft_response_error")}
            </InfoBox>
          )}
        </CollapseBox.Content>
      </CollapseBox.Root>
    </div>
  );
};
