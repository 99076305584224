import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { ChangeEvent } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { Checkbox } from "@ag/components/Checkbox";
import { ActionButton } from "@ag/components/buttons";
import { FormCheckbox } from "@ag/components/form";
import { SFlexDiv } from "@ag/components/styled";
import I18n, { getLocaleName, getLocales } from "@ag/i18n";

import Card from "~components/card";

import { SErrorMessage, SFontAwesomeIcon, SGridContainer } from "./styled";

type Props = {
  isSubmitting?: boolean;
  onSubmit: () => void;
};

const Form = ({ isSubmitting, onSubmit }: Props) => {
  const { errors, touched, values } = useFormState();
  const form = useForm();

  const locales = getLocales();
  const isAllLocalesSelected =
    Object.values(values).filter(Boolean).length === locales.length;

  const handleSelectAllChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const isSelected = event.target.checked;

    if (locales && isSelected) {
      form.batch(() => {
        locales.forEach(locale => {
          form.change(locale, true);
        });
      });
    }
  };

  const hasAnyFieldsTouched = touched && Object.values(touched).some(Boolean);
  const showError = errors?.hasError && hasAnyFieldsTouched;

  return (
    <>
      <Card.Root>
        <SFlexDiv marginBottom={12}>
          <Checkbox
            label={I18n.t("js.shared.select_all")}
            labelBold
            checked={isAllLocalesSelected}
            onChange={handleSelectAllChanged}
          />
        </SFlexDiv>

        <SGridContainer>
          {locales.map(locale => (
            <Field
              key={locale}
              name={locale}
              label={getLocaleName(locale)}
              component={FormCheckbox}
              type="checkbox"
            />
          ))}
        </SGridContainer>

        {!showError && (
          <SErrorMessage>
            <SFontAwesomeIcon size="sm" icon={faExclamationTriangle} />

            {I18n.t("js.settings.translations.error.required_translations")}
          </SErrorMessage>
        )}
      </Card.Root>

      <SFlexDiv justifyContent="end" marginTop={24}>
        <ActionButton isLoading={isSubmitting} onClick={onSubmit}>
          {I18n.t("js.shared.update")}
        </ActionButton>
      </SFlexDiv>
    </>
  );
};

export default Form;
