import isEmpty from "lodash/isEmpty";

import { AddTranslationsFormData } from "./types";

export const validate = (formData: AddTranslationsFormData) => {
  const errors: { hasError?: boolean } = {};

  const hasAnySelectedLocales =
    !isEmpty(formData) && Object.values(formData).some(Boolean);

  if (!hasAnySelectedLocales) {
    errors.hasError = true;
  }

  return errors;
};
