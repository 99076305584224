import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { typography } from "@ag/design-system/tokens";

import * as styles from "./progress-lost-modal.css";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onExitClick: () => void;
};

export const ProgressLostModal = ({ isOpen, onClose, onExitClick }: Props) => {
  return (
    <Modal.Root
      isOpen={isOpen}
      onRequestClose={onClose}
      className="bg-gray-700"
      size="small"
      isCentered
    >
      <Modal.Content>
        <div className={styles.modalContentWrapper}>
          <div>
            <Icon name="warning" fontSize={56} className={styles.warningIcon} />
          </div>
          <div className={styles.modalContentMessage}>
            <span className={typography.h3}>Progress will be lost</span>
            <p className={`${typography.p2} ${styles.paragraphWithMargin}`}>
              Exiting the current flow without submitting will result in the
              loss of any entered data. Are you sure you wish to proceed?
            </p>
          </div>
          <div className={styles.modalContentButtonList}>
            <Button
              isDanger
              className={styles.modalContentButton}
              variant="primary"
              onClick={onExitClick}
            >
              Exit
            </Button>
            <Button
              className={styles.modalContentButton}
              variant="text"
              onClick={onClose}
            >
              Continue submission
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
