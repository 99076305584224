import {
  AuthAbility,
  CarbonAbility,
  MarketsAbility,
  UserManagementAbility,
} from "../types/abilities";
import { Page } from "../types/pages";
import { PermissionsService } from "../types/permissions";
import {
  AuthResourceClass,
  CarbonResourceClass,
  MarketsResourceClass,
  UserManagementResourceClass,
} from "../types/resources";

type PermissionItem<S, R> = {
  ability: S;
  resource: R;
};

type PageAccess = Record<
  Page,
  Partial<
    Record<
      PermissionsService.Carbon,
      PermissionItem<CarbonAbility, CarbonResourceClass>[]
    > &
      Record<
        PermissionsService.Auth,
        PermissionItem<AuthAbility, AuthResourceClass>[]
      > &
      Record<
        PermissionsService.UserManagement,
        PermissionItem<UserManagementAbility, UserManagementResourceClass>[]
      > &
      Record<
        PermissionsService.Markets,
        PermissionItem<MarketsAbility, MarketsResourceClass>[]
      >
  >
>;

export const pageAccess: PageAccess = {
  // Users
  [Page.UserValidation]: {
    [PermissionsService.UserManagement]: [
      {
        ability: UserManagementAbility.List,
        resource: UserManagementResourceClass.User,
      },
    ],
  },
  [Page.Admins]: {
    [PermissionsService.Auth]: [
      {
        ability: AuthAbility.List,
        resource: AuthResourceClass.Admin,
      },
    ],
  },
  [Page.AssignedUsers]: {
    [PermissionsService.UserManagement]: [
      {
        ability: UserManagementAbility.ListOwn,
        resource: UserManagementResourceClass.AdminUserAssignment,
      },
    ],
  },

  // Carbon operations
  [Page.Fields]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.CarbonField,
      },
    ],
  },
  [Page.ValidationRequests]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.CarbonValidationRequest,
      },
    ],
  },
  [Page.CarbonContracts]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.CarbonContract,
      },
    ],
  },

  // Sales agreements
  [Page.BuyoutContracts]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.CarbonSalesAgreement,
      },
    ],
  },
  [Page.SalesOffers]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.Offer,
      },
    ],
  },
  [Page.ResaleAgreements]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.CarbonBrokerContract,
      },
    ],
  },

  // Carbon settings
  [Page.CropTypes]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.CarbonCropType,
      },
    ],
  },
  [Page.Fertilisers]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.Read,
        resource: CarbonResourceClass.CarbonFertiliser,
      },
    ],
  },

  // Carbon MRV
  [Page.QualityAssurance]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.CarbonQualityAssurance,
      },
    ],
  },
  [Page.QualityControl]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.CarbonQualityControl,
      },
    ],
  },
  [Page.BoundaryVerification]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.CarbonQualityAssurance,
      },
    ],
  },

  // Assets
  [Page.CertificatesManagement]: {
    [PermissionsService.Markets]: [
      {
        ability: MarketsAbility.Read,
        resource: MarketsResourceClass.CertificateGroup,
      },
    ],
  },
  [Page.UpdateRequest]: {
    [PermissionsService.Markets]: [
      {
        ability: MarketsAbility.Create,
        resource: MarketsResourceClass.CertificateUpdateRequest,
      },
    ],
  },
  [Page.ApproveUpdateRequest]: {
    [PermissionsService.Markets]: [
      {
        ability: MarketsAbility.Update,
        resource: MarketsResourceClass.CertificateUpdateRequest,
      },
    ],
  },
  [Page.ImportVcu]: {
    [PermissionsService.Markets]: [
      {
        ability: MarketsAbility.Create,
        resource: MarketsResourceClass.VerifiedCreditUnit,
      },
    ],
  },
  [Page.ImportIsoCertificates]: {
    [PermissionsService.Markets]: [
      {
        ability: MarketsAbility.Create,
        resource: MarketsResourceClass.VerifiedCreditUnit,
      },
    ],
  },
  [Page.ImportVerraCertificates]: {
    [PermissionsService.Markets]: [
      {
        ability: MarketsAbility.Create,
        resource: MarketsResourceClass.VerifiedCreditUnit,
      },
    ],
  },

  // Inventory
  [Page.Inventory]: {
    [PermissionsService.Markets]: [
      {
        ability: MarketsAbility.List,
        resource: MarketsResourceClass.InventoryImport,
      },
    ],
  },

  [Page.InventoryImports]: {
    [PermissionsService.Markets]: [
      {
        ability: MarketsAbility.List,
        resource: MarketsResourceClass.InventoryImport,
      },
    ],
  },

  // Settings
  [Page.ApplicationSettings]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.List,
        resource: CarbonResourceClass.ApplicationSetting,
      },
    ],
  },
  [Page.Translations]: {},

  // External
  [Page.Features]: {},
  [Page.DocuSign]: {
    [PermissionsService.Carbon]: [
      {
        ability: CarbonAbility.Create,
        resource: CarbonResourceClass.DocusignConsentUrl,
      },
    ],
  },
  [Page.SideKiq]: {},
};
