import { useSpace } from "@flatfile/react";

import * as styles from "./flatfile-embed.css";

type FlatfileEmbedProps = {
  environmentId: string;
  listener?: any; // TODO: figure out why flatfile listener is not matching in types
  spaceId: string;
  accessToken: string;
};

export function FlatfileEmbed({
  environmentId,
  spaceId,
  listener,
  accessToken,
}: FlatfileEmbedProps) {
  const Space = useSpace({
    environmentId,
    space: {
      id: spaceId,
      accessToken,
    },
    listener,
    displayAsModal: false,
    iframeStyles: {
      width: "100%",
      height: "100%",
    },
  });

  return <div className={styles.root}>{Space}</div>;
}
